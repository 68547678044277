import React, { useState, useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Sidebar from "../components/Sidebar"
import { Box, Button, Container, Grid, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core"
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import userData from '../data/userData';
import webservice from '../../api/webservice';
import Commessa from '../components/Commessa';
import contractData from '../data/contractData';
import { useHistory } from "react-router-dom";
import anagraficaData from '../data/anagraficaData';


const useStyles = makeStyles((theme) => ({
  Header:{
    backgroundColor: "#00ADA2",
    height: 50,
    display:'flex',
    justifyContent:'space-between',
    paddingLeft:15,
    paddingRight:15,
    alignItems:'center'
  },
  toolBar:{
    height:'auto',
    backgroundColor:'white',
    padding:'10px 15px 10px 50px',
    display:'flex',
    justifyContent:'space-between'
  },
  toolBox:{
    width:'20%',
    display:'flex',
    justifyContent:'space-evenly'
  },
  pulsanteAttivita:{
    borderRadius:20,
    padding:'5px 50px',
    border:'1px solid #5692d8',
    fontWeight:'bold',
    letterSpacing:'.05em',
    color:'#5692d8'
  },
  "&:hover":{
    backgroundColor:'transparent'
  },
  containerBox:{
    paddingLeft:'2%',
    marginTop:50,
    flex:1,
    flexWrap:'wrap',
    display:'flex',
    justifyContent:'flex-start'
  },

}))

export default(props) => {
  const history = useHistory()
  const { getContractList,deleteContract, getFullProgrammaReq,getClientList,clientList, updateCommessaStatus } = webservice()
  const { getContractListData, setNewContractData, setContractType,setContractActivity, setNewContractAcquisizione, removeActivity, setContractReferent, setNewContractType, setContractInvoice, setContractDocument, setNewUploadsData, getNewContractClientList } = contractData()
  const { setInterventoListData, getFullProgramma } = anagraficaData()
  const { getUserData,setDrawer, getCurrentPermission } = userData()

  //Activity button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));
  const { getCommesseList } = webservice()
  const [dataToPrint,setDataToPrint] = React.useState([])

  async function executedFirst(){
    await getCommesseList('dd').then(res => {
      if(COM_VARIANT === 'true'){
        setDataToPrint(res.filter(obj => obj.status == 0))
        console.log('true')
      }else if(COM_VARIANT === 'false'){
        setDataToPrint(res.filter(obj => obj.status == 2))
        console.log('false')
      }else{
        setDataToPrint(res.filter(obj => obj.status == 0))
        console.log('default')
      }
      console.log(dataToPrint)
    })
  }

  const COM_VARIANT = props.match.params.status
  console.log(COM_VARIANT)
  //Retrieving contract list from database
  useEffect(() => {
    executedFirst()
  }, [props.match.params.status])

  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  //Commesse selezionati per azioni
  const [selected,setSelected] = useState([])
  const handleSelect = (e) => {
    let joined = []
    let res = selected.filter(function(item){ return item.numero == e.numero && item.id == e.id}).length >0 ? true : false
    if (res  === false) {
      joined = [...selected,e]
    }else{
      joined = selected.filter( function(item) {
        return item.numero !== e.numero && item.id !== e.id
      })
    }
    setSelected(joined)
  }

  const isSelected = (e) => {
    let res = selected.filter( function(item)  {return item.numero === e.numero && item.id === e.id} )
    return res.length > 0 ? true : false
  }

  const classes = useStyles();
  return (
    <div style={{display:'flex'}}>

      <Sidebar></Sidebar>
      <Container maxWidth="False" style={{padding:0,margin:0,width:'100%',marginLeft:'-13%'}}>

        <Container maxWidth="False" className={classes.Header}>
          <MenuIcon style={{cursor:'pointer',color:'white'}} onClick={e => {
                          setDrawer()
                          forceUpdate()
                        }}/>
          {/* import PersonIcon from '@material-ui/icons/Person'; */}
          <Box style={{display:'flex',justifyContent:'space-around',width:'8%'}}>
              <Typography style={{color:'white',fontSize:'.6vw',margin:'auto 0', letterSpacing:'.1em'}}>{ getUserData() !== undefined ? getUserData().Nome + ' ' + getUserData().Cognome : '' }</Typography>
              <PersonIcon style={{height:32,color:'white'}} />
            </Box>
        </Container>
        <Container maxWidth="False" className={classes.toolBar}>
          <Box style={{display:'flex'}}>
            <img style={{width:40,marginRight:30}}src={require('../../assets/images/commesse.png')} />
            <Typography variant='h6' component='h2' style={{color:'#6A6A6A',margin:'auto 0'}}>LISTA COMMESSE {COM_VARIANT === 'true' ? "APERTE" : 'CHIUSE'}</Typography>
          </Box>
          <Box className={classes.toolBox} style={{width:'25%'}}>
                        
          <Grid container spacing={1} alignItems="flex-end">
            
            {/*<Grid item style={{width:'70%'}}>
              <TextField variant="outlined" id="input-with-icon-grid" style={{width:'100%'}} onChange={ e => {
                let tmp = data.filter(e => e.isSub == 0 ) 
                const zeroPad = (num, places) => String(num).padStart(places, '0')
                tmp = tmp.filter( item => item.titolo.toLowerCase().includes(e.target.value.toLowerCase()) || zeroPad(item.id, 5).includes(e.target.value))
                setDataToPrint(tmp)
              } } inputProps={{
                style: {
                  height:0
                },
            }}/>
            </Grid>
            <Grid item>
              <SearchIcon style={{color:'#77ABE7'}}/>
          </Grid>*/}
          </Grid>

           <Button className={classes.pulsanteAttivita} aria-controls="simple-menu" style={{backgroundColor:'transparent'}}aria-haspopup="true" onClick={handleClick}>
              Attivita'
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              style={{
                width:'40rem'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={e => {
                  e.preventDefault()
                  setNewContractData(null)
                  setNewContractAcquisizione(null)
                  setContractActivity('reset')
                  setContractReferent('reset')
                  setContractInvoice(null)
                  setNewContractType(null)
                  setNewUploadsData(null)
                  setContractType(null)
                  setContractDocument('reset')
                  history.push('/commessa/nuovaCommessa/new')
                  }}>
                  <ListItemText primary="Nuova Commessa" />
                </MenuItem> 
                <MenuItem onClick={ async e=> {
                e.preventDefault()
                let data = selected
                if (data.length > 0 && getUserData().Tipologia !== "Cliente" && getUserData().Tipologia !== 'Utente'){
                  data.map(currentCommessa => {
                    updateCommessaStatus({
                      token:getUserData().Token,
                      id:currentCommessa.id,
                      status:0
                    }).then(() => {
                      executedFirst()
                    }).catch(() => {
                      alert('Errore durante la richiesta al server')
                    }) 
                  })
                  console.log('DONE')
                }else{
                  alert("Seleziona delle commesse da aprire")
                }
              }}>
                <ListItemText primary="Seleziona e Apri" />
                <img src={require('../../assets/images/active.png')} style={{width:20}}/>
              </MenuItem>
              <MenuItem onClick={ async e=> {
                e.preventDefault()
                let data = selected
                if (data.length > 0 && getUserData().Tipologia !== "Cliente" && getUserData().Tipologia !== 'Utente'){
                  data.map(currentCommessa => {
                    updateCommessaStatus({
                      token:getUserData().Token,
                      id:currentCommessa.id,
                      status:2
                    }).then(() => {
                      executedFirst()
                    }).catch(() => {
                      alert('Errore durante la richiesta al server')
                    }) 
                  })
                  console.log('DONE')
                }else{
                  alert("Seleziona delle commesse da chiudere")
                }
              }}>
                <ListItemText primary="Seleziona e Chiudi" />
                <img src={require('../../assets/images/delete.png')} style={{width:20}}/>
              </MenuItem>
            </Menu>
          
          </Box>
        </Container>
        <Container maxWidth="False" className={classes.containerMainBox}>
          <div className={classes.containerBox}>
            {dataToPrint.sort((a, b) => b.id - a.id).map(  (item) => {
              return <Commessa data={item} onClick={(e) => handleSelect(item)} selected={isSelected(item)} variant={COM_VARIANT}/>
            })}
          </div>
        </Container>

      </Container>
      
       
    </div>
  )
}
