import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import nuovoContratto from '../../contratti/nuovoContratto';

const NewSubImpianto = ({flag,handleCloseSub}) => {
    return (
        <>
            <Dialog
                open={flag}
                onClose={handleCloseSub}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Nuovo Sub Impianto</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <nuovoContratto />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewSubImpianto
