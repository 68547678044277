import React, { useState,useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Sidebar from "../components/Sidebar"
import { Box, Container, IconButton, Typography, Button } from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import webservice from '../../api/webservice'
import { useHistory } from "react-router-dom";
import userData from '../data/userData';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIntervento from './InfoIntervento';
import FirstReport from './FirstReport';
import anagraficaData from '../data/anagraficaData';
import contractData from '../data/contractData';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PersonIcon from '@material-ui/icons/Person';
import DownloadRep from './DownloadRep';
import WrapperComponent from '../components/WrapperComponent';



const useStyles = makeStyles((theme) => ({
  Header:{
    backgroundColor: "#00ADA2",
    height: 50,
    display:'flex',
    justifyContent:'space-between',
    paddingLeft:15,
    paddingRight:15,
    alignItems:'center'
  },
  toolBar:{
    height:'auto',
    backgroundColor:'white',
    padding:'10px 15px 10px 50px',
    display:'flex',
    justifyContent:'space-between'
  },
  body:{
    marginTop:40,
    padding:'0px'
  },
  columnText:{
      fontWeight:'bold',
      color:'#333',
      margin:'auto 0'
  },
  oneRow:{
      display:'flex',
      justifyContent:'space-around',
      paddingTop:5,
      paddingBottom:5,
      marginBottom:30,
      backgroundColor:'white'
  },
  imgSTD:{
      width:24
  },
  ticketColumn:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center'
  },
  lightB:{
    '&:hover':{
      background:'#eaf8fb !important'
    }
  },
  lightR:{
    '&:hover':{
      background:'#f9e2d8 !important'
    }
  },
  iconIMG:{
      height:18
  },
  singleColumn:{
    textAlign:'center',
    display:'flex',
    flexDirection:'row',
    cursor:'pointer'
  },
}))

function ListaTicket() {


  //Data profile vars
  const { GetFullInterventoList,getProgrammaList,createPDF,getInterventoFromProgramma,getAssedById,getReport,getMateriale,getAnomalie,getContractList,userList, getFullProgrammaReq,GetAssetFromProgramma, GetClienteFromProgramma, removeProgramma, getClientList, getAssetList, getReportData,getUserList, getReportDataF, getActivityList } = webservice()
  const { setDrawer,getUserData } = userData();
  const { getInterventoListData,getProgrammaListData, getFullProgramma, setProgId, getCurrentReport, setInterventoListData} = anagraficaData()
  const { getContractListData, getNewContractClientList,getAnomalieData,
    getMaterialeData,getRR} = contractData()
  //Ticket List var
  const [ticketList,setTicketList] = React.useState([])

  const delay = ms => new Promise(res => setTimeout(res, ms));
  const history = useHistory();

  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    setInterventoListData([])
    async function initialData(){
      await getContractList('ddd')
      await getUserList('dd')
      await GetFullInterventoList('dd')
      await getClientList('dd')
      await getReport('dd')
      await getFullProgrammaReq('dd')
      await delay(500)
      if( getFullProgramma() !== null ){
        let tmp = getUserData()!== undefined ? getUserData().Tipologia !== 'Superadmin' ? getFullProgramma().filter(e=> e.idCliente == getUserData().ID && e.stato !== 'finito') : getFullProgramma() : getFullProgramma()
        if(getUserData().Tipologia == "Cliente"){
          tmp = getFullProgramma()
        }
        let finInt = []
        await tmp.map(async currentProg => {
          console.log(tmp)
          if(getUserData().Tipologia == 'Cliente'){
            let tmpContract = ( getContractListData().filter(cc=>cc.cliente == getNewContractClientList().filter( ee => ee.id == getUserData().ofCliente )[0].id  )  )
            if(currentProg.byContratto == tmpContract[0].id){
              await getInterventoFromProgramma('dd',currentProg.id).then(res => {
                finInt.push(res[0])
                setInterventoListData([
                  ...getInterventoListData(),
                  res[0]
                ])
              })
            }
          }else{
            await getInterventoFromProgramma('dd',currentProg.id).then(res => {
              finInt.push(res[0])
              setInterventoListData([
                ...getInterventoListData(),
                res[0]
              ])
            })
          }
        })
        console.log(finInt)
        
        await setTicketList(tmp)
        
        
      }
      await delay(1000)
      forceUpdate()

    }
    initialData()
    
  }, [])

  function printClient(id,type){
    let out = ''
    let addr = ''
    getContractListData().map( cc => {
      if(cc.id == id){
        getNewContractClientList().map( user => {
          if(user.id == cc.cliente){
            out = (user.rSociale)
            addr = (user.indirizzo)
          }
        } )
      }
    } )
      
      if(type){
        return addr
      }else{
        return out
      }
    
  }

  function sendClient(id){
    let exp = []
    getContractListData().map( cc => {
      
      if(cc.id == id){
        getNewContractClientList().map( user => {
          if(user.id == cc.cliente){
            exp = user
          }
        } )
      }
    } )
    return exp
    console.log('dasdasdas',exp)
  }

  const [toDownload,setToDownload] = React.useState([])
  


  const [Addr,setAddr] = React.useState('')
  const [progAct,setProgAct] = React.useState([])
  //Switch priority
  function showPriority(type){
    switch(type){
      case 'urgente':
          return (require('../../assets/images/anagraficaContratto/ticket/pUrgente.png'))
          break;
      case 'medio':
          return (require('../../assets/images/anagraficaContratto/ticket/pMedia.png'))
          break;
      case 'emergenza':
          return (require('../../assets/images/anagraficaContratto/ticket/pEmergenza.png'))
          break;
      case 'basso':
          return (require('../../assets/images/anagraficaContratto/ticket/pBassa.png'))
          break;
      case 'bassa':
          return (require('../../assets/images/anagraficaContratto/ticket/pBassa.png'))
          break;
    }
  }
  function showPriorityColor(type){
    switch(type){
      case 'urgente':
          return 'red'
          break;
      case 'medio':
          return 'red'
          break;
      case 'emergenza':
          return 'blue'
          break;
      case 'basso':
          return '#ec5388'
          break;
      case 'bassa':
          return '#ec5388'
          break;
    }
  }
  
  const [clientListITEM,setClientListITEM] = useState([])
  const [dateArr,setDateArr] = useState(false)
  const [prioritaArr,setPrioritaArr] = useState(false)
  const [tipologiaArr,setTipologiaArr] = useState(false)
  const [statusArr,setStatusArr] = useState(false)
  const [acceptedArr,setAcceptedArr] = useState(false)
  //Function to handle Filters

  function dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}

  function handleFilter(type){
    switch(type){
      case 'data':
        setDateArr(!dateArr)
        setTicketList(dateArr === true ? (
          ticketList.sort(function(a, b){
            if(a.dataInizio < b.dataInizio) { return -1; }
            if(a.dataInizio > b.dataInizio) { return 1; }
            return 0;
        })
        ) : (
          ticketList.sort(function(a, b){
            if(b.dataInizio < a.dataInizio) { return -1; }
            if(b.dataInizio > a.dataInizio) { return 1; }
            return 0;
        })
        ))
        
        forceUpdate()
        forceUpdate()
        break;
      case 'priorita':
        console.log(ticketList)
        setPrioritaArr(!prioritaArr)
        if(prioritaArr === false ? ticketList.sort(function(a, b){
          if(a.stato < b.stato) { return -1; }
          if(a.stato > b.stato) { return 1; }
          return 0;
      }) : ticketList.sort(function(a, b){
        if(b.stato < a.stato) { return -1; }
        if(b.stato > a.stato) { return 1; }
        return 0;
    } ))
        forceUpdate()
        forceUpdate()
        break;
      case 'status':
        setStatusArr(!statusArr)
          statusArr === false ? ticketList.sort(function(a, b){
            if(a.stato < b.stato) { return -1; }
            if(a.stato > b.stato) { return 1; }
            return 0;
        }) : ticketList.sort(function(a, b){
          if(b.stato < a.stato) { return -1; }
          if(b.stato > a.stato) { return 1; }
          return 0;
      })
          forceUpdate()
          forceUpdate()
        break;
      case 'tipologia': 
        setTipologiaArr(!tipologiaArr)
        tipologiaArr === false ? ticketList.sort(function(a, b){
          if(a.variante < b.variante) { return -1; }
          if(a.variante > b.variante) { return 1; }
          return 0;
      }) : ticketList.sort(function(a, b){
        if(b.variante < a.variante) { return -1; }
        if(b.variante > a.variante) { return 1; }
        return 0;
    })
        forceUpdate()
        forceUpdate()
        break;
      case 'accepted':
        setAcceptedArr(!acceptedArr)
        acceptedArr === false ? ticketList.sort(function(a, b){
          if(a.dataFine < b.dataFine) { return -1; }
          if(a.dataFine > b.dataFine) { return 1; }
          return 0;
      }) : ticketList.sort(function(a, b){
        if(b.dataFine < a.dataFine) { return -1; }
        if(b.dataFine > a.dataFine) { return 1; }
        return 0;
    })
        forceUpdate()
        forceUpdate()
        break;
    }
  }


  //Status Info Dialog
  const [isInt,setIsInt] = React.useState(false)
  const [statusInfo,setStatusInfo] = React.useState(false)
  const [target,setTarget] = React.useState({})
  //Report Creation Dialog
  const [statusReport,setStatusReport] = React.useState(false)
  const [dd,setDD] = React.useState(0)

  const classes = useStyles();

  function normalizeDates(date){
    let tmp = date.split('-')
    return ( tmp[2] + '-' + tmp[1] + '-' + tmp[0])
  }

  function displayStatus(type){
    switch(type){
      case 'attivo':
        return <>
            <Typography style={{fontSize:16,color:'white',background:'#397BD3',borderRadius:10,margin:'0 auto',padding:'2px 8px'}}>A</Typography>
            <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#397BD3'}}>Aperto</Typography>
            </>
      case 'lavorazione':
        return <>
            <Typography style={{fontSize:16,color:'white',background:'#EB9427',borderRadius:10,margin:'0 auto',padding:'2px 8px'}}>L</Typography>
            <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#EB9427'}}>Lavorazione</Typography>
            </>
      case 'finito':
        return <>
            <Typography style={{fontSize:16,color:'white',background:'#0A9EA3',borderRadius:10,margin:'0 auto',padding:'2px 8px'}}>F</Typography>
            <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#0A9EA3'}}>Finito</Typography>
            </>
      case 'sospeso':
        return <>
            <Typography style={{fontSize:16,color:'white',background:'#C09B6E',borderRadius:10,margin:'0 auto',padding:'2px 8px'}}>S</Typography>
            <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#C09B6E'}}>Sospeso</Typography>
            </>
      case 'cancellato':
        return <>
            <Typography style={{fontSize:16,color:'white',background:'#E71C28',borderRadius:10,margin:'0 auto',padding:'2px 8px'}}>C</Typography>
            <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#E71C28'}}>Cancellato</Typography>
            </>
    }
  }

  function displayType(item){
    if(getInterventoListData() !== undefined && getInterventoListData() !== null){
      let res = getInterventoListData().filter(cIntervento => cIntervento !== null ? cIntervento.id == item.idIntervento : undefined)
      if(res){
        res = res[0]
      }
      if(res !== undefined){
        if(res.priorita === 'bassa'){
          return 'basso'
        }
        return res.priorita
      }
    }
  }

  function displayDiffColor(variant){
    if(variant === 'I. '){
      return '#00ADA2'
    }else {
      return '#65a1fd'
    }
  }

  function displayKind(item){
    if(getInterventoListData() !== undefined && getInterventoListData() !== null && item !== null ){
      let res = getInterventoListData().filter(cIntervento => cIntervento !== null ? cIntervento.id == item.idIntervento : undefined)
      if(res){
        res = res[0]
      }
      if(res !== undefined){
        if(res.tipologia === 'manutenzione'){
          return 'M. '
        }else{
          return 'I. '
        }
      }
      
    }
  }

  function printDesc(idInt){
    if(getInterventoListData() !== undefined){
      console.log(getInterventoListData(), idInt)
      let tmp = getInterventoListData().find(obj => obj.id == idInt)
      if(tmp){
        if(tmp.descrizione){
          return tmp.descrizione.substring(0,100) + (tmp.descrizione.length > 99 ? '...' : '.')
        }else {
          return 'Nessuna Descrizione Impostata'
        }
      }
    }
  }

  const WrapperConfig = {
    title:'Ticket',
    subTitle:'Da qui puoi gestire i tuoi contratti di manutenzione, approvare le attività, gestire il programma User, gestire l\'anagrafica impianto.',
    activeMenu:4,
  }

  return (
      <WrapperComponent {...WrapperConfig}>
    {/* LOAD DIALOGS */}
    { statusInfo === true ? ( <InfoIntervento rep={ e => {
        setStatusReport(true)
        console.log(e)
        setProgId(e.id)
        getAssetList('dd',e.byContratto)
        setDD(e.byContratto)
      } }target = {target} addr={Addr} flag={statusInfo} handleClose={ e => setStatusInfo(false)} progAct={progAct} isInt={isInt}/> ) : null}
      { statusReport === true ? <FirstReport status={statusReport} handleClose={ e => setStatusReport(false) } data={dd}/>  : null}
                
                <Box style={{marginTop:30,marginBottom:30,display:'flex',flexDirection:'row',justifyContent:'space-around',background:'white',padding:'15px 0px', alignItems:'center'}}>
            <Box style={{width:'40%'}}>
            <Box style={{display:'flex',alignItems:'center'}}>
                    <img src={require('../../assets/images/anagraficaContratto/anagraficaSituazione/ticketTotali.png')} style={{width:40,marginRight:30}} />
                    <Box className={classes.singleColumn} style={{width:'10%',display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:16}} onClick={ e=> {
              handleFilter('tipologia')
            } }>
              <Typography variant='h6' component='h2' style={{color:'#6A6A6A',margin:'auto 0'}}>Tipologia</Typography>
              {tipologiaArr === false ? (
                <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
              ) : ( 
                  <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
              )}</Box>
                </Box>
            </Box>
            <Box className={classes.singleColumn} style={{width:'10%'}} onClick={ e=> {
              handleFilter('data')
            } }>
              <Typography className={classes.filterText}>Data</Typography>
              {dateArr === false ? (
                <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
              ) : ( 
                  <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
              )}
            </Box>

            <Box className={classes.singleColumn} style={{width:'10%',display:'flex',justifyContent:'center'}} onClick={ e=> {
              handleFilter('priorita')
            } }>
              <Typography className={classes.filterText}>Priorità</Typography>
              {prioritaArr === false ? (
                <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
              ) : ( 
                  <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
              )}</Box>
            <Box className={classes.singleColumn} style={{width:'10%',display:'flex',justifyContent:'center'}} onClick={ e=> {
              handleFilter('status')
            } }>
              <Typography className={classes.filterText}>Status</Typography>
              {statusArr === false ? (
                <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
              ) : ( 
                  <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
              )}</Box>
            <Box className={classes.singleColumn} style={{width:'10%'}} onClick={ e=> {
              handleFilter('accepted')
            } }>
              <Typography className={classes.filterText}>Ultimazione</Typography>
              {acceptedArr === false ? (
                <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
              ) : ( 
                  <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
              )}</Box>
            <Box style={{width:'10%'}}></Box>
          </Box>
        <Container maxWidth="False" className={classes.body}>
          { ticketList!== null && ticketList!== undefined ? (
            ticketList.filter( ed => ed.stato !== 'malattia' && ed.stato !== 'ferie' && ed.stato !== 'permessi' && ed.confirmed === true && ed.variante !== 'commessa').map( (item,index) => {
              let dd 
              toDownload.map( cc => {
                if(cc[0] !== undefined){

                  if(cc[0].byProg == item.id){
                    dd = cc
                  }
                }
              } )
              return (
                <Box className={classes.oneRow}>
                  
                  <Box className={classes.ticketColumn} style={{width:'5%'}}>
                      <Box>
                      {displayKind(item) === 'M. ' ? (
                <Box>
                  <img src={require('../../assets/images/man.png')} className={classes.imgSTD}/>
              </Box>
              ) : (
                <Box>
                    <img src={require('../../assets/images/ticket/INTERVENTO.png')} className={classes.imgSTD}/>
                </Box>
              )}
                  </Box>
                  </Box>
                  <Box className={classes.ticketColumn} style={{width:'5%'}}>
                      <Box>
                          <Typography variant={'h6'} className={classes.columnText} style={{color:displayDiffColor(displayKind(item)),fontWeight:'bold'}}>{ displayKind(item) +  item.idIntervento}</Typography>
                      </Box>
                  </Box>
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>
                      <Box>
                          <Typography variant={'h6'} className={classes.columnText}>{printClient(item.byContratto)}</Typography>
                      </Box>
                  </Box>
                    
                  <Box className={classes.ticketColumn} style={{width:'15%'}}>
                      <Box >
                          <Typography variant={'subtitle1'} className={classes.columnText} style={{color:'#898989',fontWeight:'normal'}}>{printDesc(item.idIntervento)}</Typography>
                      </Box>
                  </Box>
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>
                      <Box>
                          <Typography variant={'h6'} className={classes.columnText}>{item.dataInizio !== undefined ? normalizeDates(item.dataInizio) : 'Caricamento...'}</Typography>
                      </Box>
                  </Box>
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>
                      <Box style={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}} >
                          <img src={showPriority(displayType(item))} className={classes.imgSTD} style={{margin:'0 auto'}}/>
                          <Typography variant={'overline'} className={classes.columnText} style={{color:showPriorityColor(displayType(item))}}>{displayType(item)}</Typography>
                      </Box>
                  </Box>
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>

                      <Box style={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',flexDirection:'column'}}>
                          {displayStatus(item.stato)}
                      </Box>
                  </Box>
                  
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>
                      <Box>
                          <Typography variant={'h6'} className={classes.columnText}>{item.dataFine !== undefined ? normalizeDates(item.dataFine) : 'Caricamento...'}</Typography>
                      </Box>
                      
                  </Box>
                  <iframe id="my_iframe" style={{display:'none'}}></iframe>
                  <Box className={classes.ticketColumn} style={{width:'10%'}}>
                      <Box>
                        <IconButton className={classes.lightB} style={{width:30,height:30,backgroundColor:'#dbf1f5',borderRadius:7,marginRight:15,zIndex:item.stato === 'finito' ? 1 : -99}} onClick={ async e => {
                          e.preventDefault()
                          let tmp = getRR().filter(obj => obj.byProg == item.id)
                          if(tmp){
                            let reps = tmp.map(obj => obj.id)
                            reps = reps.join(';')
                            createPDF(reps).then(res => {
                              window.open('https://itp.softwellitalia.it' + res,'_blank')
                            })
                          }
                        }}>
                            <DescriptionIcon style={{color:'#65a1fd',fontSize:18}}/>
                        </IconButton>
                         
                          <IconButton className={classes.lightB} style={{width:30,height:30,backgroundColor:'#DBF1F5',borderRadius:7,marginRight:15}} onClick={ async e=> {
                            await GetClienteFromProgramma('ddd',item.byContratto)
                            console.log(item)
                            await delay(500)
                            if(getInterventoListData() !== undefined && getInterventoListData() !== null && getInterventoListData().length > 0){
                              let res = getInterventoListData().filter(cIntervento => cIntervento !== null ? cIntervento.id == item.idIntervento : undefined)
                                  if(res){
                                    res = res[0]
                                  }
                              switch(res.tipologia){
                                  case 'manutenzione':
                                      setTarget(res)
                                      console.log(res)
                                      setProgAct(item)

                                      setIsInt(false)
                                      await getActivityList( getUserData().Token,res.byContratto )
                                      break;
                                  default:
                                    console.log('DDDDDDD')
                                      setTarget(item)
                                      setIsInt(true)
                                      setProgAct(item)
                                      await GetAssetFromProgramma(getUserData().Token,item.idIntervento)
                                      break;
                              }
                              setAddr(printClient(item.byContratto,true))
                              setStatusInfo(true)
                          }

                          }}>
                            <InfoIcon style={{color:'#65a1fd',fontSize:18}}/>
                          </IconButton>
                              <IconButton className={classes.lightR} style={{width:30,height:30,backgroundColor:'#F9D3C1',borderRadius:7,zIndex:getUserData() !== undefined ? ( getUserData().Tipologia !== 'Cliente' && getUserData().Tipologia !== 'Utente' ? 1 : -99) : -99}} onClick={ async e=>{
                                e.preventDefault()
                                let toRem = {
                                  token:getUserData().Token,
                                  id:item.id
                                }
                                await removeProgramma(toRem)
                                await delay(500)
                                await getFullProgrammaReq('dd')
                                window.location.reload(false)
                              } }>
                                <img className={classes.iconIMG}  src={require("../../assets/images/lista-utenti/delete.png")} /> 
                              </IconButton>
                      </Box>
                      
                  </Box>
                </Box>
              )
            } )
          ) : null }
         </Container>

      </WrapperComponent>
  )
}

export default ListaTicket
