import React from 'react'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox } from '@material-ui/core';
import webservice from '../../api/webservice';
import { useHistory } from "react-router-dom";
import contractData from '../data/contractData';
import Item from 'antd/lib/list/Item';

const useStyles = makeStyles({
  cardStyle: {
    width:'18%',
    paddingLeft:'.5%',
    paddingRight:'.5%',
    marginBottom:'5%',
    marginRight:'2%',
    marginLeft:'2%',
    borderRadius:10,
    paddingBottom:'.5%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    color:'#808080',
    fontSize: 14,
    letterSpacing:'.05em',
  },
  subTitle:{
      fontWeight:'bold',
      color:'black',
  },
  status: {
    marginBottom: 12,
    color:'#808080',
    fontSize:13
  },
  codiceImpianto:{
      color:'#f54463',
      cursor:'pointer'
  },
  contrattoMainImg: {
      height:24,
      padding:5,
      marginRight:20,
      border:'1px solid #77ABE7',
      borderRadius:8,
      cursor:'pointer'
  },
  imageContainer:{
      paddingLeft:16,
      width:'100%',
      marginLeft:'.5%',
      flexDirection:'row',
      display:'flex',
      justifyContent:'flex-start',
  },
  contrattoSet:{
    height:24,
      padding:5,
      marginRight:20,
      borderRadius:8,
      cursor:'pointer',
      paddingBottom:10,
      paddingTop:2,
      paddingLeft:3,
      paddingRight:3,
      fontWeight:'bold',
    width:30
  }
});

const Commessa = (props) => {
    const [data,setData] = React.useState({})
    const classes = useStyles();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const history = useHistory()
    const { GetAcquisizioneCommesse, GetReferenteCommessa, getAllegatoCommessa } = webservice()
    const { setNewContractData, setNewContractID, setNewContractType } = contractData()
  
    React.useEffect(() => {
      setData(props.data)
      console.log('COMMESSA', props.data)
    }, [props.data])

    function switchStatus(tmp){
      try{
        switch(tmp){
          case 0:
            return <img style={{float:'right',width:16,height:16}}src={require('../../assets/images/active.png')} />
          case 1:
            return <img style={{float:'right',width:16,height:16}}src={require('../../assets/images/suspend.png')} />
          case 2:
            return <img style={{float:'right',width:16,height:16}}src={require('../../assets/images/block.png')} />
        }
      }catch(e){
        console.error(e)
      }
      
    }
    let border = props.selected === true ? '2px solid #a0c8f9' : 'none'

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    if(data.id){
      return (
        <Card className={classes.cardStyle} style={{border:border,display:'flex',flexDirection:'column',justifyContent:'space-between'}} variant="outlined">
          <CardContent>
            <Box style={{display:'flex',justifyContent:'space-between'}}>
              <Typography className={classes.title} gutterBottom>
                Codice Commessa: <span className={classes.subTitle} style={{
                  color:props.variant === 'false' ? "#f54463" : '#898989'
                }}>{props.variant === 'false' ? "CHIUSO" : data.idCliente}</span>
              </Typography>
                {switchStatus(data.status)}
              </Box>
              <Typography variant="h4" component="h1" className={classes.codiceImpianto} onClick={async () => {
                console.log('Getting data')
                GetAcquisizioneCommesse('dd',data.id)
                GetReferenteCommessa('dd',data.id)
                let tmp = data
                tmp.datainizio = tmp.dataInizio
                tmp.datafine = tmp.dataFine
                tmp.codicepostale = data.cap
                tmp.account = tmp.idAccount
                tmp.cliente = tmp.idCliente
                setNewContractID(data.id)
                getAllegatoCommessa('dd',data.id)
                await delay(250)
                console.log(tmp,'DATAAAA')
                setNewContractData([tmp])
                setNewContractType(tmp.types.split(','))
                history.push('/commessa/nuovaCommessa/edit')
                await delay(1500)
              }}>
                {'C.' + zeroPad(data.id,4)}
              </Typography>
            <Typography className={classes.status} color="textSecondary">
              {data.descrizione.split(0,90)}
            </Typography>
          </CardContent>
          <Box style={{display:'flex'}}>
            <Box className={classes.imageContainer}>
            <img className={classes.contrattoMainImg} src={require('../../assets/images/listContratti/settingIconContratto.png')} onClick={async () => {
              console.log('Getting data')
              GetAcquisizioneCommesse('dd',data.id)
              GetReferenteCommessa('dd',data.id)
              let tmp = data
              tmp.datainizio = tmp.dataInizio
              tmp.datafine = tmp.dataFine
              tmp.codicepostale = data.cap
              tmp.account = tmp.idAccount
              tmp.cliente = tmp.idCliente
              setNewContractID(data.id)
              await delay(250)
              console.log(tmp,'DATAAAA')
              setNewContractData([tmp])
              history.push('/commessa/nuovaCommessa/edit')
              await delay(1500)
            }}/>
              {data.exist ? <Typography variant={'h6'} className={classes.contrattoSet} style={{background:'#FCF0FC',color:'#aa61c1'}}>Tk</Typography> : null }
            </Box>
            <Box className={classes.checkContainer} >
            <Checkbox onClick={props.onClick} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
          </Box>
          </Box>
        </Card>
      )
    }else{
      return null
    }
  
}

export default Commessa