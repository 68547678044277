import React, { useEffect,useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Snackbar, TextField, Typography } from "@material-ui/core"
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import webservice from '../../../api/webservice';
import anagraficaData from '../../data/anagraficaData';
import { Alert, Autocomplete } from '@material-ui/lab';
import RemoveIcon from '@material-ui/icons/Remove';
import CancelIcon from '@material-ui/icons/Cancel';
import userData from '../../data/userData';
import DownloadQR from '../../../components/qrCode/DownloadQR';

const useStyles = makeStyles((theme) => ({
    middleHeader:{
        display:'flex',
        justifyContent:'space-between',
        padding:'20px 15px'
    },
    middleHeaderIMG:{
        height:'1.2vw',
        margin:'0px 15px'
    },
    middleHeaderCol:{
        display:'flex',
        color:'#535353',
        width:'auto',
    },
    dialogMain:{
        width:'50%',
        color:'#535353'
    },
    columnSA:{
        display:'flex'
    },
    modalIMG:{
        height:16,
        marginRight:10
    },
    rowWIMG:{
        display:'flex',
        alignItems:'center',
        marginBottom:7
    },
    columnSA:{
        paddingLeft:50,
        paddingRight:50,
        paddingBottom:15,
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'2%',
        borderBottomWidth:2,
        borderBottomColor:'#eaeaea',
        borderBottomStyle:'dashed'
    },
    columnGA:{
        paddingLeft:50,
        paddingRight:50,
        paddingBottom:15,
        display:'flex',
        marginBottom:10,
        justifyContent:'space-between',
    },
    rowSAFirst:{
        display:'flex',
        flexDirection:'column'
    },
    defaultSelectBox:{
        width:'100%'
    },
    submitButton:{
        backgroundColor:'#00ADA2',
        color:'white',
        paddingLeft:30,
        paddingRight:30,
        alignItems:'center'
    },
    addAsset:{
        borderWidth:2,
        borderStyle:'solid',
        borderColor:'#00ada2',
        padding:'5px 0px',
        color:'#00ada2',
        marginLeft:5,
        '&:hover': {
            borderColor:'#A5D7CF',
            backgroundColor: 'transparent !important',
          }
    },
    leftAssetRow:{
        width:'80%',
    },
    assetRowGrouped:{
        display:'flex',
        justifyContent:'space-between',
        flexDirection:'row',
        borderTopWidth:2,
        borderTopStyle:'dashed',
        borderTopColor:'#eaeaea',
        paddingTop:20
    },
    rightAssetRow:{
        width:'15%',
        alignItems:'center',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    },
    buttonActionGAsset:{
        borderRadius:5,
        '&:hover': {
            backgroundColor:'#e2dcda !important',
          }
    },
    undoGrouped:{
        backgroundColor:'#0074BF',
        color:'white',
        letterSpacing:'.1em',
        fontSize:'0.6vw',
        paddingLeft:25,
        paddingRight:25,
        '&:hover': {
            backgroundColor:'#569bc7 !important',
        }
    },
    deleteGrouped:{
        backgroundColor:'#ff6767',
        color:'white',
        letterSpacing:'.1em',
        fontSize:'0.6vw',
        paddingLeft:25,
        paddingRight:25,
        '&:hover': {
            backgroundColor:'#ff8787 !important',
        }
    },
    filterRow:{
        marginBottom:'7%',
        width:'45%'
    },
    filterCol:{
      display:'flex',
      justifyContent:'space-between',
      flexDirection:'row'  
    },
    filterMain:{
        fontSize:'0.7vw',
        color:'#000',
        marginBottom:'3%'
    },
    sbmtN:{
        color:'white',
        paddingLeft:25,
        width:'20%',
        float:'right',
        paddingRight:25,
        background:'#00ada2',
        marginTop:'2%',
        marginLeft:'auto',
        marginBottom:'1%'
    }

}))

const Middle = ({contractID,token,switcher,handleSwitcher,assets,filterAction}) => {

    const [openAlert, setOpenAlert] = React.useState(false);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenAlert(false);
    };

    //Anagrafica data session
    const { getAnagraficaAssetGroupID, getAnagraficaData, getAssetID, getToDelete, getToDeleteRES } = anagraficaData()
    //variable for the New Asset Group
    const { getUserData } = userData()
    const [assetGroup,setAssetGroup] = useState(0)
    //API Integration
    const { createAsset, createAssetGroup, createLog, deleteAssetGroup } = webservice()
    const classes = useStyles();

    const [openSA, setOpenSA] = React.useState(false);
    const [openGA, setOpenGA] = React.useState(false);

    //SINGLE FUNCTION
    const handleClickOpenSA = () => {
        setOpenSA(true);
    };
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const handleCloseSA = () => {
        setOpenSA(false);
    };

    //GROUP FUNCTION
    const handleClickOpenGA = () => {
        setOpenGA(true);
    };
    const handleCloseGA = () => {
        setOpenGA(false);
    };

    //group asset n state
    const [ nAsset, setNAsset ] = useState([{id:1}])
    const [counter, setCounter] = useState(1)

    //Checker state button submitter
    const [checkSubmit, setCheckSubmit] = useState(true)
    //Single asset data state
    const [sData, setSData] = useState(
        {
            token:token,
            titolo:null,
            codice:null,
            matricola:null,
            marca:null,
            otherInfo:null,
            edificio:null,
            piano:null,
            stanza:null,
            areaEsterna:null,
            descrizione:null,
            byGroup:0,
            byContract:contractID
        }
    )
    const [disabledBtn,setDisabledBtn] = React.useState(false)
    //Group MAIN Asset data state
    const [gData, setGData] = useState(
        {
            token,
            titolo:null,
            descrizione:null,
            nAsset:null,
            codice:null,
            edificio:null,
            piano:null,
            stanza:null,
        }
    )
    //Group single asset data state
    const [gsData, setgSData ] = useState([{
        token:token,
        titolo:null,
        codice:null,
        matricola:null,
        marca:null,
        otherInfo:null,
        edificio:null,
        piano:null,
        stanza:null,
        areaEsterna:null,
        descrizione:null,
        byGroup:1,
        byContract:getAnagraficaData().id
    }])
    //Handle single asset data,
    function handleSData(e,target){
        let val = e.target.value;
        setSData( (prevState) => ({
            ...prevState,
            [target]:val
        }) )
    }

    //Handle group MAIN asset data,
    function handleGData(e,target){
        let val = e.target.value;
        setGData( (prevState) => ({
            ...prevState,
            [target]:val
        }) )
    }
    
    function handlegSData(e,target,index){
        let val = e.target.value
        let toUp = gsData
        toUp[index][target] = val
        setgSData( toUp )
        console.log(gsData)
    }
    //Filter data
    const [filter,showFilter] = React.useState(false)
    const [filterTo,setFilterTo] = React.useState({
        nome:null,
        posizione:null,
        cMatricola:null,
        cAnagrafica:null
    })
    function handleFilter(e,target){
        let val= e.target.value
        setFilterTo(prevState => ({
            ...prevState,
            [target]:val
        }))
    }
    function handleType(event,value){
        setFilterTo(prevState => ({
            ...prevState,
            ['nome']: value 
          }));
    }

    return (
        <>
        <Box className={classes.middleHeader}>
          <Box className={classes.middleHeaderCol}>
            <img className={classes.middleHeaderIMG} src={require('../../../assets/images/anagraficaContratto/asset/filtroAsset.png')}/>
            <Typography variant="subtitle1" style={{fontSize:'0.8vw',cursor:'pointer'}} onClick={e=> showFilter(true)}>Filtro Asset</Typography>
            <CancelIcon className={classes.middleHeaderIMG} style={{cursor:'pointer'}} onClick={ e=> {
                e.preventDefault();
                setFilterTo({
                    nome:null,
                    tipologia:null,
                    cMatricola:null,
                    cAnagrafica:null
                })
                filterAction(filterTo)
            } }/>
          </Box>
          {/*<Box className={classes.middleHeaderCol}>
            <img className={classes.middleHeaderIMG} src={require('../../../assets/images/anagraficaContratto/asset/rettangoloAsset.png')}/>
            <img className={classes.middleHeaderIMG} src={require('../../../assets/images/anagraficaContratto/asset/listAsset.png')}/>
            </Box>*/}
            {switcher === true ? (
                <Box className={classes.middleHeaderCol}>
                    <Button className={classes.undoGrouped} onClick={e=> handleSwitcher()}>Indietro</Button>
                    <Button className={classes.deleteGrouped} style={{marginLeft:20}}onClick={e=> {
                        e.preventDefault()
                        async function delG(){
                            let rem = {
                                token:'dd',
                                id:getToDelete().id
                            }
                            console.log(rem)
                            await deleteAssetGroup(rem)
                            await delay(500)
                            if( getToDeleteRES() == -3 ){
                                console.log('d')
                                alert('Il Gruppo non puo\' essere eliminato')
                            }else{
                                window.location.reload(false)
                            }
                        }
                        delG()
                    }}>Elimina Gruppo</Button>
                </Box>
            ) : null}
          <Box className={classes.middleHeaderCol}>
            <Box style={{display:'flex'}}>
              <Typography variant="subtitle1" style={{cursor:'pointer',fontSize:'0.8vw'}} onClick={handleClickOpenSA}>Nuovo Asset</Typography>
              <img className={classes.middleHeaderIMG} src={require('../../../assets/images/anagraficaContratto/asset/nAsset.png')}/>
            </Box>
            <Box style={{display:'flex',marginLeft:30}}>
              <Typography variant="subtitle1" style={{cursor:'pointer',fontSize:'0.8vw'}} onClick={handleClickOpenGA}>Nuovo Gruppo</Typography>
              <img className={classes.middleHeaderIMG} src={require('../../../assets/images/anagraficaContratto/asset/nAsset.png')}/>
            </Box>
          </Box>
        </Box>

        {/* SINGLE ASSET DIALOG */}
        <Dialog onClose={handleCloseSA} aria-labelledby="customized-dialog-title" open={openSA} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseSA}>
            Aggiungi Asset
            </DialogTitle>
            <DialogContent dividers>
                <Box style={{marginTop:15}}>
                    <Box className={classes.columnSA}>

                        <Box className={classes.rowSAFirst} style={{width:'50%'}}>
                            <Typography variant="subtitle" style={{marginBottom:7}}>Titolo Dell'Asset</Typography>
                            <TextField value={sData.titolo} onChange={e => handleSData(e,'titolo')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSAFirst}>
                            <Box className={classes.rowWIMG}>
                                <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/codiceAsset.png')} />
                                <Typography variant="subtitle">Codice Anagrafica</Typography>
                            </Box>
                            <TextField value={sData.codice} onChange={e => handleSData(e,'codice')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>

                    </Box>
                    <Box className={classes.columnSA}>
                        <Box className={classes.rowSASecond}>
                            <Box className={classes.rowWIMG}>
                                <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/matricolaAsset.png')} />
                                <Typography variant="subtitle">Matricola</Typography>
                            </Box>
                            <TextField value={sData.matricola} onChange={e => handleSData(e,'matricola')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSASecond}>
                            <Box className={classes.rowWIMG}>
                                <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/brandAsset.png')} />
                                <Typography variant="subtitle">Marca</Typography>
                            </Box>
                            <TextField value={sData.marca} onChange={e => handleSData(e,'marca')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSASecond}>
                            <Box className={classes.rowWIMG}>
                                <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/anagraficaAsset.png')} />
                                <Typography variant="subtitle">Altre Informazioni</Typography>
                            </Box>
                            <TextField value={sData.otherInfo} onChange={e => handleSData(e,'otherInfo')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                    </Box>
                    <Box className={classes.columnSA}>
                        <Box className={classes.rowSAThird}>
                            <Box className={classes.rowWIMG}>
                                <Typography variant="subtitle" style={{width:'100%'}}>Edificio</Typography>
                            </Box>
                            <TextField value={sData.edificio} onChange={e => handleSData(e,'edificio')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSAThird}>
                            <Box className={classes.rowWIMG}>
                                <Typography variant="subtitle">Piano</Typography>
                            </Box>
                            <TextField value={sData.piano} onChange={e => handleSData(e,'piano')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSAThird}>
                            <Box className={classes.rowWIMG}>
                                <Typography variant="subtitle">Stanza/Locale</Typography>
                            </Box>
                            <TextField value={sData.stanza} onChange={e => handleSData(e,'stanza')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                        <Box className={classes.rowSAThird}>
                            <Box className={classes.rowWIMG}>
                                <Typography variant="subtitle">Area Esterna</Typography>
                            </Box>
                            <TextField value={sData.areaEsterna} onChange={e => handleSData(e,'areaEsterna')} inputProps={{style:{paddingLeft:7,paddingTop:5,paddingBottom:5}}} id="outlined-basic" variant="outlined" />
                        </Box>
                    </Box>
                    <Box className={classes.columnSA} style={{borderBottomWidth:0}}>
                        <Box style={{width:'100%'}}>
                            <Typography variant="subtitle" style={{marginBottom:7}}>Descrizione</Typography>
                            <TextField
                                value={sData.descrizione} 
                                onChange={e => handleSData(e,'descrizione')}
                                id="outline-multiline-static"
                                multiline
                                inputProps={{style:{paddingLeft:0,paddingTop:0,paddingBottom:0}}}
                                className={classes.defaultSelectBox}
                                rowsMax={8}
                                variant="outlined"
                                rows={3}
                            />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <DownloadQR codice={sData.codice} active={sData.codice && sData.codice.length > 0 ? true : false} />
            <Button 
                autoFocus 
                disabled={disabledBtn}
                onClick={async e => {
                    e.preventDefault()
                    setDisabledBtn(true)
                    console.log(sData)
                    await createAsset(sData)
                    await delay(1000)
                    console.log(getAssetID())
                    let toUpLog={
                        token:getUserData().Token,
                        type:1,
                        contenuto:'Creazione Asset',
                        byAsset:getAssetID(),
                        byUser:getUserData().ID
                    }
                    await createLog(toUpLog)
                    handleCloseSA()
                    window.location.reload(false);
                }} 
                className={classes.submitButton}>
                <span style={{textTransform:'none'}}>Salva e Chiudi </span><DoneIcon style={{marginLeft:10,fontSize:16}}/>
            </Button>
            </DialogActions>
        </Dialog>
        {/* GROUP ASSET DIALOG */}
        <Dialog onClose={handleCloseGA} aria-labelledby="customized-dialog-title" open={openGA} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseGA}>
            Aggiungi Gruppo Asset
            </DialogTitle>
            <DialogContent dividers>
                <Box style={{marginTop:15}}>
                    <Box style={{marginBottom:25}}>
                        <Box className={classes.columnGA}>

                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Titolo del Gruppo</Typography>
                                <TextField disabled={!checkSubmit} value={gData.titolo} onChange={e => handleGData(e,'titolo')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>
                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Descrizione</Typography>
                                <TextField disabled={!checkSubmit} value={gData.descrizione} onChange={e => handleGData(e,'descrizione')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>
                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Nr. Asset</Typography>
                                <Box>
                                    <TextField disabled={!checkSubmit} value={counter}disabled={true}inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} style={{width:'30%'}} id="outlined-basic" variant="outlined" />
                                    <Button onClick={e => {
                                        let toUp=nAsset
                                        toUp.push({id:counter+1})
                                        setCounter(counter+1);
                                        setNAsset(toUp)
                                        toUp = gsData;
                                        toUp.push({
                                            token:token,
                                            titolo:null,
                                            codice:null,
                                            matricola:null,
                                            marca:null,
                                            otherInfo:null,
                                            edificio:null,
                                            piano:null,
                                            stanza:null,
                                            areaEsterna:null,
                                            descrizione:null,
                                            byGroup:1,
                                            byContract:contractID
                                        })
                                        setgSData(toUp)

                                    } } className={classes.addAsset}><AddIcon/></Button>
                                </Box>
                            </Box>

                        </Box>
                        <Box className={classes.columnGA}>

                            <Box className={classes.rowSAFirst}>
                                <Box className={classes.rowWIMG}>
                                    <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/codiceAsset.png')} />
                                    <Typography variant="subtitle">Codice Anagrafica</Typography>
                                </Box>
                                <TextField disabled={!checkSubmit} value={gData.codice} onChange={e => handleGData(e,'codice')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>
                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Edificio</Typography>
                                <TextField disabled={!checkSubmit} value={gData.edificio} onChange={e => handleGData(e,'edificio')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>
                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Piano</Typography>
                                <TextField disabled={!checkSubmit} value={gData.piano} onChange={e => handleGData(e,'piano')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>
                            <Box className={classes.rowSAFirst}>
                                <Typography variant="subtitle" style={{marginBottom:7}}>Stanza/Locale</Typography>
                                <TextField disabled={!checkSubmit} value={gData.stanza} onChange={e => handleGData(e,'stanza')} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                            </Box>

                        </Box>
                    </Box>
                    <Box>
                        { nAsset.map((item,index) => {
                            return (
                                <Box className={classes.assetRowGrouped}>
                                    <Box className={classes.leftAssetRow}>
                                        <Box className={classes.columnGA}>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/matricolaAsset.png')} />
                                                    <Typography variant="subtitle">Matricola</Typography>
                                                </Box>
                                                <TextField value={gsData[index].matricola} onChange={e=> handlegSData(e,'matricola',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/brandAsset.png')} />
                                                    <Typography variant="subtitle">Marca</Typography>
                                                </Box>
                                                <TextField value={gsData[index].marca} onChange={e=> handlegSData(e,'marca',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/codiceAsset.png')} />
                                                    <Typography variant="subtitle">Codice Anagrafica</Typography>
                                                </Box>
                                                <TextField value={gsData[index].codice} onChange={e=> handlegSData(e,'codice',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                        </Box>
                                        <Box className={classes.columnGA}>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <img className={classes.modalIMG} src={require('../../../assets/images/anagraficaContratto/asset/anagraficaAsset.png')} />
                                                    <Typography variant="subtitle">Altre Informazioni</Typography>
                                                </Box>
                                                <TextField value={gsData[index].otherInfo} onChange={e=> handlegSData(e,'otherInfo',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <Typography variant="subtitle">Stanza/Locale</Typography>
                                                </Box>
                                                <TextField value={gsData[index].stanza} onChange={e=> handlegSData(e,'stanza',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                            <Box className={classes.rowSAFirst}>
                                                <Box className={classes.rowWIMG}>
                                                    <Typography variant="subtitle">Area Esterna</Typography>
                                                </Box>
                                                <TextField value={gsData[index].areaEsterna} onChange={e=> handlegSData(e,'areaEsterna',index)} inputProps={{style:{paddingLeft:10,paddingTop:10,paddingBottom:10}}} id="outlined-basic" variant="outlined" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.rightAssetRow}>
                                        <Box style={{margin:'auto',display:'flex',justifyContent:'space-around',width:'100%'}}> 
                                            
                                            <Button className={classes.buttonActionGAsset} style={{backgroundColor:'#FFC9BE', color:'#E26553'}} onClick={e => {
                                                let toDelete = nAsset.filter(object => object.id != item.id)
                                                setNAsset(toDelete)
                                                setCounter(counter- 1)
                                                let toUp = gsData.splice(index,index)
                                                console.log(toUp)
                                            }}> <DeleteIcon /> </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        }) }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
            <Button 
            disabled={disabledBtn}
            onClick={async (e) => {
                setDisabledBtn(true)
                async function sendForm(){
                    let toUp= gData
                toUp.nAsset = counter
                toUp.byContratto = getAnagraficaData().id
                console.log(toUp)
                await createAssetGroup(toUp)
                await delay(500)
                await setCheckSubmit(false)
                await setAssetGroup( getAnagraficaAssetGroupID() )
                await delay(500)
                await gsData.map( async (asset,index) => {
                    let toUp = gsData[index]
                    toUp.descrizione = gData.descrizione
                    toUp.edificio = gData.edificio
                    toUp.piano = gData.piano
                    toUp.titolo = gData.titolo
                    toUp.byGroup = getAnagraficaAssetGroupID()
                    console.log(toUp)
                    await createAsset(toUp)
                    await delay(1500)
                    let toUpLog={
                        token:getUserData().Token,
                        type:1,
                        contenuto:'Creazione Asset',
                        byAsset:getAssetID(),
                        byUser:getUserData().ID
                    }
                    await createLog(toUpLog)
                    await delay(1000)
                } )
                await delay(1000)
                handleCloseGA()
                window.location.reload(false);
                }
                sendForm()

            }} className={classes.submitButton}>
                <span style={{textTransform:'none'}}>Salve e Chiudi </span><DoneIcon style={{marginLeft:10,fontSize:16}}/>
            </Button>
            </DialogActions>
        </Dialog>
        {/* FILTER ASSET DIALOG*/}
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="success">
            Asset Aggiunto con successo
            </Alert>
        </Snackbar>

        {/* Show filter*/}
        <Dialog
                open={filter}
                maxWidth={'lg'}
                fullWidth={true}
                onClose={e=> showFilter(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{borderBottomWidth:1,borderBottomStyle:'solid',borderBottomColor:'#e8e8e8'}}>Filtro Asset</DialogTitle>
                <DialogContent>
                    <Box className={classes.filterCol}>
                        <Box className={classes.filterRow}>
                            <Typography variant={'h6'} className={classes.filterMain}>Cerca per Posizione( Edificio,Piano...):</Typography>
                            <TextField id="standard-basic-matr" value={filterTo.posizione} variant="outlined" style={{width:'100%'}} onChange={ e => {
                                handleFilter(e,'posizione')
                            } } />
                        </Box>
                        <Box className={classes.filterRow}>
                            <Typography variant={'h6'} className={classes.filterMain}>Cerca per Asset:</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={assets}
                                getOptionLabel={(option) => option.titolo}
                                onInputChange={ handleType }
                                className={classes.formRowInput}
                                renderInput={(params) => <TextField {...params}  variant="outlined" />}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.filterCol}>
                        <Box className={classes.filterRow}>
                            <Typography variant={'h6'} className={classes.filterMain}>Cerca per Informazioni(Matricola, Marca):</Typography>
                            <TextField id="standard-basic-matr" value={filterTo.cMatricola} variant="outlined" style={{width:'100%'}} onChange={ e => {
                                handleFilter(e,'cMatricola')
                            } } />
                        </Box>
                        <Box className={classes.filterRow}>
                            <Typography variant={'h6'} className={classes.filterMain}>Cerca per codice Anagrafica:</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={assets}
                                getOptionLabel={(option) => option.codice}
                                onInputChange={ handleType }
                                className={classes.formRowInput}
                                renderInput={(params) => <TextField {...params}  variant="outlined" />}
                            />
                        </Box>
                    </Box>
                    

                    <Button className={classes.sbmtN} autoFocus onClick={ e=> {
                        filterAction(filterTo)
                    } }>
                        Genera Filtro
                    </Button>
                </DialogContent>
            </Dialog>
        
        </>
    )

}

export default Middle