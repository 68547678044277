import React from 'react'
import exportFromJSON from 'export-from-json'
import { useHistory } from "react-router-dom";

export default() => {
    const history = useHistory();

    var setNewClientData= (data) => {
        localStorage.setItem('newClienteData', JSON.stringify(data));
    }
    var setInterventoTMP= (data) => {
        if(data!== null && JSON.parse(localStorage.getItem('tmpIntervento'))!== null && JSON.parse(localStorage.getItem('tmpIntervento')).length >0){
            let tmp = JSON.parse(localStorage.getItem('tmpIntervento'));
            tmp.push(data)
            localStorage.setItem('tmpIntervento', JSON.stringify(tmp));
        }else{
            localStorage.setItem('tmpIntervento', JSON.stringify(data))
        }
    }

    var updateContactReferent= (data) => {
        localStorage.setItem('newContactReferent', JSON.stringify(data))
    }
    var updateClientReferent= (data) => {
        localStorage.setItem('newClientReferent', JSON.stringify(data))
    }
    var setContactReferent= (data) => {
        if(data == 'reset'){
            localStorage.setItem('newContactReferent', null)
        }else if(JSON.parse(localStorage.getItem('newContactReferent')) !== null){
            let toSet = JSON.parse(localStorage.getItem('newContactReferent'))
            toSet.push(data)
            localStorage.setItem('newContactReferent', JSON.stringify(toSet))
        }else{
            localStorage.setItem('newContactReferent', JSON.stringify([data]))
        }
        
    } 
    var setClientReferent= (data) => {
        if(data == 'reset'){
            localStorage.setItem('newClientReferent', null)
        }else if(JSON.parse(localStorage.getItem('newClientReferent')) !== null){
            let toSet = JSON.parse(localStorage.getItem('newClientReferent'))
            toSet.push(data)
            localStorage.setItem('newClientReferent', JSON.stringify(toSet))
        }else{
            localStorage.setItem('newClientReferent', JSON.stringify([data]))
        }
        
    }
    var setNewClientID= (data) => {
        localStorage.setItem('neClientID', JSON.stringify(data))
    }
    var setClientData= (data) => {
        localStorage.setItem('clientData', JSON.stringify(data));
        console.log(data)
    }
    

    var getClientData= () => {
        return JSON.parse(localStorage.getItem('clientData'))
    }
    var getNewClientID= () => {
        return localStorage.getItem('neClientID')
    }
    var getContactReferent= () => {
        return JSON.parse(localStorage.getItem('newContactReferent'))
    }
    var getClientReferent= () => {
        return JSON.parse(localStorage.getItem('newClientReferent'))
    }
    var getNewClientData= () => {
        return JSON.parse(localStorage.getItem('newClienteData'))
    }
    var getInterventoTMP= () => {
        return JSON.parse(localStorage.getItem('tmpIntervento'))
    }
    var getInterventoListData= () => {
        return JSON.parse(localStorage.getItem('tmpIntervento'))
    }

    return {
        setNewClientData,
        getNewClientData,
        updateContactReferent,
        setContactReferent,
        getContactReferent,
        updateClientReferent,
        setClientReferent,
        getClientReferent,
        setNewClientID,
        getNewClientID,
        setClientData,
        getClientData,
        setInterventoTMP,
        getInterventoTMP,
        getInterventoListData
    }
        
}
