import React, {useState, useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, Container, Divider, Input, InputAdornment, Paper, Typography } from "@material-ui/core"
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
//Contract Data File
import contractData from '../data/contractData';
import { useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Referent from '../components/referent'

import MenuIcon from '@material-ui/icons/Menu';
import userData from '../data/userData';
import webservice from '../../api/webservice';
import anagraficaData from '../data/anagraficaData';
import clientData from '../data/clientData';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    Header:{
        backgroundColor: "#00ADA2",
        height: 50,
        paddingTop:12
      },
    listaTable:{
        marginTop:40,
        height:'auto',
        width:'95%',
        paddingBottom:30,
        borderRadius:15,
        backgroundColor:'white',
      },
      tabName:{
        padding:15,
        paddingTop:30,
        fontWeight:'bold',
        fontSize:25, 
        letterSpacing:'.05em',
        color:'#6C6C6C'
      },
      subTabName:{
        paddingLeft:15,
        color:'#535353',
        fontSize:18,
        fontWeight:'bold',
        letterSpacing:'0.05em',
        textTransform:'capitalize',
        textAlign:'left'
       },
       mainBox:{
           paddingTop:30,
           display:'flex',
           justifyContent:'space-between'
       },
       midBox:{
        width:'25%'
       },
       rightBox:{
        width:'25%'
       },
       mainRow:{
        padding:'30px 20px',
        paddingLeft:0,
        height:'20%'
       },
       normalInput:{
        border:'1px solid #F3F3F3',
        width:'100%',
        paddingTop:10,
        paddingBottom:10,
        fontSize:20,
        paddingLeft:15,
        borderRadius:7
       },
       styledInput:{
        fontSize:20,
        width:'100%',
        borderRadius:7,
        border:'1px solid #F3F3F3',
       },
       euroLogo:{
        backgroundColor:'#F3F3F3',
        borderRight:'1px solid #eaeaea',
        padding:15,
        paddingTop:25,
        paddingBottom:25,
        color:'black'
    },
    subSpecialName:{
        color:'#30BCB1',
        fontSize:16,
        paddingLeft:15,
        fontWeight:'bold',
        letterSpacing:'.05em',
        cursor:'pointer'
    },
    button:{
        backgroundColor:'#00ADA2',
        textDecoration:'capitalize',
        width:'15%'
      },
      buttonBack:{
          width:'15%',
          background:'transparent',
          color:'black',
          '&:hover': {
            backgroundColor: '#f1e9e9 !important',
        }
      },
      errorBasic: {
        color:'red',
        paddingLeft:30
      },
}))

export default({dataC,handleClose,status}) => {
    function useForceUpdate() {
        let [value, setState] = useState(true);
        return () => setState(!value);
      }
      //F update
      let forceUpdate =  useForceUpdate()
      const { setDrawer } = userData()
    const history = useHistory()
    const { GetReferenteCliente, createReferenteContratto } = webservice()
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const { getReferenteContrattoData, getAnagraficaData } = anagraficaData()
    //contractData
    const { getClientData } = clientData()
    const { setContractReferent, getContractReferent, updateContractReferent } = contractData()
    //main referent data
    const [data,setData] = useState()
    //torender
    const [toRenderData,setToRenderData] = useState([])
    const classes = useStyles();

    useEffect(() => {
        async function initialData(){
            await GetReferenteCliente('dd',getClientData().id)
            await delay(1000)
            setToRenderData( getReferenteContrattoData() )
            await delay(500)
        }
        initialData()
    }, [status])
    return (
        <Dialog
        open={status}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
    <DialogContent>
    <Container maxWidth="False" className={classes.listaTable}>
                    <Typography className={classes.tabName}>LISTA REFERENTI</Typography>
                    <Divider style={{marginTop:30,marginBottom:30}}></Divider>
                    <Box className={classes.referentContainer} style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                        {toRenderData !== null && toRenderData !== undefined ? (
                            toRenderData.map( item => {
                                let data = {
                                    nome:item.nome,
                                    posizione:item.posizione,
                                    email:item.email,
                                    pec:item.pec === 'null' ? null : item.pec,
                                    nTel:item.telefono_one,
                                    nTelS:item.telefono_two
                                }
                                return <Referent data={data} type={'dash'} from='cliente'></Referent>
                            })
                        ) : null}          
                    </Box>
                </Container>
              
    </DialogContent>
    <DialogActions>
                  <Box style={{display:'flex',width:'100%',justifyContent:'space-between',paddingLeft:50,paddingRight:50}}>
                    <Button
                       variant="contained"
                       color="primary"
                       fullWidth={false}
                       onClick={ () => {
                         handleClose()
                       } }
                       className={classes.button}
                       startIcon={<ArrowBackIcon></ArrowBackIcon>}                       
                       >
                       Chiudi
                      </Button>

                      
                  </Box>
            </DialogActions>
    </Dialog>          
  )
}