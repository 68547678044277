import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Avatar, Box, Button, Container, Divider, IconButton, Typography } from "@material-ui/core"
import axios from 'axios'

import { useHistory } from "react-router-dom";

import userData from '../../data/userData';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useForm } from 'react-hook-form'
import DeleteIcon from '@material-ui/icons/Delete';
import webservice from '../../../api/webservice';
import anagraficaData from '../../data/anagraficaData';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: 50,
    paddingTop: '40px',
  },
  leftTab: {
    width: '50%',
  },
  tabUpperBox: {
    display: 'flex',
    marginBottom: 20
  },
  tabHeader: {
    color: '#333',
    fontWeight: 'bold',
    marginRight: 15,
    margin: 'auto 0px'
  },
  tabSubHeader: {
    color: '#898989',
    margin: 'auto 0px'
  },
  singleOption: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 4,
    paddingBottom: 4,
    borderColor: '#e8e8e8',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 7,
    marginBottom: 17,
    cursor: 'pointer'
  },
  imgSTD: {
    height: 32
  },
  tabTitle: {
    margin: 'auto 0px',
    color: '#898989'
  },
  bottomMiddle: {
    width: '80%',
    paddingTop: 7
  },
  bottomFinal: {
    paddingTop: 7
  },
  singleOptionRes: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 7,
    marginBottom: 17,
    justifyContent: 'flex-start',
    paddingRight: 70
  },
  bottomStartRes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 20
  },
  bottomMiddleRes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '75%'
  },
  bottomFinalRes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  imgSTDres: {
    width: 18
  },
  avatarClass: {
    backgroundColor: 'lightblue'
  },
  headDoc: {
    display: 'flex',
    justifyContent: 'space-between',

  },
  bodyDoc: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  docRow: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px',
  },
  docRowText: {
    fontSize: 16,
    color: '#8e8e8e',
    fontWeight: 'bold'
  },
}))



const Documenti = ({ data, variant }) => {
  const { uploadAllegato, getAllegato } = webservice()
  const { getAnagraficaData, getAllegatoData } = anagraficaData()
  const classes = useStyles()
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const history = useHistory()
  const { deleteAllegato } = webservice()
  const [dataFiles, setDataFiles] = React.useState(data)
  const hiddenFileInput = React.useRef(null);
  const { getUserData } = userData()
  const { register, handleSubmit } = useForm()
  //ForceUpdate
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();
  console.log('FILES', dataFiles)


  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const onSubmit = async event => {
    var data = event.target.files[0];
    let id = getUserData().ID.toString()
    //Creating name file
    var str = "00000000";
    let len = 8 - id.length
    let tmpPath = (str.substring(0, len)) + id
    var resName = data.name + '[**]' + '/contratto/' + (str.substring(0, 8 - getAnagraficaData().id.toString().length)) + getAnagraficaData().id;
    var today = new Date();

    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    var dateTime = date + 'T' + time;
    let fin = dataFiles
    fin.push({
      created: dateTime,
      owner: getUserData().Nome + ' ' + getUserData().Cognome,
      path: "uploads/" + tmpPath + '/' + data.name
    })
    console.log(fin)


    const formData = new FormData()
    formData.append('file', data)
    formData.append("document", resName)
    console.log('SENT')
    const res = await fetch("https://itp-njs.softwellitalia.it/filesUpload/contratti", {
      method: "POST",
      body: formData
    }).then(res => res.json())
    //setDataFiles(fin)

    let toUp = {
      token: getUserData().Token,
      path: res.filePath,
      name: data.name,
      byContratto: getAnagraficaData().id,
      owner: getUserData().Nome + ' ' + getUserData().Cognome
    }
    await uploadAllegato(toUp)
    await delay(500)
    await getAllegato(getUserData().Token, getAnagraficaData().id)
    await delay(1000)
    setDataFiles(getAllegatoData())

    forceUpdate()
  }



  //Choosing icon
  function showIcon(data) {
    if (data === undefined || data === null) return null
    let extension = data.split('.')
    extension = extension[(extension.length) - 1]
    switch (extension) {
      case 'pdf':
        return require('../../../assets/images/creaContratto/formats/pdf.png')
      case 'jpg':
        return require('../../../assets/images/creaContratto/formats/jpg.png')
      case 'jpeg':
        return require('../../../assets/images/creaContratto/formats/jpg.png')
      case 'png':
        return require('../../../assets/images/creaContratto/formats/png.png')
      case 'ppt':
        return require('../../../assets/images/creaContratto/formats/ppt.png')
      case 'pptx':
        return require('../../../assets/images/creaContratto/formats/ppt.png')
      case 'doc':
        return require('../../../assets/images/creaContratto/formats/doc.png')
      case 'docx':
        return require('../../../assets/images/creaContratto/formats/doc.png')
      case 'dwg':
        return require('../../../assets/images/creaContratto/formats/dwg.png')
      case 'exc':
        return require('../../../assets/images/creaContratto/formats/exc.png')
      case 'xls':
        return require('../../../assets/images/creaContratto/formats/exc.png')
      case 'xlsx':
        return require('../../../assets/images/creaContratto/formats/exc.png')
      default:
        return require('../../../assets/images/creaContratto/formats/other.png')
    }
  }
  return (
    <>
      <Box className={classes.mainBox}>

        <Box className={classes.leftTab}>
          <Box className={classes.tabUpperBox}>
            <Typography variant={'h5'} className={classes.tabHeader}>DOCUMENTI</Typography>

            <Typography variant={'caption'} className={classes.tabSubHeader}>Lista dei documenti allegati al contratto</Typography>
            {variant !== 'client' ? <Button style={{
              color: 'white',
              backgroundColor: '#25b4a7',
              padding: '5px 25px',
              borderRadius: 5,
              marginLeft: 20
            }} onClick={handleClick}>Carica Documenti</Button>
              : null}
            <input ref={register} ref={hiddenFileInput} onChange={onSubmit} style={{ display: 'none' }} type="file" name="files" />
          </Box>

          <Box className={classes.bottomTab}>
            <Box className={classes.headDoc}>
              <Typography className={classes.docRow} style={{ fontSize: 18, fontWeight: 'bold', color: '#535353' }}>Documento</Typography>
              <Typography className={classes.docRow} style={{ fontSize: 18, fontWeight: 'bold', color: '#535353' }}>Modifica</Typography>
              <Typography className={classes.docRow} style={{ fontSize: 18, fontWeight: 'bold', color: '#535353' }}>Data</Typography>
            </Box>
            <Divider></Divider>

            {dataFiles !== null ?
              dataFiles.map((item, index) => {
                return (
                  <>
                    <Box className={classes.midBox}>
                      <Box>
                        <Box className={classes.bodyDoc}>
                          <Box className={classes.docRow}>
                            <img style={{ width: 32, height: 32, marginRight: 15 }} src={showIcon(item.name)} />
                            <Typography className={classes.docRowText}>{item.name}</Typography>
                          </Box>
                          <Box className={classes.docRow}>
                            <Typography className={classes.docRowText} style={{ textTransform: 'capitalize' }}>{item.owner}</Typography>
                          </Box>
                          <Box className={classes.docRow} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={classes.docRowText}>{item.created.split('T')[0]}</Typography>
                            <CloudDownloadIcon style={{ cursor: 'pointer' }} onClick={async e => {
                              await axios.get('https://itp-njs.softwellitalia.it/download/?path=' + item.path + '&name=' + item.name, {
                                headers: {
                                  'Content-Type': 'image/png'
                                }
                              }).then(async res => {
                                window.open('https://itp.softwellitalia.it/' + res.data, '_blank')
                              })
                            }} />
                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={async e => {
                              async function dell() {
                                let toDel = {
                                  token: getUserData().Token,
                                  id: item.id
                                }
                                await deleteAllegato(toDel)
                                await delay(500)
                                await getAllegato(getUserData().Token, getAnagraficaData().id)
                                await delay(1000)
                                setDataFiles(getAllegatoData())
                                //window.location.reload(false)
                              }
                              dell()
                            }} />

                          </Box>
                        </Box>

                      </Box>

                    </Box>
                  </>
                )

              })

              : <>
                <Box className={classes.midBox}>
                  <Box>
                    <Box className={classes.bodyDoc}>
                      <Box className={classes.docRow}>
                        <img style={{ width: 32, height: 32, marginRight: 15 }} src={showIcon('vuoto')} />
                        <Typography className={classes.docRowText}>VUOTO</Typography>
                      </Box>
                      <Box className={classes.docRow}>
                        <Typography className={classes.docRowText}>VUOTO</Typography>
                      </Box>
                      <Box className={classes.docRow} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className={classes.docRowText}>VUOTO</Typography>

                      </Box>
                    </Box>

                  </Box>

                </Box>
              </>}

          </Box>
        </Box>

      </Box>
    </>
  )
}

export default Documenti
