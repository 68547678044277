import React, { useState, useEffect } from 'react'
import { Avatar, IconButton, Box, Button, Checkbox, DialogActions, DialogContent, DialogContentText, Chip, DialogTitle, FormControlLabel, Input, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Select, TextField, Typography, RadioGroup, Radio } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Autocomplete } from '@material-ui/lab';
import webservice from '../../../api/webservice';
import userData from '../../data/userData';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import anagraficaData from '../../data/anagraficaData';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import { Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FormatListBulletedRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  helperTopText: {
    fontWeight: 'bold'
  },
  defInput: {
    width: '100%'
  },
  stdImage: {
    height: 20
  },
  mainRow: {
    justifyContent: 'space-between',
    marginBottom: 15,
    display: 'flex',
  },
  columnLabel: {
    color: '#535353',
    marginBottom: 10
  },
  controlLabel: {
    fontSize: '14px !important'
  },
  button: {
    backgroundColor: '#00ADA2',
    textDecoration: 'capitalize',
    width: '25%'
  },
  buttonBack: {
    width: '25%',
    background: 'transparent',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f1e9e9 !important',
    }
  },
}))

const AggiungiAttivita = ({ user, flag, handleClose, date }) => {
  const classes = useStyles();
  const [error, setError] = React.useState()

  function toDateTime(date, action) {

    var now = new Date()
    if (action === 0) {
      return (date + ' ' + now.getHours() + ':' + now.getMinutes())
    } else if (action === 1) {
      if (now.getHours() + date > 24) {
        return (now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + (now.getDate() + 1) + ' ' + ((now.getHours() + date) - 24) + ':' + now.getMinutes())
      } else {
        return (now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + (now.getHours() + date) + ':' + now.getMinutes())
      }
    } else {
      return (date + ' ' + '00:00')
    }

  }


  function getDates(startDate, stopDate) {
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    Date.prototype.remDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() - days);
      return date;
    }
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    console.log(startDate)
    let lastDay = new Date(stopDate)
    let firstDay = new Date(startDate).remDays(1)
    const diffTime = Math.abs(firstDay - lastDay);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let final = []
    for (let i = 0; i < diffDays; i++) {
      final.push(formatDate(firstDay.addDays(1)))
      firstDay = firstDay.addDays(1)
    }
    return (final)
  }


  //Sort INT AVAILABLE
  function sortInterventi(arr1, arr2) {
    var arrayIDIntervento = []
    var interventiSorted = []

    arr1.map(ticket => {
      if (ticket.stato == "finito" || ticket.stato == "ferie" || ticket.stato == "permessi" || ticket.stato == "malattia") {
        arrayIDIntervento.push(ticket.idIntervento)
      }
    })
    arr2.map(item => {
      if (item.accepted === 1) {
        if (!arrayIDIntervento.includes(item.id)) {
          interventiSorted.push(item)
        }
      }
    })
    return interventiSorted
  }

  //Call API webservice
  const { getUserList, createMezzo, userList, createIntervento, getInterventoList, createPrograma, getMezziList, insertMezzoAssegnato, getAvailableInterventi, GetFullInterventoList, getMezziAssegnati, getCommesseList } = webservice()
  const { getUserData } = userData()//Token,idcontratto
  const { getInterventoListData, getAnagraficaData, getMezziListData, getFullProgramma, getAvInterventi } = anagraficaData()
  const [intData, setIntData] = React.useState([])
  const [mezziList, setMezzi] = React.useState([])
  var lastDate = ''
  const [mezziFiltered, setMezziFiltered] = React.useState([])
  React.useEffect(() => {
    async function initialData() {
      //await getAvailableInterventi(getUserData().Token)
      await getMezziList(getUserData().Token)
      await getUserList(getUserData().Token)
      await GetFullInterventoList(getUserData().Token)
      setIntData(getInterventoListData())
      await delay(1000)
      setMezzi(getMezziListData())
      setMezziFiltered(getMezziListData())
      setToShow(sortInterventi(getFullProgramma(), getInterventoListData()))
      getCommesseList('dd').then(res => {
        setToShow([...sortInterventi(getFullProgramma(), getInterventoListData()), ...res.map(obj => ({ ...obj, tipologia: "commessa" }))])
      })
    }
    initialData()
  }, [])

  //BLOCCO FUNZIONI E STATI MODIFICA DATA
  const [richiestaData, setRichiestaData] = useState({
    tipologia: null,
    tempo: null,
    dataInizio: null,
    dataFine: null,
    priorita: {
      attivo: false,
      ferie: false,
      permessi: false,
      malattia: false
    },
    ragSociale: null,
    descrizione: null
  })
  function handleChangeRichiesta(e, target) {
    console.log(typeof e.target.value)
    let val = e.target.value;
    if (target === 'dataInizio') lastDate = val
    setRichiestaData(prevState => ({
      ...prevState,
      [target]: val
    }))
  }
  const [currentStato, setCurrentStato] = React.useState('')
  function handlePriorita(e, target) {
    let val = e.target.value;
    setCurrentStato(target)
    let priorita = richiestaData.priorita
    priorita.attivo = false
    priorita.ferie = false
    priorita.permessi = false
    priorita.malattia = false
    priorita[target] = true
    setRichiestaData(prevState => ({
      ...prevState,
      'priorita': priorita
    }))
  }
  const [users, setUsers] = React.useState([])
  function handleInputChange(event, value) {
    if (value !== undefined) {
      let cValue = userList.filter(data => (data.Nome + ' ' + data.Cognome) === value)
      if (cValue.length > 0) {
        cValue = cValue[0]
        setRichiestaData(prevState => ({
          ...prevState,
          'ragSociale': cValue.id
        }))
        let tmp = users
        tmp.push(cValue)
        setUsers(tmp)
        console.log(users)
      }
    }
  }
  const [disabledBtn, setDisabledBtn] = React.useState(false)
  function handleTipologiaChange(event, value) {
    if (value !== null && value !== undefined) {
      let tmp = value.split('|')[0].split('.')
      console.log('DDDDDDDDD', value)
      setRichiestaData(prevState => ({
        ...prevState,
        'tipologia': tmp[1]
      }))
    }
  }
  const [usedMezzi, setUsedMezzi] = React.useState([])
  function handleMezziChange(value) {
    if (value !== null && value !== undefined) {
      let tmp = value.map(obj => obj.id)
      setUsedMezzi(tmp)
    }

  }

  //Display only available interventi
  const [toShow, setToShow] = React.useState([])
  const [personName, setPersonName] = React.useState([]);
  const [openMezzi, setOpenMezzi] = React.useState(false)
  const [mezzoTarga, setMezzoTarga] = React.useState('')
  const [mezzoType, setMezzoType] = React.useState('1')
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  const prova = <img src={require('../../../assets/images/vehicleOne.png')} />
  const delay = ms => new Promise(res => setTimeout(res, ms));
  return (
    <>

      <Dialog open={flag} fullWidth={true} maxWidth={'lg'} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><Typography variant='h5'>Aggiungi Attività/Ferie/Permessi/Malattia</Typography></DialogTitle>
        <DialogContent>
          {flag === true ? (
            <Box className={classes.mainTab}>
              <Dialog open={openMezzi} onClose={() => setOpenMezzi(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Aggiungi mezzo</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Inserisci la targa del nuovo mezzo e la tipologia dello stesso.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    value={mezzoTarga}
                    onChange={(e) => {
                      setMezzoTarga(e.target.value)
                    }}
                    margin="dense"
                    id="targa"
                    label="Targa veicolo"
                    fullWidth
                  />
                  <RadioGroup aria-label="veicolo" value={mezzoType} name="veicolo" onChange={(e) => {
                    setMezzoType(e.target.value)
                  }}>
                    <FormControlLabel value="2" control={<Radio />} label="Macchina" />
                    <FormControlLabel value="1" control={<Radio />} label="Furgone" />
                  </RadioGroup>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenMezzi(false)} color="primary">
                    Annulla
                  </Button>
                  <Button onClick={async () => {
                    let toUp = {
                      token: 'dd',
                      targa: mezzoTarga,
                      type: mezzoType
                    }
                    createMezzo(toUp)
                    await delay(750)
                    getMezziList('dd')
                    await delay(500)
                    setMezzi(getMezziListData())
                    setMezziFiltered(getMezziListData())
                    setOpenMezzi(false)

                  }} color="primary">
                    Aggiungi
                  </Button>
                </DialogActions>
              </Dialog>
              <Box className={classes.mainRow}>
                <Box className={classes.mainColumn} style={{ width: '45%' }}>
                  <Typography variant='subtitle1' className={classes.columnLabel} >* Cerca Intervento</Typography>
                  <Autocomplete
                    id="combo-bbox-demo"
                    options={toShow}
                    getOptionLabel={(option) => (option.tipologia === 'manutenzione' || option.tipologia === 'ordinaria' || option.tipologia === 'straordinaria' ? option.tipologia !== 'manutenzione' ? 'INT.' : 'MAN.' : 'C.') + option.id + '| ' + option.descrizione}
                    onInputChange={handleTipologiaChange}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField style={{ color: '#00ADA2' }}{...params} label="Interventi" variant="outlined" />}
                  />
                </Box>


              </Box>

              <Box className={classes.mainRow}>
                <Box className={classes.mainColumn} style={{ width: '25%' }}>
                  <Typography variant='subtitle1' className={classes.columnLabel} style={{ marginBottom: 0 }} > Attivo/ Non Attivo</Typography>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      className={classes.controlLabel}
                      control={
                        <Checkbox
                          checked={richiestaData.priorita.attivo}
                          onChange={e => handlePriorita(e, 'attivo')}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Attivo"
                    />
                    <FormControlLabel
                      className={classes.controlLabel}
                      control={
                        <Checkbox
                          checked={richiestaData.priorita.ferie}
                          onChange={e => handlePriorita(e, 'ferie')}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Ferie"
                    />
                    <FormControlLabel
                      className={classes.controlLabel}
                      control={
                        <Checkbox
                          checked={richiestaData.priorita.permessi}
                          onChange={e => handlePriorita(e, 'permessi')}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Permessi"
                    />
                    <FormControlLabel
                      className={classes.controlLabel}
                      control={
                        <Checkbox
                          checked={richiestaData.priorita.malattia}
                          onChange={e => handlePriorita(e, 'malattia')}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Malattia"
                    />
                  </Box>
                </Box>
                <Box className={classes.mainColumn} style={{ width: '20%' }}>
                  <Typography variant='subtitle1' className={classes.columnLabel} >* Ore di Lavoro</Typography>
                  <TextField
                    className={classes.defInput}
                    disabled={richiestaData.priorita.ferie === true || richiestaData.priorita.malattia === true ? true : false}
                    value={richiestaData.tempo}
                    error={error == 'tempo' ? true : false}
                    helperText={error == 'tempo' ? "Ore di lavoro obbligatorie " : "Definisci il tempo massimo di intervento"}
                    onChange={e => handleChangeRichiesta(e, 'tempo')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className={classes.stdImage} src={require('../../../assets/images/manutenzioneContratto/tempoIntervento.png')} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {console.log(error)}
                </Box>
                <Box className={classes.mainColumn}>
                  <Typography variant='subtitle1' className={classes.columnLabel} >* Inizio Intervento</Typography>
                  <TextField
                    value={richiestaData.dataInizio !== '' ? richiestaData.dataInizio : date}
                    onChange={async e => {
                      var tmpVal = e
                      handleChangeRichiesta(e, 'dataInizio')
                      function getUniqueListBy(arr, key) {
                        return [...new Map(arr.map(item => [item[key], item])).values()]
                      }

                      if (richiestaData.dataFine === null) {
                        handleChangeRichiesta(tmpVal, 'dataFine')
                      }
                      await getMezziAssegnati({
                        token: getUserData().Token,
                        dateNow: e.target.value
                      }).then(res => {
                        let tmpFinal = JSON.parse(JSON.stringify(mezziList))
                        try {
                          res.map(currentActiveMezzo => {
                            let finder = tmpFinal.find(obj => obj.id === currentActiveMezzo.idMezzo)

                            if (finder && currentActiveMezzo.date == lastDate) {
                              tmpFinal.filter(obj => obj.id !== finder.id)
                              finder.idIntervento = currentActiveMezzo.idIntervento
                              finder.tipologia = getInterventoListData().find(obj => obj.id == currentActiveMezzo.idIntervento).tipologia
                              tmpFinal.push(finder)
                            }
                          })
                          console.log(getUniqueListBy(tmpFinal, 'id'))
                          setMezziFiltered(getUniqueListBy(tmpFinal, 'id'))
                        } catch (e) {

                        }

                      })
                    }}
                    type="date"
                    error={error == 'dataInizio' ? true : false}
                    helperText={"Definisci la data di inizio intervento"}
                    className={classes.defInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box className={classes.mainColumn}>
                  <Typography variant='subtitle1' className={classes.columnLabel} >Fine Intervento</Typography>
                  <TextField
                    value={richiestaData.dataFine}
                    onChange={e => handleChangeRichiesta(e, 'dataFine')}
                    type="date"
                    error={error == 'dataFine' ? true : false}
                    helperText={"Definisci la data di fine intervento"}

                    className={classes.defInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

              </Box>
              <Box className={classes.mainRow}>
                <Box className={classes.mainColumn} style={{ width: '40%' }}>
                  <Typography variant='subtitle1' className={classes.columnLabel} style={{ marginBottom: 0 }} >* Assegna Intervento</Typography>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      style={{ width: '100%' }}
                      value={personName.length > 0 ? personName : [user.id]}
                      onChange={handleChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip key={value} label={userList.filter(user => user.id == value)[0].Nome + ' ' + userList.filter(user => user.id == value)[0].Cognome} className={classes.chip} />
                          ))}
                        </div>
                      )}
                    >
                      {userList.filter(user => user.Tipologia === 'Utente').map((name) => (
                        <MenuItem key={name.Nome + ' ' + name.Cognome} value={name.id}>
                          {name.Nome + ' ' + name.Cognome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.mainColumn} style={{ width: '45%', marginTop: -25 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                    <Typography variant='subtitle1' className={classes.columnLabel} style={{ marginBottom: 0 }}>* Seleziona Mezzo</Typography>
                    <IconButton color="default" aria-label="aggiunta mezzo" component="span" onClick={() => {
                      setOpenMezzi(true)
                    }}>
                      <AddBoxIcon />
                    </IconButton>
                  </div>

                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    getOptionDisabled={option => option.idIntervento !== undefined}
                    options={mezziFiltered !== undefined ? mezziFiltered : null}
                    //getOptionDisabled={(option) => option.assigned !== null}   + ( option.assigned > 0 ? ' - Assegnato: M' + option.assigned : '')
                    getOptionLabel={(option) => option.targa}
                    renderOption={(option) => (
                      <Fragment>
                        {option.targa}
                        {option.type == 2 ? (
                          <img src={require('../../../assets/images/vehicleOne.png')} style={{ height: 24, marginLeft: 20 }} />
                        ) : (
                          <img src={require('../../../assets/images/vehicleTwo.png')} style={{ height: 24, marginLeft: 20 }} />
                        )}
                        {option.idIntervento ? (
                          <Typography style={{ color: '#25b4a7', marginLeft: 30 }}>{option.tipologia !== 'manutenzione' ? 'INT.' : 'MAN.'} {option.idIntervento}</Typography>
                        ) : null}

                      </Fragment>
                    )}
                    onInputChange={handleMezziChange}
                    onChange={(event, newValue) => handleMezziChange(newValue)}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField style={{ color: '#00ADA2' }}{...params} label="Mezzi" variant="outlined" />}
                  />
                </Box>
              </Box>

              <Box className={classes.mainRow}>
                <Box className={classes.mainColumn} style={{ width: '100%' }}>
                  <Typography variant='subtitle1' className={classes.columnLabel} style={{ marginBottom: 0 }} >Descrizione</Typography>
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-multiline-static"
                    value={richiestaData.descrizione}
                    onChange={e => handleChangeRichiesta(e, 'descrizione')}
                    multiline
                    rows={6}
                    variant="outlined"
                  />
                </Box>
              </Box>

              <Box style={{ display: 'flex', justifyContent: 'center', padding: '10px 100px 0' }}>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={false}
                  disabled={disabledBtn}
                  className={classes.button}
                  style={{ justifyContent: 'center' }}
                  onClick={async e => {
                    e.preventDefault()
                    if (richiestaData.tempo !== null || currentStato != 'Attivo' || currentStato != 'attivo') {
                      if (richiestaData.dataInizio !== null) {
                        if (richiestaData.dataFine !== null) {
                          setDisabledBtn(true)
                          var VARIANTE = ''
                          if (richiestaData.tipologia) {
                            let target = toShow.find(obj => obj.id == richiestaData.tipologia)
                            if(target){
                              VARIANTE = target.tipologia
                            }
                          }
                          if (personName.length > 0) {
                            let users = []
                            personName.map(async utente => {
                              users.push(utente)
                            })
                            if (currentStato == 'Attivo' || currentStato == 'attivo') {
                              usedMezzi.map(async currentMezzo => {
                                let toSend = {
                                  Token: getUserData().Token,
                                  idMezzo: currentMezzo,
                                  idIntervento: richiestaData.tipologia,
                                  dateNow: (richiestaData.dataInizio == '' ? date : richiestaData.dataInizio)
                                }
                                await insertMezzoAssegnato(toSend).then(res => {
                                  console.log(res)
                                })
                              })
                              async function sendData() {
                                let contrID = (getInterventoListData().filter(ee => ee.id == richiestaData.tipologia)[0].byContratto)
                                await createPrograma(getUserData().Token,
                                  richiestaData.tipologia,
                                  currentStato,
                                  richiestaData.tempo,
                                  (richiestaData.dataInizio == '' ? date : richiestaData.dataInizio),
                                  richiestaData.dataFine,
                                  users.join(','),
                                  richiestaData.descrizione,
                                  contrID,
                                  date,
                                  usedMezzi.join(','),
                                  VARIANTE)

                                await delay(500)
                                await delay(500)

                                window.location.reload(false)
                              }
                              sendData()
                            } else {
                              async function sendPer() {
                                await createPrograma(getUserData().Token,
                                  0,
                                  currentStato,
                                  richiestaData.tempo ? richiestaData.tempo : 0,
                                  richiestaData.dataInizio,
                                  richiestaData.dataFine,
                                  users.join(','),
                                  richiestaData.descrizione,
                                  0,
                                  date,
                                  usedMezzi.join(','),
                                  VARIANTE)
                                await delay(500)
                              }
                              sendPer()
                              await delay(500)

                              window.location.reload(false)

                            }
                            setUsers([])
                          } else {
                            if (currentStato == 'Attivo' || currentStato == 'attivo') {
                              usedMezzi.map(async currentMezzo => {
                                let toSend = {
                                  Token: getUserData().Token,
                                  idMezzo: currentMezzo,
                                  idIntervento: richiestaData.tipologia,
                                  dateNow: (richiestaData.dataInizio == '' ? date : richiestaData.dataInizio)
                                }
                                await insertMezzoAssegnato(toSend).then(res => {
                                  console.log(res)
                                })
                              })
                              async function sendData() {
                                let contrID = (getInterventoListData().filter(ee => ee.id == richiestaData.tipologia)[0] ? getInterventoListData().filter(ee => ee.id == richiestaData.tipologia)[0].byContratto : 0)
                                await createPrograma(getUserData().Token,
                                  richiestaData.tipologia,
                                  currentStato,
                                  richiestaData.tempo,
                                  (richiestaData.dataInizio == '' ? date : richiestaData.dataInizio),
                                  richiestaData.dataFine,
                                  user.id,
                                  richiestaData.descrizione,
                                  contrID,
                                  date,
                                  usedMezzi.join(','),
                                  VARIANTE)

                                await delay(500)
                                console.log(usedMezzi)
                                await delay(500)

                                window.location.reload(false)
                              }
                              sendData()
                            } else {
                              async function sendPer() {
                                await createPrograma(
                                  getUserData().Token,
                                  0,
                                  currentStato,
                                  richiestaData.tempo ? richiestaData.tempo : 0,
                                  (richiestaData.dataInizio == '' ? date : richiestaData.dataInizio),
                                  richiestaData.dataFine,
                                  user.id,
                                  richiestaData.descrizione,
                                  0,
                                  date,
                                  usedMezzi.join(','),
                                  VARIANTE)
                                await delay(500)
                              }
                              sendPer()
                              await delay(500)
                              window.location.reload(false)
                            }
                            setUsers([])
                          }
                        } else {
                          setError('dataFine')
                        }
                      } else {
                        setError('dataInizio')
                      }
                    } else {
                      setError('tempo')
                    }



                  }}
                  endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                >
                  Salva e Chiudi
                </Button>
              </Box>
            </Box>

          ) : null}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default AggiungiAttivita
