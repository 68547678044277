import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import webservice from '../api/webservice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        '&:hover':{
            backgroundColor: 'transparent !important'
        }
    },
    stdImage: {
        height: 20
      },
}))

export default ({
    visible,
    data,
    toggleModal
}) => {
    const { removeProgramma, updatePartProgramma } = webservice()
    const [formDetails,setFormDetails] = React.useState({
        initDate: '',
        endDate:'',
        time:0,
        changeValue: (val,target) => setFormDetails(prevState => ({...prevState,[target]:val}))
    })
    const [error,setError] = React.useState(undefined)
    const classes = useStyles()

    React.useEffect(() => {
        if(data){
            formDetails.changeValue(data.dataInizio,'initDate')
            formDetails.changeValue(data.dataFine,'endDate')
            if(data.stato === 'permessi'){
                formDetails.changeValue(data.tempi,'time')
            }
        }
    }, [visible])

    const submitEdit = () => {
        if(formDetails.initDate && formDetails.endDate && (data.stato === 'permessi' ? (formDetails.time && parseInt(formDetails.time) > 0 && parseInt(formDetails.time) <= 8) : true)){
            if(moment(formDetails.initDate,'YYYY-MM-DD') <= moment(formDetails.endDate,'YYYY-MM-DD')){
                setError(undefined)
                updatePartProgramma({
                    dataInizio: formDetails.initDate,
                    dataFine: formDetails.endDate,
                    tempi: formDetails.time,
                    id:data.id
                }).then((res) => {
                    console.log(res)
                    toggleModal(false)
                    window.location.reload(false)
                })
            } else {
                setError('Le date sono impostate in modo errato')
            }
        }else {
            setError('Verifica di avere compilato i campi correttamente')
        }
    }

    const submitDelete = () => {
        if( window.confirm('Confermi di voler eliminare l\'attivtà') ) {
            removeProgramma({id: data.id})
            setTimeout(() => {
                toggleModal(false)
                window.location.reload(false)
            }, 500)
        }
    }

  return (
    <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => toggleModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Dettagli su ${data && data.stato}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            
          </DialogContentText>
          <div style={{display:'flex',flexDirection:'column',width:500,overflowY:'auto',height:300}}>
          <TextField
            value={formDetails.initDate}
            onChange={(event) => formDetails.changeValue(event.target.value,'initDate')}
            type="date"
            style={{marginBottom:16}}
            helperText={"Definisci la data di inizio"}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={formDetails.endDate}
            onChange={(event) => formDetails.changeValue(event.target.value,'endDate')}
            type="date"
            style={{marginBottom:16}}
            helperText={"Definisci la data di termine"}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {data && data.stato === 'permessi' && (
              <TextField
              type="number"
              value={formDetails.time}
              helperText={'Ore di permesso'}
              onChange={(event) => formDetails.changeValue(event.target.value,'time')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img className={classes.stdImage} src={require('../assets/images/manutenzioneContratto/tempoIntervento.png')} />
                  </InputAdornment>
                )
              }}
            />
          )}
          </div>
        </DialogContent>
        {error && (
            <Typography style={{textAlign:'center',color:'red'}}>{error}</Typography>
        )}
        <DialogActions>
          <Button onClick={() => submitEdit()} variant="outlined" className={classes.buttonStyle} >Modifica</Button>
          <Button onClick={() => submitDelete()} variant="outlined" color="error" className={classes.buttonStyle}>Elimina</Button>
        </DialogActions>
      </Dialog>
  );
}