import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Sidebar from "../components/Sidebar"
import { Box, Button, Container, Divider, InputLabel, TextField, Typography, Select as SelectNV } from "@material-ui/core"
import MenuItem from '@material-ui/core/MenuItem';
import Activity from '../components/activity'
import contractData from '../data/contractData';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import Select from 'react-select'

import MenuIcon from '@material-ui/icons/Menu';
import userData from '../data/userData';
import webservice from '../../api/webservice';
import anagraficaData from '../data/anagraficaData';
import WrapperComponent from '../components/WrapperComponent';

const useStyles = makeStyles((theme) => ({
    toolBar: {
        height: 'auto',
        backgroundColor: 'white',
        padding: '10px 15px 10px 50px',
        display: 'flex',
        marginBottom:16,
        justifyContent: 'space-between'
      },
  Header:{
    backgroundColor: "#00ADA2",
    height: 50,
    paddingTop:12
  },
  listaTable:{
    height:'auto',
    width:'98%',
    paddingBottom:30,
    backgroundColor:'white',
  },
  tabName:{
    padding:15,
    paddingTop:30,
    fontWeight:'bold',
    fontSize:25, 
    letterSpacing:'.05em',
    color:'#6C6C6C'
  },
  topTab:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      paddingBottom:30
  },
  defaultSelectBox:{
      width:'100%'
  },
  subTabName:{
    paddingLeft:15,
    color:'#535353',
    fontSize:20,
    fontWeight:'bold',
    letterSpacing:'0.05em',
    textTransform:'capitalize',
    textAlign:'left'
   },
   topButton:{
       background:'#18CCBC !important',
       color:'white',
       paddingLeft:25,
       paddingRight:25
   },
   mainTab:{
    paddingTop:50,
    display: "flex",
    justifyContent:'column',
    flexWrap: "wrap",
        "& > *": {
        margin: theme.spacing(1),
        height: theme.spacing(16)
        }
    },
    button:{
        backgroundColor:'#00ADA2',
        textDecoration:'capitalize',
        width:'15%'
    },
    buttonBack:{
          width:'15%',
          background:'transparent',
          color:'black',
          '&:hover': {
            backgroundColor: '#f1e9e9 !important',
        }
    },
    errorBasic: {
        color:'red',
        paddingLeft:15
      }
    

}))

export default() => { 
    const [cType,setCType] = React.useState([])
    const { setDrawer } = userData()
    function useForceUpdate(){
        const [value, setValue] = React.useState(0); // integer state
        return () => setValue(value => ++value); // update the state to force render
      }
      const forceUpdate = useForceUpdate();
  const history = useHistory();
  //Setting values in sessionStorage
  const { getTipologieIntervento, getTabTipoImpianti } = webservice()
  const { setContractActivity, getContractActivity, updateContractActivity, getContractType, getNewContractData,getTabImpianti, getNewContractType } = contractData();
  const [toRender,setToRender] = useState([])
  const { getTipoI } = anagraficaData()
  //STATE Data Attivita
  const [data,setData] = useState({
      tipologia:null,
      descrizione:null,
      periodo:[],
      id: null
  })

  //GENERAZIONE CODICE personale Attività
  function makeid() {
    var result = '';
    var characters  = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 5; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
    }

  //Error manage
  const [error,setError] = useState(0)

  
  //Function to change state data
  function handleChange(e,target){
      let value = e.target.value
      setData(prevState => ({
          ...prevState,
          [target]:value
      }))
  }
  //Reintegrating values if already compiled
  useEffect(() => {
      
      getTabTipoImpianti('dd')
      getTipologieIntervento('dd')
      if(getContractActivity() !== null){
          let data = getContractActivity()
          setToRender(data)
      }
      let fin = []
      if(getNewContractData() !== null && getTipoI() !== undefined && getTipoI() !== null){
        let tt = []
        let ttTemp = getNewContractType()
        getTabImpianti().map(obj => {
            ttTemp.map(obj2 =>{
                if(obj.Descr == obj2){
                    tt.push(obj)
                }
            })
        })
        if(tt !== undefined){
            getTipoI().map( type => {
                tt.map(obj2 =>{
                    if(type.bytipologia == obj2.id){
                        fin.push(type)
                    }
                    
                })
                setCType(fin)
            } )
        }
        
      }
  }, [])
  console.log(getContractActivity())
  //Deleting values
  function removeActivity(code){
    let dataFresh = toRender.filter( item => item.id !== code )
    updateContractActivity(dataFresh)
    setToRender( getContractActivity() )
}



//New month system
const [monthToLoad,setMonthToLoad] = React.useState([])

function monthDiff(startDate, endDate) {
    var start      = startDate.split('-');
    var end        = endDate.split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];

    for(var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1])-1 : 0;
        for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? '0'+month : month;
        dates.push([i, displayMonth, '01'].join('-'));
        }
    }
    return dates;
}
function printSelectMonths(dates){

    function printName(num){
        switch(num){
            case '01':
                return 'Gennaio';
            case '02':
                return 'Febbraio';
            case '03':
                return 'Marzo';
            case '04':
                return 'Aprile';
            case '05':
                return 'Maggio';
            case '06':
                return 'Giugno';
            case '07':
                return 'Luglio';
            case '08':
                return 'Agosto';
            case '09':
                return 'Settembre';
            case '10':
                return 'Ottobre';
            case '11':
                return 'Novembre';
            case '12':
                return 'Dicembre';
        }
    }
    let fin = []

    dates.map( item => {
        let tmp = item.split('-')
        let tmpToUp = {
            value:item,
            label:printName(tmp[1])
        }
        fin.push(tmpToUp)
        //<MenuItem value={item}>{printName(tmp[1]) + ' - ' + tmp[0]}</MenuItem>
    })

    return (
        <Select
        menuPortalTarget={document.body} 
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            value={monthToLoad}
            isMulti
            options={fin.slice(0,12)}
            onChange={ item => {
                setMonthToLoad(item)
                data.periodo.push(item)
            } }
            />
        
    )
}
  const classes = useStyles();

  const WrapperConfig = {
    title: 'Contratti',
    subTitle:'Da qui puoi gestire i tuoi contratti di manutenzione, approvarare le attività,\n gestire il programma User, gestire l\'anagrafica impianto',
    activeMenu:3,
    menu:[
      {
        label:'Dati del contratto',
      },
      {
        label:'Acquisizione'
      },
      {
        label:'Lista attività',
        active:true
      },
      {
        label:'Fatturazione'
      },
      {
        label:'Referenti Cliente'
      },
      {
        label:'Documenti'
      },
      {
        label:'Conferma e Aggiungi'
      }
    ],
    color:'#3ED1B8',
    darkColor:'#31A99D',
    options: [
        {
            label:'Lista contratti',
            onPress: () => history.push('/contratti/listaContratti'),
            enabled: true,
            active:false
          },
          {
            label:'Nuovo contratto',
            onPress: () => null,
            enabled: true,
            active:true
          },
          {
            label:'Programma',
            onPress: () => history.push('/programma'),
            enabled: true,
            active:false //PEX
          },
          {
            label:'Attività',
            onPress: () => history.push('/attivita'),
            enabled: true,
            active:false //PEX
          }
    ],
    heading:<Container maxWidth="False" className={classes.toolBar}>
    <Box style={{ display: 'flex' }}>
      <img style={{ width: 40, marginRight: 30 }} src={require('../../assets/images/lista-utenti/CONTRATTO.png')} />
      <Typography variant='h6' component='h2' style={{ color: '#6A6A6A', margin: 'auto 0' }}>LISTA CONTRATTI</Typography>
    </Box></Container>
  }

  return (
      <WrapperComponent {...WrapperConfig}>
        <Container maxWidth="False" className={classes.listaTable}>
            <Typography className={classes.tabName}>LISTA ATTIVITA'</Typography>
            {error === 1 ? <Typography className={classes.errorBasic}>* Compila tutti i campi</Typography> : null}
            <Box className={classes.topTab}>
                <Typography className={classes.subTabName}>Nuova Attività</Typography>
                <Box style={{width:'15%'}}>
                    <InputLabel htmlFor="selectTipologia">* Tipologia Impianto</InputLabel>
                    <SelectNV
                        className={classes.defaultSelectBox}
                        labelId="selectTipologia"
                        id="selectTipologia"
                        value={data.tipologia}
                        onChange={(e) => handleChange(e,'tipologia')}
                        >
                        {cType.map(type => {
                            return (
                                <MenuItem value={type.Nome}>{type.Nome}</MenuItem>
                            )
                        })}
                    </SelectNV>
                </Box>
                <Box style={{width:'30%'}}>
                    <TextField
                        id="outline-multiline-static"
                        label="Descrizione dell'attività"
                        multiline
                        className={classes.defaultSelectBox}
                        rowsMax={8}
                        height={64}
                        rows={1}
                        value={data.descrizione}
                        placeholder="Inserisci la descrizione compelta dell'attività da svolgere"
                        onChange={e => handleChange(e,'descrizione')}
                    />
                </Box>
                <Box style={{width:'15%'}}>
                    <InputLabel htmlFor="selectTipologia">* Periodo</InputLabel>
                    { printSelectMonths(monthDiff(getNewContractData()[0].datainizio.split('T')[0],getNewContractData()[0].datafine.split('T')[0])) }
                </Box>

                {/* Old was data.periodo, handleChange(e,'periodo') */}
                <Button 
                    variant="contained" 
                    className={classes.topButton}
                    onClick={ () => {
                        if(data.tipologia && monthToLoad.length > 0 && data.descrizione){
                            let toUpdate = data
                            toUpdate.id = makeid()
                            toUpdate.periodo = monthToLoad
                            setContractActivity( toUpdate )
                            setToRender(getContractActivity())
                            setError(0);
                            setMonthToLoad([])
                            setData({
                                tipologia:null,
                                descrizione:'',
                                periodo:[],
                                id: null
                            })
                        }else{
                            setError(1);
                        }
                    } }
                >
                    <span style={{textTransform:'capitalize'}}>Aggiungi</span>
                </Button>
            </Box>
            <Divider></Divider>
            <Box className={classes.mainTab}>
                {toRender !== null ?
                    toRender.map( (item, index) => {
                        return <Activity data={item} index={index} tipo={cType} toDelete={(e) => removeActivity(e)} updateAct={ e => {
                            let toUp = toRender.filter( cc => cc.id !== e.id )
                            toUp.push(e)
                            setToRender(toUp)
                            updateContractActivity(toUp)
                        } }/>
                    } ) : null
                }
                </Box>
                <Box style={{display:'flex',justifyContent:'space-between',padding:'50px 100px 0'}}>
                    <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => {
                        
                        if (getContractType() === 'sub') {
                            history.push('/contratti/nuovoContratto')
                        }else{
                            history.push('/contratti/acquisizioneContratto')
                        }
                    }}
                    className={classes.buttonBack}
                    id='buttonBack'
                    style={{justifyContent:'flex-start'}}
                    startIcon={<ArrowBackIcon></ArrowBackIcon>}
                    >
                    Indietro
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    className={classes.button}
                    onClick={() => {
                        if (getContractType() === 'sub') {
                            history.push('/contratti/documenti')
                        }else{
                            history.push('/contratti/Fatturazione')
                        }
                        
                    }}
                    style={{justifyContent:'flex-end'}}
                    endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                    >
                    Salva e Continua
                    </Button>
                </Box>
        </Container>

      </WrapperComponent>
  )
}
