import React from 'react'
import { Preview, print, A4Page } from 'react-html2pdf';
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import webservice from '../../api/webservice'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Typography from '@material-ui/core/Typography';
import { Backdrop, CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
}));


function DownloadRep({progToShow,nCliente,desc,int,client,anom,mate,manu,open,handleClose,signatureCliente,signatureTecnico}) {
    console.log("DESc", desc)
    console.log("MATERIALI", mate)
    console.log("MANODOPERE", manu)
    console.log("ANOMALIE", anom)
    const { userList,getUserList } = webservice()
    const classes = useStyles()
    const delay = ms => new Promise(res => setTimeout(res, ms));
    function normalizeDates(date){
        let tmp = date.split('-')
        return ( tmp[2] + '-' + tmp[1] + '-' + tmp[0])
      }
    console.log('FFFFF',progToShow)

    function tagSwitcher(tmp){
        if(tmp !== null && tmp !== undefined){
            if(tmp.tipologia === 'manutenzione'){
                return 'MANUTENZIONE ORDINARIA'
            }else{
                if(tmp.tipologia === 'ordinaria'){
                    return 'INTERVENTO ORDINARIO'
                }else{
                    return 'INTERVENTO STRAORDINARIO'
                }
            }
        }
    } //
    function tagSwitcherInternal(tmp){
        if(tmp !== null && tmp !== undefined){
            if(tmp.tipologia === 'manutenzione'){
                return 'M'
            }else{
                if(tmp.tipologia === 'ordinaria'){
                    return 'I'
                }else{
                    return 'I'
                }
            }
        }
    }
    
    let name = int.id !== undefined ? int.id : 'ERRN'

    /*function printDocument(){
        const input = document.getElementById('divToOfferInfo');
        const pdf = new jsPDF();
        if (pdf) {
          html2canvas(input, {
            useCORS: true
          })
            .then(canvas => {
              const imgData = canvas.toDataURL('image/png');
              console.log(imgData); //Maybe blank, maybe full image, maybe half of image
              pdf.addImage(imgData, 'PNG', 10, 10);
              pdf.save('download.pdf');
            });
        }
    } */
    const [loader,setLoader] = React.useState(false)
    return (
        <>
        <Backdrop className={classes.backdrop} style={{zIndex:9999}} open={loader}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog onClose={handleClose} maxWidth={'lg'}aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          REPORT
        </DialogTitle>
        <DialogContent dividers>
            <div id="containerPDF">
        {desc !== undefined && desc !== null && desc.length > 0 ? (
            desc.map((currentDesc,index) => {return(
                <div id={`${index}div`} style={{paddingLeft:'2%',paddingRight:'2%',textAlign:'center'}}>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <div>
                            <p style={{fontSize:13,fontWeight:'bold',textAlign:'right'}}>REPORT INTERVENTO</p>
                            <p style={{marginTop:-20,fontSize:14,color:'#06F',fontWeight:'bold',textAlign:'right'}}>{tagSwitcher(int)}</p>
                        </div>
                    </div>

                    <div style={{marginTop:-15,border:'1px solid #898989',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <div style={{width:'49.5%',borderRight:'1px solid #898989',textAlign:'left',paddingLeft:10}}>
                            <p style={{color:'#F93',fontWeight:'bold',fontSize:'.45vw',marginBottom:10}}>CLIENTE</p>
                            <div style={{lineHeight:.5}}>
                                <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw'}} >{client.rSociale}</p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.25vw',textTransform:'capitalize'}}>{client.indirizzo + ', ' + client.cap + ' - ' + client.localita}</p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.27vw'}}>C.F {client.cf} </p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.27vw'}}>P.IVA: {client.pIVA}</p>
                            </div>
                        </div>

                        <div style={{width:'49.5%',textAlign:'right',paddingRight:10}}>
                        <p style={{color:'#06F',fontWeight:'bold',fontSize:'.45vw',marginBottom:10}}>DITTA</p>
                            <div style={{lineHeight:.5}}>
                                <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw'}} >ITALPROIM SRL</p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.25vw',marginBottom:5}}>VIA SALVATORE BARZILAI 219 - 00173 </p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.27vw'}}>P.I 01488891001 – C.F 06078350581 </p>
                                <p style={{color:'#898989',fontWeight:'bold',fontSize:'.27vw'}}>Telefono: 06.7231367/72632099</p>
                            </div>
                        </div>
                    </div>

                    <div style={{borderLeft:'1px solid #898989',borderRight:'1px solid #898989',display:'flex',justifyContent:'space-between'}}>
                        <div style={{borderRight:'1px solid #898989',borderBottom:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>DITTA</p>
                            <p style={{color:'#06F',fontWeight:'normal',fontSize:15}}>{tagSwitcherInternal(int)}.{int.id} / {int.created !== undefined  ? (int.created.split('-')[0]) : null}</p>
                        </div>
                        <div style={{borderRight:'1px solid #898989',borderBottom:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>Cliente</p>
                            <p style={{color:'#333',fontWeight:'normal',fontSize:'.35vw'}}>{client.rSociale}</p>
                        </div>
                        <div style={{borderRight:'1px solid #898989',borderBottom:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>User</p>
                            <p style={{color:'#333',fontWeight:'normal',fontSize:'.35vw'}}>{progToShow !== undefined && progToShow.usersInternal !== undefined ? (
                                progToShow.usersInternal.map(obj => {return obj})
                            ) : null}</p>
                        </div>
                    </div>
                    <div style={{borderLeft:'1px solid #898989',borderRight:'1px solid #898989',borderBottom:'1px solid #898989',display:'flex',justifyContent:'space-between'}}>
                        <div style={{borderRight:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>Dove</p>
                            <p style={{color:'#333',fontWeight:'normal',fontSize:'.35vw',textTransform:'capitalize'}}>{int.indirizzo}</p>
                        </div>
                        <div style={{borderRight:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>Tipologia</p>
                            <p style={{color:'#333',fontWeight:'normal',fontSize:'.35vw',textTransform:'capitalize'}}>{int.tipologia}</p>
                        </div>
                        <div style={{borderRight:'1px solid #898989',textAlign:'center',width:'33%'}}>
                            <p style={{color:'#333',fontWeight:'bold',fontSize:'.35vw',marginBottom:2}}>Fine Intervento</p>
                            <p style={{color:'#333',fontWeight:'normal',fontSize:'.35vw'}}>{progToShow.dataFine !== undefined ? ( normalizeDates(progToShow.dataFine.split('T')[0]) ) : null}</p>
                        </div>
                    </div>


                    <div style={{marginTop:-10,borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        <p style={{fontSize:10,fontWeight:'bold'}}>Descrizione Generale delle Attività ed eventuali Anomalie</p>
                    </div>
                    <div style={{marginTop:-5,borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15,paddingRight:15}}>
                        <p style={{fontSize:'.35vw',fontWeight:'normal',color:'#767171'}}>{currentDesc.desc}</p>
                    </div>


                    <div style={{marginTop:-10,borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        <p style={{fontSize:10,fontWeight:'bold'}}>Asset con Anomalie</p>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between',borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        {anom !== undefined ? anom.map( an => {
                            if(an.anom.byTicket == currentDesc.idRep){
                                return (
                                    <table style={{width:'49.5%',textAlign:'center'}}>
                                <tr>
                                    <th></th>
                                    <th style={{fontSize:9}}>Codice</th>
                                    <th style={{fontSize:9}}>Anomalia Riscontrata</th>
                                </tr>
                                <tr>
                                    <tr><img style={{height:22}}src={require('../../assets/images/anagraficaContratto/asset/codiceAsset.png')} /></tr>
                                    <td style={{fontSize:'.35vw',color:'767171'}}>{an.anom.Codice}</td>
                                    <td style={{fontSize:'.35vw',color:'767171'}}>{an.anom.Descrizione}</td>
                                </tr>
                            </table>
                                )
                            }
                            
                        } ) : null}
                        

                    </div>

                    <div style={{marginTop:-10,borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        <p style={{fontSize:10,fontWeight:'bold'}}>Materiale Utilizzato</p>
                    </div>
                    <div style={{borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                     <table style={{width:'100%',textAlign:'center'}}>
                            <tr>
                                <th style={{fontSize:9}}>Codice</th>
                                <th style={{fontSize:9}}>Nome</th>
                                <th style={{fontSize:9}}>Descrizione</th>
                                <th style={{fontSize:9}}>Quantità</th>
                            </tr>
                            {mate !== undefined ? mate.map( ma => {
                                    if(ma.mate.byTicket == currentDesc.idRep){
                                        return <tr>
                                                <td style={{fontSize:'.35vw',color:'767171'}}>{ma.mate.Codice}</td>
                                                <td style={{fontSize:'.35vw',color:'767171'}}>{ma.mate.Nome}</td>
                                                <td style={{fontSize:'.35vw',color:'767171'}}>{ma.mate.Descrizione}</td>
                                                <td style={{fontSize:'.35vw',color:'767171'}}>{ma.mate.Quantita}</td>
                                            </tr>
                                    }
                    }) : null }
                        </table>
                    </div>

                    <div style={{marginTop:-10,borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        <p style={{fontSize:10,fontWeight:'bold'}}>Totale Ore Tecnici</p>
                    </div>
                    <div style={{borderLeft:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',paddingLeft:15}}>
                        <table style={{width:'100%',textAlign:'center'}}>
                            <tr>
                                <th style={{fontSize:9}}>Nome e Cognome</th>
                                <th style={{fontSize:9}}>Data Inizio</th>
                                <th style={{fontSize:9}}>Data Fine</th>
                                <th style={{fontSize:9}}>Ore Ordinarie</th>
                            </tr>
                            {progToShow !== undefined &&progToShow.usersInternal !== undefined ? (
                                progToShow.usersInternal.map(obj => {
                                    return <tr>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{obj}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{int.dataInizio !== undefined ? normalizeDates(int.dataInizio.split('T')[0]) : null}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{int.dataFine !== undefined ? normalizeDates(int.dataFine.split('T')[0]) : null}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{progToShow.tempi}</td>
                                    </tr>
                                })
                            ) : null}
                            {manu.map( man => {
                                if(man.byTicket == currentDesc.idRep){
                                    return <tr>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{man.Nome + ' ' + man.Cognome}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{int.dataInizio !== undefined ? normalizeDates(int.dataInizio.split('T')[0]) : null}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{int.dataFine !== undefined ? normalizeDates(int.dataFine.split('T')[0]) : null}</td>
                                        <td style={{fontSize:'.35vw',color:'767171'}}>{man.hOrd}</td>
                                    </tr>
                                }
                                
                            } )}
                            
                        </table>
                        
                    </div>
                    
                    <div style={{marginTop:1,textAlign:'center',width:'100%',lineHeight:'1'}}>
                        <p style={{fontSize:'.35vw',color:'#767171'}}>ITALPROIM SRL</p>
                        <p style={{fontSize:7,color:'#898989'}}>VIA SALVATORE BARZILAI 219 - 00173 ROMA (RM) P.I 01488891001 – C.F 06078350581</p>
                        <p style={{fontSize:7,color:'#898989'}}>Telefono: 06.7231367/72632099</p>

                    </div>
                    <div style={{marginTop:1,display:'flex',justifyContent:'space-between'}}>
                            <div style={{borderLeft:'1px solid #898989',borderTop:'1px solid #898989',borderBottom:'1px solid #898989',borderRight:'1px solid #898989',height:125,width:'50%',padding:0}}>
                                <p style={{fontSize:10,fontWeight:'bold',borderBottom:'1px solid #898989',marginBlockEnd:5,marginBlockStart:5,paddingBottom:5}}>Firma Tecnico Responsabile</p>
                                <p style={{padding:0,marginBlockEnd:5,fontSize:'.45vw',marginBlockStart:5,borderBottom:'1px solid #898989',paddingBottom:5}}>{
                                    manu!==undefined ? (
                                        manu[0] !== undefined ? (
                                            manu[0].Nome + ' ' + manu[0].Cognome
                                        ) : 'ERRORE INTERNO'
                                    ) : 'ERRORE INTERNO'
                                }</p>
                                <img style={{height:'50%'}} src={signatureTecnico !== undefined && signatureTecnico !== null ? signatureTecnico[index] : ''} />
                            </div>
                            <div style={{borderRight:'1px solid #898989',borderTop:'1px solid #898989',borderBottom:'1px solid #898989',height:125,width:'50%',paddingTop:0}}>
                                <p style={{fontSize:10,fontWeight:'bold',borderBottom:'1px solid #898989',marginBlockEnd:5,marginBlockStart:5,paddingBottom:5}}>Firma Cliente</p>
                                <p style={{padding:0,marginBlockEnd:5,fontSize:'.45vw',marginBlockStart:5,borderBottom:'1px solid #898989',paddingBottom:5}}>{nCliente[index]}</p>
                                <img style={{height:'50%'}} src={signatureCliente !== undefined && signatureCliente !== null ? signatureCliente[index] : ''} />
                                </div>
                    </div>

                </div>
                
            )})
        ) : null}
        </div>
            
        </DialogContent>
        <DialogActions>
          <Button onClick={async ()=>{
              setLoader(true)
              const input = document.getElementById('containerPDF');
              var complete = []
              
              await desc.map((c,index) => {
                const input = document.getElementById(`${index}div`);
                const arr = (
                    html2canvas((input), {
                        scale:5,
                        quality:5,
                        width:380,
                        height:1000
                    })
                    .then((canvas) => {
                      var imgData = canvas.toDataURL('image/png');
                      return {
                          imgData,
                          height:canvas.height,
                          width:canvas.width
                      }
                      
                    })
                ).then(res => {
                    complete.push(res)
                    if((index + 1) === desc.length){
                        returnPDF()
                    }
                })
            })

            function returnPDF(){
                console.log(complete)
                var doc = new jsPDF('p', 'mm', 'a4');
                complete.map((current, index) => {
                    var imgWidth = 210; 
                    var pageHeight = 298;  
                    var imgHeight = current.height * imgWidth / current.width;
                    var heightLeft = imgHeight;
                    var position = 0;

                    doc.addImage(current.imgData, 'PNG', 20, position, imgWidth, imgHeight,undefined,'SLOW');
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(current.imgData, 'PNG', 20, position, imgWidth, imgHeight,undefined,'SLOW');
                    heightLeft -= pageHeight;
                    }
                    if((index + 1) === complete.length){
                        doc.save('Report_' + int.id + '.pdf')
                        setLoader(false)
                    }
                    doc.addPage()
                })
            }
              
            ; 
          }}>
            Scarica PDF
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
}

export default DownloadRep

/*
var imgWidth = 210; 
var pageHeight = 295;  
var imgHeight = canvas.height * imgWidth / canvas.width;
var heightLeft = imgHeight;
var doc = new jsPDF('p', 'mm');
var position = 0;

doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
heightLeft -= pageHeight;

while (heightLeft >= 0) {
position = heightLeft - imgHeight;
doc.addPage();
doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
heightLeft -= pageHeight;
}
doc.save( 'Report.pdf');
*/