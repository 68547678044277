import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();

  var setAnagraficaData = (data) => {
    localStorage.setItem('anagraficaData', JSON.stringify(data));
    console.log(data)
  }
  var setAssetListData = (data) => {
    console.log('DATA', data)
    localStorage.setItem('assetList', JSON.stringify(data));
  }
  var setAssetGroupList = (data) => {
    localStorage.setItem('assetGroupList', JSON.stringify(data));
  }
  var setActivityListData = (data) => {
    if (data.length === 0) {
      localStorage.setItem('activityList', JSON.stringify(null));
    } else {
      localStorage.setItem('activityList', JSON.stringify(data));
    }
  }
  var setAnagraficaAssetGroupID = (data) => {
    localStorage.setItem('assetGroupID', data);
  }
  var setProgId = (data) => {
    localStorage.setItem('progID', data);
  }

  var setProgrammaListData = (data) => {
    console.log(JSON.stringify(data))
    localStorage.setItem('programmaListData', JSON.stringify(data));
  }
  var setInterventoListData = (data) => {
    console.log(JSON.stringify(data))
    if (data !== null) {
      localStorage.setItem('interventoListData', JSON.stringify(data));
    }
  }
  var setSingleAsset = (data) => {
    console.log('DDDD', data)
    if (data !== undefined && data !== 'undefined') {
      localStorage.setItem('singleData', JSON.stringify(data));
    } else {
      localStorage.setItem('singleData', JSON.stringify([]))
    }
  }
  var setFirstReportData = (data) => {
    localStorage.setItem('firstReportData', JSON.stringify(data));
    console.log(data)
  }
  var setSecondReportData = (data) => {
    localStorage.setItem('secondReportData', JSON.stringify(data));
    console.log(data)
  }
  var setInfoBoxData = (data) => {
    console.log(data)
    localStorage.setItem('infoBoxData', JSON.stringify(data));
  }
  var setAssetID = (data) => {
    console.log(data)
    localStorage.setItem('assetNewID', (data));
  }
  var setLogData = (data) => {
    console.log(data)
    localStorage.setItem('logData', JSON.stringify(data));
  }
  var setReferenteContrattoData = (data) => {
    console.log('dd', data)
    localStorage.setItem('referenteContrattoData', JSON.stringify(data));
  }
  var setAcquisizioneData = (data) => {
    console.log(data)
    localStorage.setItem('acquisizioneData', JSON.stringify(data));
  }
  var setLastReportData = (data) => {
    if (data === undefined) {
      localStorage.setItem('lastReportData', JSON.stringify([]));
    } else {
      localStorage.setItem('lastReportData', JSON.stringify(data));
    }
  }
  var setToDelete = (data) => {
    console.log(data)
    localStorage.setItem('groupDelete', JSON.stringify(data));
  }
  var setAllegatoData = (data) => {
    console.log(data)
    localStorage.setItem('allegatoData', JSON.stringify(data));
  }
  var setMezziListData = (data) => {
    console.log(data)
    localStorage.setItem('mezziList', JSON.stringify(data));
  }
  var setResultGroupDEL = (data) => {
    localStorage.setItem('groupDeleteRES', (data));
  }
  var setNewRepID = (data) => {
    localStorage.setItem('reportNewId', (data));
  }
  var setTargetPROGRAMMA = (data) => {
    console.log('ttttt' + JSON.stringify(data))
    localStorage.setItem('targetPROGRAMMA', JSON.stringify(data));
  }
  var setCurrentReport = (data) => {
    localStorage.setItem('currentReport', JSON.stringify(data));
  }
  var setAvInterventi = (data) => {
    localStorage.setItem('avInterventi', JSON.stringify(data));
  }
  var setTipoI = (data) => {
    console.log('TIPOO', data)
    localStorage.setItem('tipoI', JSON.stringify(data));
  }
  var setPreviousAnagrafica = (data) => {
    localStorage.setItem('previousAnagrafica', JSON.stringify(data))
  }

  var getPreviousAnagrafica = (data) => {
    return JSON.parse(localStorage.getItem('previousAnagrafica'))
  }
  var getTipoI = () => {
    return JSON.parse(localStorage.getItem('tipoI'))
  }
  var getAvInterventi = () => {
    return JSON.parse(localStorage.getItem('avInterventi'))
  }
  var getCurrentReport = () => {
    return JSON.parse(localStorage.getItem('currentReport'))
  }
  var getTargetPROGRAMMA = () => {
    return JSON.parse(localStorage.getItem('targetPROGRAMMA'))
  }
  var getNewRepID = () => {
    return (localStorage.getItem('reportNewId'))
  }
  var getMezziListData = () => {
    return JSON.parse(localStorage.getItem('mezziList'))
  }
  var getAllegatoData = () => {
    return JSON.parse(localStorage.getItem('allegatoData'))
  }
  var getLastReportData = () => {
    return JSON.parse(localStorage.getItem('lastReportData'))
  }
  var getReferenteContrattoData = () => {
    return JSON.parse(localStorage.getItem('referenteContrattoData'))
  }
  var getFirstReportData = () => {
    return JSON.parse(localStorage.getItem('firstReportData'))
  }
  var getAcquisizioneData = () => {
    return JSON.parse(localStorage.getItem('acquisizioneData'))
  }
  var getSecondReportData = () => {
    return JSON.parse(localStorage.getItem('secondReportData'))
  }
  var getInterventoListData = () => {
    return JSON.parse(localStorage.getItem('interventoListData')).filter((obj) => obj !== null && obj !== undefined)
  }
  var getAnagraficaAssetGroupID = () => {
    return (localStorage.getItem('assetGroupID'))
  }
  var getProgId = () => {
    return (localStorage.getItem('progID'))
  }
  var getGroupedAsset = () => {
    return JSON.parse(localStorage.getItem('assetGroupList'))
  }
  var getActivityListData = () => {
    return JSON.parse(localStorage.getItem('activityList'))
  }
  var getAssetListData = () => {
    return JSON.parse(localStorage.getItem('assetList'))
  }
  var getAnagraficaData = () => {
    return JSON.parse(localStorage.getItem('anagraficaData'))
  }
  var getSingleData = () => {
    return JSON.parse(localStorage.getItem('singleData'))
  }
  var getProgrammaListData = () => {
    return JSON.parse(localStorage.getItem('programmaListData'))
  }
  var setFullProgramma = (data) => {
    localStorage.setItem('fullProgramma', JSON.stringify(data));
  }
  var getFullProgramma = () => {
    return JSON.parse(localStorage.getItem('fullProgramma'))
  }
  var getInfoBoxData = () => {
    return JSON.parse(localStorage.getItem('infoBoxData'))
  }
  var getLogBoxData = () => {
    return JSON.parse(localStorage.getItem('logData'))
  }
  var getClienteFromProgrammaData = () => {
    return JSON.parse(localStorage.getItem('clientfromprogram'))
  }
  var getAssetFromProgrammaData = () => {
    return JSON.parse(localStorage.getItem('assetfromprogram'))
  }
  var setClienteFromProgrammaData = (data) => {
    console.log(data)
    localStorage.setItem('clientfromprogram', JSON.stringify(data));
  }
  var getClienteFromProgrammaDataALL = () => {
    return JSON.parse(localStorage.getItem('ALLclientfromprogram'))
  }
  var setAssetFromProgrammaData = (data) => {
    console.log(data)
    if (data === undefined) {
      localStorage.setItem('assetfromprogram', JSON.stringify([]));
    } else {
      localStorage.setItem('assetfromprogram', JSON.stringify(data));
    }
  }
  var getAssetID = () => {
    return (localStorage.getItem('assetNewID'))
  }
  var getToDelete = () => {
    return JSON.parse(localStorage.getItem('groupDelete'))
  }
  var getToDeleteRES = () => {
    return (localStorage.getItem('groupDeleteRES'))
  }

  return {
    getToDeleteRES,
    setResultGroupDEL,
    setNewRepID,
    getNewRepID,
    getClienteFromProgrammaDataALL,
    setClienteFromProgrammaData,
    setAssetFromProgrammaData,
    getClienteFromProgrammaData,
    getAssetFromProgrammaData,
    setFullProgramma,
    setReferenteContrattoData,
    getReferenteContrattoData,
    getFullProgramma,
    setAnagraficaData,
    getAnagraficaData,
    setAssetListData,
    getAssetListData,
    setAnagraficaAssetGroupID,
    getAnagraficaAssetGroupID,
    setActivityListData,
    getActivityListData,
    setAssetGroupList,
    getAcquisizioneData,
    getGroupedAsset,
    setInterventoListData,
    getInterventoListData,
    setLastReportData,
    getLastReportData,
    setAssetID,
    getAssetID,
    setSingleAsset,
    getSingleData,
    setFirstReportData,
    setAcquisizioneData,
    getFirstReportData,
    setSecondReportData,
    getSecondReportData,
    setProgrammaListData,
    getProgrammaListData,
    setProgId,
    getProgId,
    setInfoBoxData,
    getInfoBoxData,
    setLogData,
    getLogBoxData,
    setAllegatoData,
    getAllegatoData,
    setMezziListData,
    getMezziListData,
    setTargetPROGRAMMA,
    getTargetPROGRAMMA,
    setCurrentReport,
    getCurrentReport,
    setAvInterventi,
    getAvInterventi,
    setTipoI,
    getTipoI,
    setToDelete,
    getToDelete,
    setPreviousAnagrafica,
    getPreviousAnagrafica
  }

}
