import { Avatar, Button, Box, Container, IconButton, Typography, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Drawer from '@material-ui/core/Drawer';
import Sidebar from "../../components/Sidebar"
import ListItemText from '@material-ui/core/ListItemText';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AggiungiAttivita from './AggiungiAttivita';
import userData from '../../data/userData'
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIntervento from './InfoIntervento';
import anagraficaData from '../../data/anagraficaData';
import webservice from '../../../api/webservice';
import { useHistory } from "react-router-dom";
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import exportFromJSON from 'export-from-json'
import PersonIcon from '@material-ui/icons/Person';
import { PhonelinkSetupSharp } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import contractData from '../../data/contractData';
import { Alert } from '@material-ui/lab';
import ProgramDetail from '../../../components/ProgramDetail';
import WrapperComponent from '../../components/WrapperComponent';

const useStyles = makeStyles((theme) => ({
  Header: {
    backgroundColor: "#00ADA2",
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center'
  },
  toolBar: {
    height: 'auto',
    backgroundColor: 'white',
    padding: '10px 15px 10px 50px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolBox: {
    width: '18%',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  pulsanteAttivita: {
    borderRadius: 20,
    padding: '5px 50px',
    border: '1px solid #5692d8',
    fontWeight: 'bold',
    letterSpacing: '.05em',
    color: '#5692d8'
  },
  "&:hover": {
    backgroundColor: 'transparent'
  },
  avatarBox: {
    width: '15%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mainRow: {
    width: '11.75%',
    borderLeftStyle: 'dashed',
    display: 'flex',
    padding: 5,
    justifyContent: 'space-evenly',
    borderLeftColor: '#898989',
    borderLeftWidth: 2
  },
  circleAdd: {
    border: '1px dashed #898989',
    borderRadius: 16,
    height: 20,
    marginLeft: 3,
    marginRight: 3,
    textHeight: 20,
    textAlign: 'center'

  },
  circleShow: {
    textHeight: 20,
    textAlign: 'center',
    background: '#71A8DB !important',
    borderRadius: 16,
    height: 20,
    marginLeft: 3,
    marginRight: 3,

  },
  userName: {
    margin: 'auto 0',
    color: '#898989',
    fontSize: '0.7vw',
  },
  dayNumber: {
    fontSize: '1.1vw',
    color: '#333'
  },
  daysOrganizer: {
    display: 'flex',
    flexDirection: 'row',
  },
  currentWeek: {
    color: '#25b4a7',
    fontWeight: 'bold',
    fontSize: '0.8vw',
    margin: 'auto 0',
    textTransform: 'capitalize'
  },
  weekSwitcher: {
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
  },
  stdIcon: {
    fontSize: '0.6vw',
    color: '#333'
  },
  stdSwitcherBtn: {
    width: 18,
    height: 18,
    margin: 'auto 0',
    '&:hover': {
      background: 'whitesmoke !important'
    }
  },
  swipeableBoxToolbar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  swipeableText: {
    color: '#898989',
    fontSize: '0.7vw',
    margin: 'auto 0'
  },
  topToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 40
  },
  rightTopText: {
    color: '#898989',
    fontWeight: 'normal',
    textAlign: 'left',
    marginLeft: '20px',
    fontSize: '0.8vw',
    letterSpacing: '.1em',
    marginTop: 20,
    margin: '20px auto',
  },
  singleRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 7,
    borderBottom: '1px solid #e8e8e8',
    marginBottom: 7,
    width: '40vw'

  },
  rightCod: {
    color: '#71A8DB',
    fontWeight: 'bold',
    fontSize: '0.7vw',
    letterSpacing: '.1em',
    width: '10%',
    margin: 'auto 25px'
  },
  rightDesc: {
    color: '#898989',
    width: '30%',
    fontSize: '0.6vw',
    margin: 'auto 0'
  },
  rightSimpleContainer: {
    width: '15%',
    textAlign: 'center',
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto 25px'
  },
  topBtnBox: {
    paddingLeft: 15,
    paddingRight: 15,
    border: '2px solid #00ADA2',
    color: '#00ADA2',
    '&:hover': {
      background: 'whitesmoke !important'
    }
  },
  topBtnBoxConfirm: {
    paddingLeft: 15,
    paddingRight: 15,
    border: '2px solid #00ADA2',
    background: '#00ADA2',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: 'whitesmoke !important',
      color: '#00ADA2',
    }
  },
  topBtnActive: {
    '&:hover': {
      background: 'darkorange !important'
    }
  },
  topBtnConfirm: {
    backgroundColor: '#3ca7a1',
    '&:hover': {
      background: '#50c7c1 !important'
    }
  },
  topBtnCancel: {
    color: '#3ca7a1',
    '&:hover': {
      color: 'white',
      background: '#f78f8f !important'
    }
  },
  topBtnStyle: {
    marginLeft: 5
  },
  singleDay: {
    width: '11.75%',
    padding: 5,
    textAlign: 'center',
  },
  dayOf: {
    color: '#898989',
    textTransform: 'capitalize',
    fontSize: '0.7vw'
  }

}))

const priorityColor = {
  manutenzione: '#5296d4',
  intervento: '#55bbaa',
  commessa: '#e4879d',
}

function Programma() {
  const history = useHistory()
  const classes = useStyles()
  var d = new Date();
  const [weekStart, setWeekStart] = React.useState(d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear())
  const [weekEnd, setWeekEnd] = React.useState((d.getDate() + 6) + '-' + (d.getMonth() + 1) + '-' + d.getFullYear())
  //API CALL
  const { getProgrammaList, getContractList, getClientList, getUserList, userList, getPrevAct, getFullProgrammaReq, GetClienteFromProgramma, GetAssetFromProgramma, GetFullInterventoList, getActivityList, createPrograma, confirmProgram } = webservice()
  const { getAnagraficaData, getFullProgramma, getInterventoListData } = anagraficaData()
  const { getUserData, setDrawer, getCurrentPermission } = userData()
  const { getContractListData,getNewContractClientList, setNewContractData, setContractType, setContractActivity, setNewContractAcquisizione, removeActivity, setContractReferent, setNewContractType, setContractInvoice, setContractDocument, setNewUploadsData } = contractData()

  const delay = ms => new Promise(res => setTimeout(res, ms));
  //State calls
  const [currentData, setCurrentData] = React.useState([])
  const [prevActData, setPrevActData] = React.useState([])
  React.useEffect(() => {
    async function initialData() {
      await getContractList('dd')
      await getPrevAct().then(res => setPrevActData(res))
      await getClientList('dd')
      await getUserList(getUserData().Token)
      await getFullProgrammaReq('dd')
      await GetFullInterventoList('dd')
      await delay(1000)
      setCurrentData(getFullProgramma())
      var date = new Date(2014, 10, 28, 0, 0, 0, 0);
    }
    initialData()

    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    var datt = getMonday(new Date())
    datt = datt.addDays(7)
    console.log(datt)
    let thisWeek = [formatDate(datt)]
    for (let i = 0; i < 6; i++) {
      thisWeek.push(formatDate(datt.addDays(1)))
      datt = datt.addDays(1)
    }
    setDateGrouper(thisWeek)


  }, [])

  //Start week from monday
  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  //Get day name
  function getDayname(date) {
    var days = ['Domenica', 'Lunedi\'', 'Martedi\'', 'Mercoledi\'', 'Giovedi\'', 'Venerdi\'', 'Sabato'];
    var dayName = days[new Date(date).getDay()];
    return (dayName)
  }
  //Get month name
  function getMonthname(date) {
    const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
      "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    var monthName = monthNames[new Date(date).getMonth()]
    return (monthName)
  }
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  //Activity button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Render day name
  function getDayNames(year, month) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var daysInMonth = new Date(year, month, 0).getDate();
    for (let i = 1; i <= daysInMonth; i++) {
      var d = new Date(year, month - 1, i);
      var dayName = days[d.getDay()];
    }
  }


  //SwitchDays
  function changeDays(arr, type) {

    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    let lastDay = new Date(arr[6])
    let firstDay = new Date(arr[0])


    if (type === 1) {
      let final = []
      for (let i = 0; i < 7; i++) {
        final.push(formatDate(lastDay.addDays(1)))
        lastDay = lastDay.addDays(1)
      }
      setDateGrouper(final)
    } else {
      let final = []
      for (let i = 0; i < 7; i++) {
        final.push(formatDate(firstDay.addDays(-1)))
        firstDay = firstDay.addDays(-1)
      }
      setDateGrouper(final.reverse())
    }
  }

  //Function to sort weeks
  const [dateAdd, setDate] = React.useState(0)
  const [dateGrouper, setDateGrouper] = React.useState([])

  //Swipeable right
  const [actPrev, setActPrev] = React.useState(false)
  //flag and function for add activity
  const [flag, setFlag] = React.useState(false)
  const [progId, setProgId] = React.useState(0)
  const [infoFlag, setInfoFlag] = React.useState(false)
  const [us, setUs] = React.useState([])
  const [infoTarget, setInfoTarget] = React.useState(0)
  const [targetClient, setTargetClient] = React.useState()
  const [confirmProgramStatus, setConfirmProgramStatus] = React.useState(false)
  const [snackSuccess, setSnackSuccess] = React.useState(false)

  function isBetween(currentDate, minDate, maxDate) {
    if (currentDate >= minDate && currentDate <= maxDate) {
      return true
    }
  }

  const [programDetailConfig,setProgramDetailConfig] = useState({
    visible:false,
    data:null,
    toggleModal:(variation) => setProgramDetailConfig(prevState => ({...prevState, visible: variation})),
    setData: (item) => setProgramDetailConfig(prevState => ({...prevState, data:item }))
  })
  console.log('CONFIG', programDetailConfig)
  function switchVariant(idINT) {
    if (idINT == undefined && idINT == null) return
    let res = getInterventoListData().find(obj => obj.id == idINT)
    if (res) {
      if (res.tipologia === 'manutenzione') {
        return 'M'
      } else {
        return 'I'
      }
    }

  }
  const [isInt, setIsInt] = React.useState(false)
  function testFun(user, programs, dates) {
    if (user !== null && user !== undefined && programs !== undefined) {
      let toShow = []
      return dates.map(cData => {
        let tmp
        if (programs !== null) {
          let tmpRes = []
          programs.map(currentProgram => {
            currentProgram.idCliente.split(',').map(currentUserID => {
              if (isBetween(cData, currentProgram.dataInizio, currentProgram.dataFine) && parseInt(currentUserID) === parseInt(user.id)) tmpRes.push(currentProgram)
            })
          })
          tmp = tmpRes
          //tmp = (programs.filter( cProgram => isBetween(cData,cProgram.dataInizio,cProgram.dataFine) && cProgram.idCliente === user.id )).slice(0,3)
        }
        if (tmp !== undefined) {
          return (
            <Box className={classes.mainRow}>
              {tmp.map((cc, index) => {
                return (
                  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                    {cc.stato !== 'malattia' && cc.stato !== 'ferie' && cc.stato !== 'permessi' && cc.variante !== 'commessa' ? (<><Typography style={{ color: '#898989', cursor: 'pointer', margin: 'auto 5px', marginBottom: '-8px', fontSize: '0.4vw' }}>+{cc.tempi}</Typography>
                      <Typography variant={'overline'} style={{ color: '#25b4a7', cursor: 'pointer', margin: 'auto 5px', fontSize: '0.5vw', fontWeight: 'bold' }} onClick={async e => {
                        e.preventDefault()
                        let tmp = userList.filter(ee => ee.id === cc.idCliente && ee.Tipologia == 'Utente')
                        setTargetClient(tmp[0])
                        await GetClienteFromProgramma('ddd', cc.byContratto)

                        await delay(500)
                        if (getInterventoListData() !== undefined && getInterventoListData() !== null) {
                          let res = getInterventoListData().filter(item => item.id == cc.idIntervento)[0]
                          switch (res.tipologia) {
                            case 'manutenzione':
                              setInfoTarget(res)
                              await getActivityList(getUserData().Token, res.byContratto)
                              break;
                            default:
                              setInfoTarget(cc)
                              await GetAssetFromProgramma(getUserData().Token, cc.idIntervento)
                              break;
                          }
                        }
                        if (switchVariant(cc.idIntervento) === 'I') {
                          setIsInt(true)
                        } else {
                          setIsInt(false)
                        }
                        setFullProg(cc)
                        setProgId(cc.id)
                        await delay(1000)
                        setInfoFlag(true)
                      }}>{switchVariant(cc.idIntervento)}.{cc.idIntervento}</Typography></>) : (
                      null
                    )}
                    {cc.stato === 'malattia' || cc.stato === 'ferie' || cc.stato === 'permessi' && cc.variante !== 'commessa' ? (
                      <Typography variant={'overline'} style={{ color: '#25b4a7', margin: 'auto 5px', fontSize: '0.65vw', fontWeight: 'bold',cursor:'pointer' }} onClick={() => {
                        programDetailConfig.setData(cc)
                        programDetailConfig.toggleModal(true)
                      }}>
                        {cc.stato.charAt(0)}
                      </Typography>
                    ) : null}
                    {cc.variante === 'commessa' ? (
                      <Typography variant={'overline'} style={{ color: '#f54463', cursor: 'pointer', margin: 'auto 5px', fontSize: '0.5vw', fontWeight: 'bold' }}>
                        C. {cc.idIntervento}
                      </Typography>
                    ) : null}
                  </Box>
                )



              })}
              {tmp !== undefined && tmp[0] !== undefined && tmp[0].variante !== 'commessa' ? (
                tmp[0].stato === 'permessi' || tmp[0].stato === 'ferie' || tmp[0].stato === 'malattia' ? (
                  <>
                    <Box className={classes.circleAdd} style={{ margin: 'auto 5px', cursor: 'not-allowed', width: 32, height: 32 }}>
                      <AddIcon style={{ padding: 4, color: '#898989' }} />
                    </Box>
                  </>
                ) : (
                  <Box className={classes.circleAdd} style={{ margin: 'auto 5px', cursor: 'pointer', width: 32, height: 32 }} onClick={e => {
                    setDate(cData)
                    setUs(user)
                    setFlag(true)
                  }
                  }>
                    <AddIcon style={{ padding: 4, color: '#898989' }} />
                  </Box>
                )
              ) : (
                <Box className={classes.circleAdd} style={{ margin: 'auto 5px', cursor: 'pointer', width: 32, height: 32 }} onClick={e => {
                  setDate(cData)
                  setUs(user)
                  setFlag(true)
                }
                }>
                  <AddIcon style={{ padding: 4, color: '#898989' }} />
                </Box>
              )}



            </Box>
          ) //Program found with x user and x date
        } else {
          return (
            <Box className={classes.mainRow}>
              <Box className={classes.circleAdd} style={{ margin: 'auto 5px', cursor: 'pointer', width: 32, height: 32 }} onClick={e => {
                setDate(cData)
                setFlag(true)
              }}>

                <AddIcon style={{ padding: 4, color: '#898989' }} />
              </Box>
            </Box>
          ) // No program with x user and x date SKIP
        }
      })
    }

  }
  function printColorBg(index) {
    if (index % 2 === 0) {
      return '#f2f2f3'
    } else {
      return 'white'
    }
  }
  const [fullProg, setFullProg] = React.useState({})

  //Switch priority
  function showPriority(type) {
    switch (type) {
      case 'urgente':
        return (require('../../../assets/images/anagraficaContratto/ticket/pUrgente.png'))
        break;
      case 'medio':
        return (require('../../../assets/images/anagraficaContratto/ticket/pMedia.png'))
        break;
      case 'emergenza':
        return (require('../../../assets/images/anagraficaContratto/ticket/pEmergenza.png'))
        break;
      case 'basso':
        return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
        break;
    }
  }
  function showPriorityColor(type) {
    switch (type) {
      case 'urgente':
        return 'red'
        break;
      case 'medio':
        return 'red'
        break;
      case 'emergenza':
        return 'blue'
        break;
      case 'basso':
        return '#ec5388'
        break;
    }
  }

  //Export side
  const [selected, setSelected] = useState()
  function isSelected(id, type) {
    if (selected !== undefined) {
      let tmp = selected.filter(item => item.id === id)
      if (tmp.length > 0) {
        if (type === 'border') {
          return '1px solid #dadada'
        } else {
          return 'whitesmoke'
        }
      } else {
        if (type === 'border') {
          return 'none'
        } else {
          return 'white'
        }
      }
    }
    if (type === 'border') {
      return 'none'
    } else {
      return 'white'
    }
  }
  const [toCopy, setToCopy] = React.useState([])
  const [activeCopy, setActiveCopy] = React.useState(true)
  const [activeCopyF, setActiveCopyF] = React.useState(true)
  //actprev

  //Switch priority
  function showPriority(type) {
    switch (type) {
      case 'urgente':
        return (require('../../../assets/images/anagraficaContratto/ticket/pUrgente.png'))
        break;
      case 'medio':
        return (require('../../../assets/images/anagraficaContratto/ticket/pMedia.png'))
        break;
      case 'emergenza':
        return (require('../../../assets/images/anagraficaContratto/ticket/pEmergenza.png'))
        break;
      case 'basso':
        return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
        break;
      case 'bassa':
        return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
        break;
    }
  }
  function showPriorityColor(type) {
    switch (type) {
      case 'urgente':
        return 'red'
        break;
      case 'medio':
        return 'red'
        break;
      case 'emergenza':
        return 'blue'
        break;
      case 'basso':
        return '#ec5388'
        break;
      case 'bassa':
        return '#ec5388'
        break;
    }
  }
  function displayStatus(type) {
    switch (type) {
      case 'attivo':
        return <>
          <Typography style={{ fontSize: 16, color: 'white', background: '#397BD3', borderRadius: 10, margin: '0 auto', padding: '2px 8px' }}>A</Typography>
          <Typography style={{ fontSize: 12, color: '#12BB9C', fontWeight: 'bold', color: '#397BD3' }}>Aperto</Typography>
        </>
      case 'lavorazione':
        return <>
          <Typography style={{ fontSize: 16, color: 'white', background: '#EB9427', borderRadius: 10, margin: '0 auto', padding: '2px 8px' }}>L</Typography>
          <Typography style={{ fontSize: 12, color: '#12BB9C', fontWeight: 'bold', color: '#EB9427' }}>Lavorazione</Typography>
        </>
      case 'finito':
        return <>
          <Typography style={{ fontSize: 16, color: 'white', background: '#0A9EA3', borderRadius: 10, margin: '0 auto', padding: '2px 8px' }}>F</Typography>
          <Typography style={{ fontSize: 12, color: '#12BB9C', fontWeight: 'bold', color: '#0A9EA3' }}>Finito</Typography>
        </>
      case 'sospeso':
        return <>
          <Typography style={{ fontSize: 16, color: 'white', background: '#C09B6E', borderRadius: 10, margin: '0 auto', padding: '2px 8px' }}>S</Typography>
          <Typography style={{ fontSize: 12, color: '#12BB9C', fontWeight: 'bold', color: '#C09B6E' }}>Sospeso</Typography>
        </>
      case 'cancellato':
        return <>
          <Typography style={{ fontSize: 16, color: 'white', background: '#E71C28', borderRadius: 10, margin: '0 auto', padding: '2px 8px' }}>C</Typography>
          <Typography style={{ fontSize: 12, color: '#12BB9C', fontWeight: 'bold', color: '#E71C28' }}>Cancellato</Typography>
        </>
    }
  }

  function printActPrev() {
    let finalCompose = []
    prevActData.map(current => {
      finalCompose.push({
        id: current.id,
        descrizione: current.descrizione,
        priorita: current.priorita,
        status: current.stato,
        contratto: current.byContratto
      })
    })
    return finalCompose.map(item => {
      return (
        <Box className={classes.singleRight}>
          <Typography variant={'h6'} className={classes.rightCod} style={{
            color: switchVariant(item.id) === 'I' ? priorityColor.intervento : priorityColor.manutenzione
          }}>{switchVariant(item.id)}.{item.id}</Typography>
          <Typography variant={'subtitle2'} className={classes.rightDesc}>{printClient(item.contratto)}</Typography>
          <Typography variant={'subtitle2'} className={classes.rightDesc} style={{ width: '30%' }}>{printContractTitle(item.contratto)} <br />{item.descrizione.split(0, 70)}</Typography>
          <div style={{ width: '10%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <img src={showPriority(item.priorita)} style={{ margin: '0 auto', width: 24 }} />
            <Typography variant={'outline'} style={{ color: showPriorityColor(item.priorita), fontSize: '.4vw', letterSpacing: '.1em', textTransform: 'uppercase', fontWeight: 'bold', marginTop: 10 }}>{item.priorita}</Typography>
          </div>
          <Box className={classes.rightSimpleContainer}>
            <Box style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexDirection: 'column' }}>
              {displayStatus(item.status)}
            </Box>
          </Box>
        </Box>
      )
    })
  }
  const fileName = 'lista_programmi'
  const exportType = 'csv'


  function DifferenceInDays(firstDate, secondDate) {
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
  }
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const clickedBtn = () => {
    switch (activeCopy) {
      case true:
        return {
          marginRight: 25
        }
      case false:
        return {
          marginRight: 25,
          backgroundColor: 'orange',
          border: '1px solid darkorange',
          color: 'white',
          "&:hover": {
            backgroundColor: 'darkorange !important'
          }
        }
    }
  }



  function printClient(id, type) {
    let out = ''
    let addr = ''
    getContractListData().map(cc => {
      if (cc.id == id) {
        getNewContractClientList().map(user => {
          if (user.id == cc.cliente) {
            out = (user.rSociale)
            addr = (user.indirizzo)
          }
        })
      }
    })
    if (type) {
      return addr
    } else {
      return out
    }
  }

  function printContractTitle(id) {
    console.log(getContractListData(), id)
    if (getContractListData()) {
      let res = getContractListData().find(obj => obj.id == id.toString())
      console.log('RESSS', res)
      if (res) {
        return res.titolo
      }
    }
  }

  const [confirmPaste, setConfirmPaste] = React.useState(false)

  const WrapperConfig = {
    title: 'Contratti',
    subTitle:'Da qui puoi gestire i tuoi contratti di manutenzione, approvarare le attività,\n gestire il programma User, gestire l\'anagrafica impianto',
    activeMenu:3,
    options: [
      {
        label:'Lista contratti',
        onPress: () => history.push('/contratti/listaContratti'),
        enabled: true,
        active:false
      },
      {
        label:'Nuovo contratto',
        onPress: () => {
          setNewContractData(null)
                  setNewContractAcquisizione(null)
                  setContractActivity('reset')
                  setContractReferent('reset')
                  setContractInvoice(null)
                  setNewContractType(null)
                  setNewUploadsData(null)
                  setContractType(null)
                  setContractDocument('reset')
                  history.push('/contratti/nuovoContratto')
        },
        enabled: getCurrentPermission() !== null && getCurrentPermission().nuovoContratto === true,
        active:false
      },
      {
        label:'Programma',
        onPress: () => history.push('/clienti'),
        enabled: true,
        active:true //PEX
      },
      {
        label:'Attività',
        onPress: () => history.push('/attivita'),
        enabled: true,
        active:false //PEX
      }
    ]
  }

  return (
      <WrapperComponent {...WrapperConfig}>
        <ProgramDetail {...programDetailConfig}/>

        <Container maxWidth="False" className={classes.toolBar}>
          <Box style={{ display: 'flex' }}>
            <img src={require('../../../assets/images/anagraficaContratto/programmaSett.png')} style={{ width: 40, marginRight: 30 }} />
            <Typography variant='h6' component='h2' style={{ color: '#6A6A6A', margin: 'auto 0' }}>Programma</Typography>
          </Box>
          <Box className={classes.toolBox}>
            <Box className={classes.swipeableBoxToolbar} onClick={e => setActPrev(true)}>
              <SwapHorizIcon style={{ color: 'darkblue', margin: 'auto 0' }} />
              <Typography variant={'h6'} className={classes.swipeableText} >Attività previste</Typography>
            </Box>
            <Button className={classes.pulsanteAttivita} aria-controls="simple-menu" style={{ backgroundColor: 'transparent' }} aria-haspopup="true" onClick={handleClick}>
              Attivita'
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <MenuItem onClick={e => {
                //console.log( getInterventoListData(),getFullProgramma(), selected )
                let data = []
                getInterventoListData().map(e => {
                  getFullProgramma().map(prog => {
                    if (e.id === prog.idIntervento && prog.idCliente === selected[0].id) {
                      data.push(e)
                    }
                  })
                })
                if (data.length > 0) {
                  exportFromJSON({ data, fileName, exportType })
                }
              }}>
                <ListItemText primary="Seleziona ed Esporta" />
              </MenuItem>
            </Menu>
          </Box>
        </Container>
        <Container maxWidth="False" className={classes.userTable} style={{padding:'0px'}}>
          {/* Declaring dialog call */}
          <Dialog
            open={confirmPaste}
            onClose={e => setConfirmPaste(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"> Incollare i programmi copiati?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Incollare i programmi copiati nella settimana {dateGrouper !== undefined && dateGrouper !== null ? dateGrouper[0] + " - " + dateGrouper[6] : null} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setConfirmPaste(false)
              }} color="primary" className={classes.topBtnCancel}>
                Annulla
              </Button>
              <Button onClick={() => {
                if (toCopy.length > 0) {
                  async function sendData() {
                    toCopy.map(async cProg => {
                      var newUp = JSON.parse(JSON.stringify(cProg));
                      newUp.dataInizio = dateGrouper[new Date(cProg.dataInizio).addDays(-1).getDay()];
                      newUp.dataFine = formatDate(new Date(newUp.dataInizio).addDays(DifferenceInDays(new Date(cProg.dataInizio), new Date(cProg.dataFine))))
                      if (newUp.stato === "attivo") {
                        //Upload copied prog

                        await createPrograma(
                          getUserData().Token,
                          newUp.idIntervento,
                          newUp.stato,
                          newUp.tempi,
                          newUp.dataInizio,
                          newUp.dataFine,
                          newUp.idCliente,
                          newUp.description,
                          newUp.byContratto,
                          newUp.dayOf
                        )

                        await delay(500)

                      }
                    })
                    await delay(500)
                    await getFullProgrammaReq('dd')
                    await delay(1000)
                    setCurrentData(getFullProgramma())
                    setConfirmPaste(false)
                    //window.location.reload(false)
                  }

                  sendData()
                }
              }} color="primary" autoFocus className={classes.topBtnConfirm} style={{
                backgroundColor: '#00ada2',
                color: 'white'
              }}>
                Incolla
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={confirmProgramStatus}
            onClose={() => setConfirmProgramStatus(false)}
            aria-labelledby="confirm-program"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Confermare e inviare i dati ai Tecnici ?
            </DialogTitle>
            <DialogActions>
              <Button className={classes.topBtnCancel} autoFocus onClick={() => setConfirmProgramStatus(false)} color="primary">
                Annulla
              </Button>
              <Button onClick={(e) => {
                e.preventDefault()
                let tmp = getFullProgramma()
                if (tmp !== undefined && tmp !== null) {
                  tmp.map(async cProg => {
                    if (cProg.confirmed !== true) {
                      let toUpdate = {
                        token: getUserData().Token,
                        id: cProg.id
                      }
                      confirmProgram(toUpdate)
                      await delay(250)

                    }

                  })
                }
                setConfirmProgramStatus(false)
                setSnackSuccess(true)
              }} color="primary" className={classes.topBtnConfirm} style={{
                backgroundColor: '#00ada2',
                color: 'white'
              }} >
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <InfoIntervento isInt={isInt} flag={infoFlag} handleClose={e => setInfoFlag(false)} target={infoTarget} cliente={targetClient} progId={progId} fullProg={fullProg} />
          <AggiungiAttivita user={us} flag={flag} handleClose={e => setFlag(false)} date={dateAdd} />
          {getDayNames()}
          <Drawer anchor={'right'} open={actPrev} onClose={e => setActPrev(false)} style={{ paddingTop: 10, paddingLeft: 15, paddingRight: 15, width: '40vw !important' }}>
            <div style={{ alignItems: 'flex-start' }}>
              <Typography variant='h6' className={classes.rightTopText} style={{ marginBottom: 20 }}>
                Attività Previste
              </Typography>
            </div>

            {printActPrev()}
          </Drawer>
          <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 25 }}>
            <Button disabled={false} variant="outlined" className={activeCopy === true ? classes.topBtnBox : classes.topBtnActive} style={clickedBtn()} onClick={async e => {
              e.preventDefault()
              if (activeCopy === true) {
                const isBetween = (date, min, max) => (date.getTime() >= min.getTime() && date.getTime() <= max.getTime());
                let data = []

                userList.filter(e => e.Tipologia === 'Utente').map(cSelected => {
                  getInterventoListData().map(e => {
                    getFullProgramma().map(prog => {
                      if (e.id === prog.idIntervento && prog.idCliente === cSelected.id && isBetween(
                        new Date(prog.dataInizio),
                        new Date(dateGrouper[0]),
                        new Date(dateGrouper[6])
                      ) && isBetween(
                        new Date(prog.dataFine),
                        new Date(dateGrouper[0]),
                        new Date(dateGrouper[6])
                      )) {
                        data.push(prog)
                      }
                    })
                  })
                })

                setToCopy(data)
                setActiveCopy(false)
              } else {
                setConfirmPaste(true)
              }


            }}>
              <Typography variant={'subtitle2'}>{activeCopy === true ? "Copia Prog." : "Incolla"}</Typography>
              <FileCopyIcon className={classes.topBtnStyle} />
            </Button>
            <Button className={classes.topBtnBoxConfirm} onClick={async e => {
              e.preventDefault()
              setConfirmProgramStatus(true)
            }}>
              <Typography variant={'subtitle2'} >Conferma</Typography>
              <CloudDoneIcon className={classes.topBtnStyle} />
              
            </Button>
          </Box>
          <Box className={classes.daysOrganizer} style={{ marginTop: 30 }}>
            <Box className={classes.weekSwitcher}>
              <IconButton className={classes.stdSwitcherBtn} onClick={e => {
                e.preventDefault()
                changeDays(dateGrouper, 2)
              }}><ArrowBackIosIcon className={classes.stdIcon} /></IconButton>
              <Typography variant={'h6'} className={classes.currentWeek} style={{ width: '100%', textAlign: 'center' }}>{dateGrouper[0] !== undefined ? dateGrouper[0].split('-')[2] + (dateGrouper[0].split('-')[1] !== dateGrouper[6].split('-')[1] ? " " + getMonthname(dateGrouper[0]) + " " : "") + '-' + dateGrouper[6].split('-')[2] + ' ' + getMonthname(dateGrouper[6]) + " " + dateGrouper[0].split('-')[0] : "Caricamento.."}</Typography>
              <IconButton className={classes.stdSwitcherBtn} onClick={e => {
                e.preventDefault()
                changeDays(dateGrouper, 1)
              }}><ArrowForwardIosIcon className={classes.stdIcon} /></IconButton>
            </Box>
            {dateGrouper !== undefined && dateGrouper !== null ? (
              dateGrouper.map(date => {
                return (
                  <Box className={classes.singleDay}>
                    <Typography variant={'h6'}>{date.split('-')[2]}</Typography>
                    <Typography variant={'subtitle2'} className={classes.dayOf}>{getDayname(date)}</Typography>
                  </Box>
                )
              })
            ) : null}


          </Box>
          {userList !== undefined ? (
            userList.filter(e => e.Tipologia === 'Utente').map((user, index) => {
              return (

                <Box style={{ display: 'flex', padding: '1.75px 8px', border: isSelected(user.id, 'border'), backgroundColor: isSelected(user.id, 'background'), background: printColorBg(index) }}>
                  <Box className={classes.avatarBox} style={{ cursor: 'pointer' }} onClick={e => {
                    e.preventDefault()
                    /*let sel = selected
                    if( sel !== undefined ){
                        let tmp = sel.filter( item => item.id === user.id )
                        if(tmp.length > 0){
                            let toUp = sel.filter( item => item.id !== user.id )
                            setSelected(toUp)
                        }else{
                            sel.push(user)
                            setSelected(sel)
                        }
                    }else{
                        setSelected([user])
                    }*/
                    setSelected([user])
                    setActiveCopyF(false)
                    forceUpdate()
                  }}>
                    <Avatar style={{ margin: 'auto 0', marginRight: 20, marginLeft: 20, width: 32, height: 32 }}>{user.Nome.charAt(0)}</Avatar>
                    <Typography variant={'h6'} className={classes.userName}>{user.Nome + ' ' + user.Cognome}</Typography>
                  </Box>
                  {testFun(user, getFullProgramma(), dateGrouper)}
                </Box>
              )
            })
          ) : null}
          <Snackbar open={snackSuccess} autoHideDuration={3000} onClose={() => setSnackSuccess(false)}>
            <Alert onClose={() => setSnackSuccess(false)} severity="success">
              Attività confermate
            </Alert>
          </Snackbar>
        </Container>

      </WrapperComponent>
  )
}


export default Programma
