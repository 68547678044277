import React, { useState,useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Sidebar from "../../components/Sidebar"
import { Box, Container, IconButton, Radio, Typography, Button } from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import webservice from '../../../api/webservice'
import { useHistory } from "react-router-dom";
import userData from '../../data/userData';
import InfoIcon from '@material-ui/icons/Info';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import DescriptionIcon from '@material-ui/icons/Description';
import anagraficaData from '../../data/anagraficaData';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIntervento from './InfoIntervento';
import PersonIcon from '@material-ui/icons/Person';
import contractData from '../../data/contractData';
import { DatePicker } from 'antd'
import WrapperComponent from '../../components/WrapperComponent';
const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
    Header:{
        backgroundColor: "#00ADA2",
        height: 50,
        display:'flex',
        justifyContent:'space-between',
        paddingLeft:15,
        paddingRight:15,
        alignItems:'center'
      },
      toolBar:{
        height:'auto',
        backgroundColor:'white',
        padding:'10px 0px',
        paddingBottom:1,
        marginBottom:30
      },
      toolBox:{
        width:'18%',
        display:'flex',
        justifyContent:'space-evenly'
      },
      pulsanteAttivita:{
        borderRadius:20,
        padding:'5px 50px',
        border:'1px solid #5692d8',
        fontWeight:'bold',
        letterSpacing:'.05em',
        color:'#5692d8'
      },
      "&:hover":{
        backgroundColor:'transparent'
      },
      columnText:{
        fontWeight:'bold',
        color:'#333',
        fontSize:'.7vw'
    },
    oneRow:{
        display:'flex',
        justifyContent:'space-around',
        paddingTop:5,
        paddingBottom:5,
        marginBottom:10,
        backgroundColor:'white'
    },
    imgSTD:{
        width:24
    },
    ticketColumn:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    },
    lightB:{
      '&:hover':{
        background:'#eaf8fb !important'
      }
    },
    lightR:{
      '&:hover':{
        background:'#f9e2d8 !important'
      }
    },
    iconIMG:{
        height:18
    },
    singleColumn:{
      textAlign:'center',
      display:'flex',
      flexDirection:'row',
    },
    filterText:{
      color:'#333',
      letterSpacing:'.1em'
    },
    filterArrow:{
      color:'#898989'
    }
    
}))
function Programma() {
   
   //Data profile vars
  const { getInterventoList,updateAccepted,GetClienteFromProgramma,getAssedById, GetFullInterventoList, getActivityList,getClientList,getContractList, getFullActivityList } = webservice()
  const { setDrawer,getUserData, getCurrentPermission } = userData();
  const [actList,setActList] = React.useState([])
  const { getInterventoListData,getAnagraficaData } = anagraficaData()
  const { getContractListData,getNewContractClientList, setNewContractData, setContractType, setContractActivity, setNewContractAcquisizione, removeActivity, setContractReferent, setNewContractType, setContractInvoice, setContractDocument, setNewUploadsData  } = contractData()
  //Ticket List var
  const [ticketList,setTicketList] = React.useState([])

  const delay = ms => new Promise(res => setTimeout(res, ms));
  const history = useHistory();

  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    async function initialData(){
      //AGGIUNGERE .map per prendere tutti gli ID dei contratti
      await getFullActivityList('dd').then(res => setActList(res))
      await GetFullInterventoList(getUserData().Token, '0')
      await getClientList(getUserData().Token)
      await getContractList(getUserData().Token)
      await delay(1000)
      //let toShow =  getInterventoListData().filter( current => current.accepted !== 3 )
      setTicketList(getInterventoListData())
      forceUpdate()
    }
    initialData()
    //console.log(ticketList[0])
  }, [])

//Checked state shower
  const [checked,setChecked] = React.useState([])

  //Switch priority
  function showPriority(type){
    switch(type){
      case 'urgente':
          return (require('../../../assets/images/anagraficaContratto/ticket/pUrgente.png'))
          break;
      case 'medio':
          return (require('../../../assets/images/anagraficaContratto/ticket/pMedia.png'))
          break;
      case 'emergenza':
          return (require('../../../assets/images/anagraficaContratto/ticket/pEmergenza.png'))
          break;
      case 'basso':
          return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
          break;
      case 'bassa':
          return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
          break;
    }
  }
  function showPriorityColor(type){
    switch(type){
      case 'urgente':
          return 'red'
          break;
      case 'medio':
          return 'red'
          break;
      case 'emergenza':
          return 'blue'
          break;
      case 'basso':
          return '#ec5388'
          break;
      case 'bassa':
          return '#ec5388'
          break;
    }
  }
  const classes = useStyles();

  function showChecked(itm,type){
    if(type === 'yes'){
      switch(itm.accepted){
        case 1:
          return true
        default:
          return false
      }
    }else{
      switch(itm.accepted){
        case 2:
          return true
        default:
          return false
      }
    }
  }
  const [dateArr,setDateArr] = useState(false)
  const [prioritaArr,setPrioritaArr] = useState(false)
  const [statusArr,setStatusArr] = useState(false)
  const [acceptedArr,setAcceptedArr] = useState(false)

  //Function to handle Filters
  function handleFilter(type){
    switch(type){
      case 'data':
        let def = ticketList
        setTicketList(def.reverse())
        setDateArr(!dateArr)
        forceUpdate()
        break;
      case 'priorita':
        let urg = []
        let med = []
        let eme = []
        let bas = []
        ticketList.map( item => {
          if(item.priorita === "urgente"){
            urg.push(item)
          }else if(item.priorita === "medio"){
            med.push(item)
          }else if(item.priorita === "emergenza"){
            eme.push(item)
          }else{
            bas.push(item)
          }
        } )
        setPrioritaArr(!prioritaArr)
        if(prioritaArr === false ? setTicketList( [...urg,...med,...eme,...bas] ) : setTicketList( [...urg,...med,...eme,...bas].reverse() ))
        forceUpdate()
        break;
      case 'status':
        let aperto = []
        let chiuso = []
        ticketList.map( item => {
          if(item.accepted === 1 ){
            aperto.push(item)
          }else{
            chiuso.push(item)
          }
        } )
        setStatusArr(!statusArr)
          statusArr === false ? setTicketList( [...chiuso,...aperto] ) : setTicketList( [...aperto,...chiuso] ) 
          forceUpdate()
        break;
      case 'accepted':
        let apertoA = []
        let chiusoA = []
        let sospesoA = []
        ticketList.map( item => {
          if(item.accepted === 1 ){
            apertoA.push(item)
          }else if(item.accepted == null){
            sospesoA.push(item)
          }else{
            chiusoA.push(item)
          }
        } )
        setAcceptedArr(!acceptedArr)
        acceptedArr === false ? setTicketList( [...chiusoA,...apertoA,...sospesoA] ) : setTicketList( [...sospesoA,...apertoA,...chiusoA] ) 
        forceUpdate()
        break;
    }
  }

  //Print to display status
  function showStatus(accepted){
    switch(accepted){
      case 1:
        return 'Aperto'
      case 2:
        return "Chiuso"
      default:
        return "Sospeso"
    }
  }

  //Print Client name
  function printClient(id){
    let out = ''
    getContractListData().map( cc => {
      if(cc.id == id){
        getNewContractClientList().map( user => {
          if(user.id == cc.cliente){
            out = (user.rSociale)
          }
        } )
      }
    } )
    return out
  }

  //PRIORITA FILTER ZONE
  const [anchorElP, setAnchorElP] = React.useState(null);

  const handleClickP = (event) => {
    setAnchorElP(event.currentTarget);
  };

  const handleCloseP = () => {
    setAnchorElP(null);
  };
  function filterPrioritaP(type){
    let filtered = getInterventoListData().filter( current => current.accepted !== 3 ).filter( e => e.priorita == type )
    setTicketList(filtered)
    handleCloseP()
  }

  //STATUS FILTER ZONE
  const [anchorElS, setAnchorElS] = React.useState(null);

  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
  };

  function displayType(item){
    if(item.priorita === 'bassa'){
      return 'basso'
    }
    return item.priorita
  }

  const handleCloseS = () => {
    setAnchorElS(null);
  };
  async function filterPrioritaS(type){
    let filtered
    if(type === null){
      filtered = getInterventoListData().filter( current => current.accepted !== 3 ).filter( e => e.accepted == null || e.accepted === 0 )
    }else{
      filtered = getInterventoListData().filter( current => current.accepted !== 3 ).filter( e => e.accepted == type )
    }
    //setTicketList(filtered)
    await GetFullInterventoList(getUserData().Token,type)
    await delay(500)
    setTicketList(getInterventoListData())
    forceUpdate()
    
    handleCloseS()
  }

  //ACCEPTED FILTER ZONE
  const [anchorElA, setAnchorElA] = React.useState(null);

  function filterPrioritaT(type){
    if(type === 'M'){
      let filtered = getInterventoListData().filter(obj => obj.tipologia === 'manutenzione')
    setTicketList(filtered)
    handleCloseP()
    }else{
      let filtered = getInterventoListData().filter(obj => obj.tipologia !== 'manutenzione')
    setTicketList(filtered)
    handleCloseP()
    }
    
  }

  const handleClickA = (event) => {
    setAnchorElA(event.currentTarget);
  };

  const handleCloseA = () => {
    setAnchorElA(null);
  };

  //TIPOLOGIA FILTER ZONE
  const [anchorElT, setAnchorElT] = React.useState(null);
  const handleClickT = (event) => {
    setAnchorElT(event.currentTarget);
  };

  const handleCloseT = () => {
    setAnchorElT(null);
  };

  //Activity button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //INFO TICKET ZONE
  const [infoFlag,setInfoFlag] = React.useState(false)
  const [infoTarget,setInfoTarget] = React.useState([])
  const [targetClient,setTargetClient] = React.useState()
  const [currentFilter,setCurrentFilter] = React.useState('0')
  const [type,setType] = React.useState('')

  function printFiltersP(){
    switch(currentFilter){
      case '0':
        return <p style={{margin:0}}>Confermato/Non Confermato/<span style={{fontWeight:'bold'}}>Sospesi</span></p>
      case '1':
        return <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Confermato</span>/Non Confermato/Sospesi</p>
      case '2':
        return <p style={{margin:0}}>Confermato/<span style={{fontWeight:'bold'}}>Non Confermato</span>/Sospesi</p>
    }
  }

  function displayKind(item){
    if(getInterventoListData() !== undefined && getInterventoListData() !== null){
      let res = getInterventoListData().filter(cIntervento => cIntervento.id == item.id)[0]
      if(res.tipologia === 'manutenzione'){
        return 'M. '
      }else{
        return 'I. '
      }
    }
  }

  function displayDiffColor(variant){
    if(variant === 'I. '){
      return '#00ADA2'
    }else {
      return '#65a1fd'
    }
  }

  function printDesc(item){
    if(item === undefined || item === null) return 'CARICAMENTO...'
    if(item.tipologia !== 'manutenzione'){
      return item.descrizione.substring(0,100) + (item.descrizione.length > 99 ? '...' : '.')
    }else{
      let res = actList.filter(obj => obj.idContratto == item.byContratto && obj.periodo == item.dataInizio.split('T')[0])
      console.log(item,res,actList)
      return res.length + ' Attività presenti'
    }
  }

  const WrapperConfig = {
    title: 'Contratti',
    subTitle:'Da qui puoi gestire i tuoi contratti di manutenzione, approvarare le attività,\n gestire il programma User, gestire l\'anagrafica impianto',
    activeMenu:3,
    options: [
      {
        label:'Lista contratti',
        onPress: () => history.push('/contratti/listaContratti'),
        enabled: true,
        active:false
      },
      {
        label:'Nuovo contratto',
        onPress: () => {
          setNewContractData(null)
                  setNewContractAcquisizione(null)
                  setContractActivity('reset')
                  setContractReferent('reset')
                  setContractInvoice(null)
                  setNewContractType(null)
                  setNewUploadsData(null)
                  setContractType(null)
                  setContractDocument('reset')
                  history.push('/contratti/nuovoContratto')
        },
        enabled: getCurrentPermission() !== null && getCurrentPermission().nuovoContratto === true,
        active:false
      },
      {
        label:'Programma',
        onPress: () => history.push('/programma'),
        enabled: true,
        active:false //PEX
      },
      {
        label:'Attività',
        onPress: () => history.push('/attivita'),
        enabled: true,
        active:true //PEX
      }
    ]
  }

    return (
            <WrapperComponent {...WrapperConfig}>
                
                <Container maxWidth="False" className={classes.toolBar}>
                <Box style={{alignItems:'center',display:'flex',flexDirection:'row',justifyContent:'space-around',}}> 
<Box style={{width:'8%'}}><Box style={{display:'flex'}}>
                    <img src={require('../../../assets/images/ATTIVITA.png')} style={{width:40,marginRight:30}} />
                    <Typography className={classes.filterText} onClick={handleClickT} style={{cursor:'pointer',marginRight:15}}>Tipologia</Typography>
                    <ClearIcon onClick={ e => setTicketList( getInterventoListData().filter( current => current.accepted !== 3 ) ) } style={{color:'#898989',cursor:'pointer'}}/>
                </Box>
                
                 </Box>
                 <Menu
    id="simple-menu"
    anchorEl={anchorElT}
    keepMounted
    open={Boolean(anchorElT)}
    onClose={handleCloseT}
    getContentAnchorEl={null}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    transformOrigin={{vertical: 'top', horizontal: 'center'}}
  >
    <MenuItem onClick={ e => filterPrioritaT('I')}>Intervento</MenuItem>
    <MenuItem onClick={ e => filterPrioritaT('M')}>Manutenzione</MenuItem>
    </Menu>
  <Box style={{width:'5%'}}></Box>

  <Box style={{width:'25%'}}></Box>

  <Box className={classes.singleColumn} style={{width:'5%',cursor:'pointer'}} onClick={ e=> {
    handleFilter('data')
  } }>
    <Typography className={classes.filterText}>Data</Typography>
    {dateArr === false ? (
      <KeyboardArrowDownIcon className={classes.filterArrow}></KeyboardArrowDownIcon>
    ) : ( 
        <KeyboardArrowUpIcon className={classes.filterArrow}></KeyboardArrowUpIcon>
    )}
  </Box>

  <Box className={classes.singleColumn} style={{width:'5%',display:'flex',justifyContent:'space-around'}}>
    <Typography className={classes.filterText} onClick={handleClickP} style={{cursor:'pointer'}}>Priorità</Typography>
    <ClearIcon onClick={ e => setTicketList( getInterventoListData().filter( current => current.accepted !== 3 ) ) } style={{color:'#898989',cursor:'pointer'}}/>
  </Box>
  <Menu
    id="simple-menu"
    anchorEl={anchorElP}
    keepMounted
    open={Boolean(anchorElP)}
    onClose={handleCloseP}
    getContentAnchorEl={null}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    transformOrigin={{vertical: 'top', horizontal: 'center'}}
  >
    <MenuItem onClick={ e => filterPrioritaP('urgente')}>Urgente</MenuItem>
    <MenuItem onClick={ e => filterPrioritaP('emergenza')}>Emergenza</MenuItem>
    <MenuItem onClick={ e => filterPrioritaP('medio')}>Medio</MenuItem>
    <MenuItem onClick={ e => filterPrioritaP('basso')}>Basso</MenuItem>
  </Menu>

  <Box className={classes.singleColumn} style={{width:'8%',display:'flex',justifyContent:'center'}}>
    <Typography className={classes.filterText} onClick={handleClickS} style={{cursor:'pointer',marginRight:15}}>Status</Typography>
    <ClearIcon onClick={ e => setTicketList( getInterventoListData().filter( current => current.accepted !== 3 ) ) } style={{color:'#898989',cursor:'pointer'}}/>
  </Box>
  <Menu
    id="simple-menu"
    anchorEl={anchorElS}
    keepMounted
    open={Boolean(anchorElS)}
    onClose={handleCloseS}
    getContentAnchorEl={null}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    transformOrigin={{vertical: 'top', horizontal: 'center'}}
  >
    <MenuItem onClick={ e => filterPrioritaS('1')}>Aperto</MenuItem>
    <MenuItem onClick={ e => filterPrioritaS('2')}>Chiuso</MenuItem>
    </Menu>



    <Box className={classes.singleColumn} style={{width:'15%',display:'flex',justifyContent:'flex-start'}}>
    <Typography className={classes.filterText} onClick={handleClickA} style={{cursor:'pointer',marginRight:15}}>{printFiltersP()}</Typography>
    <ClearIcon onClick={ e => {
      filterPrioritaS('0')
      setCurrentFilter('0')
      }} style={{color:'#898989',cursor:'pointer'}}/>
  </Box>
  <Menu
    id="simple-menu"
    anchorEl={anchorElA}
    keepMounted
    open={Boolean(anchorElA)}
    onClose={handleCloseA}
    getContentAnchorEl={null}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    transformOrigin={{vertical: 'top', horizontal: 'center'}}
  >
    <MenuItem onClick={ e => {
      filterPrioritaS('1')
      setCurrentFilter('1')
    }}>Confermato</MenuItem>
    <MenuItem onClick={ e => {
      filterPrioritaS('2')
      setCurrentFilter('2')
    }}>Non Confermato</MenuItem>
    <MenuItem onClick={ e => {
      filterPrioritaS('0')
      setCurrentFilter('0')
    }}>Sospeso</MenuItem>
    </Menu>


  <Box style={{width:'4%'}}></Box>
</Box>


                </Container>
                <Container maxWidth="False" style={{padding:'0px'}}>
                    
                <InfoIntervento deleteFlag={false} type={type} flag={infoFlag} handleClose={e=> setInfoFlag(false)} target={infoTarget} cliente={targetClient}/>

{/* LOAD DIALOGS */}
<Container maxWidth="False" style={{padding:'0px',margin:0,width:'100%'}}>
  <Container maxWidth="False" className={classes.body} style={{padding:'0px'}}>
      { ticketList!== null && ticketList!== undefined ? (
      ticketList.map( item => {
        return (
          <Box className={classes.oneRow}>
            <Box className={classes.ticketColumn} style={{width:'4% !important'}}>
              {displayKind(item) === 'M. ' ? (
                <Box>
                  <img src={require('../../../assets/images/man.png')} className={classes.imgSTD}/>
              </Box>
              ) : (
                <Box>
                    <img src={require('../../../assets/images/ticket/INTERVENTO.png')} className={classes.imgSTD}/>
                </Box>
              )}
                
            </Box>
            <Box className={classes.ticketColumn}style={{width:'4%'}}>
                <Box>
                    <Typography variant={'h6'} className={classes.columnText} style={{color:displayDiffColor(displayKind(item)),fontWeight:'bold'}}>{displayKind(item) + item.id}</Typography>
                </Box>
            </Box>
            <Box className={classes.ticketColumn}style={{width:'5%'}}>
                <Box>
                    <Typography variant={'h6'} className={classes.columnText} style={{color:'#00ADA2',fontWeight:'bold'}}>{printClient(item.byContratto)}</Typography>
                </Box>
            </Box>
              
              
            <Box className={classes.ticketColumn} style={{width:'20%'}}>
                <Box >
                    <Typography variant={'subtitle1'} className={classes.columnText} style={{color:'#898989',fontWeight:'normal'}}>{printDesc(item)}</Typography>
                </Box>
            </Box>
            <Box className={classes.ticketColumn} style={{width:'5%'}}>
                <Box>
                    <Typography variant={'h6'} className={classes.columnText}>{item.dataInizio.split('T')[0]}</Typography>
                </Box>
            </Box>
            <Box className={classes.ticketColumn} style={{width:'5%'}}>
                <Box style={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}} >
                    <img src={showPriority(item.priorita)} className={classes.imgSTD} style={{margin:'0 auto'}}/>
                    <Typography variant={'overline'} className={classes.columnText} style={{color:showPriorityColor(displayType(item))}}>{displayType(item)}</Typography>
                </Box>
            </Box>
            <Box className={classes.ticketColumn} style={{width:'5%'}}>
                <Box style={{textAlign:'center'}}>
                    <WatchLaterIcon style={{fontSize:24,color:'#12BB9C'}}/>
                    <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold'}}>{showStatus(item.accepted)}</Typography>
                </Box>
            </Box>
            <Box className={classes.ticketColumn} style={{width:'15%'}}>
                <Box>
                    <Typography variant={'h6'} >Confermi l'attività?</Typography>
                    <Box style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                          <Box style={{display:'flex',margin:'auto 0'}}>
                              <Typography variant={'subtitle2'} style={{margin:'auto 0',color:'#00ada2'}}>Si</Typography>
                              <Radio
                                  checked={showChecked(item,'yes')}
                                  value="n"
                                  label="Si"
                                  style={{color:'dagreen'}}
                                  name="radio-button-demo"
                                  inputProps={{ 'aria-label': 'Si' }}
                                  onClick={ e=> {
                                      e.preventDefault();
                                      async function sendData(){
                                          let toUp={
                                              token:getUserData().Token,
                                              id:item.id,
                                              accepted:1
                                          }
                                          await updateAccepted(toUp)
                                          await delay(1000)
                                          window.location.reload(false);
                                      }
                                      sendData()
                                  } }
                              />
                          </Box>
                          <Box style={{display:'flex',margin:'auto 0'}}>
                              <Typography variant={'subtitle2'} style={{margin:'auto 0',color:'darkred'}}>No</Typography>
                              <Radio
                                  checked={showChecked(item,'no')}
                                  value={item.accepted}
                                  label="No"
                                  style={{color:'darkred'}}
                                  name="radio-button-demo"
                                  onClick={ e => {
                                      e.preventDefault();
                                      async function sendData(){
                                        let toUp
                                        if(e.target.value == 2){
                                          toUp={
                                            token:getUserData().Token,
                                            id:item.id,
                                            accepted:0
                                        }
                                        }else{
                                          toUp={
                                            token:getUserData().Token,
                                            id:item.id,
                                            accepted:2
                                        }
                                        }
                                          await updateAccepted(toUp)
                                          await delay(1000)
                                          window.location.reload(false);
                                      }
                                      sendData()
                                  } }
                                  inputProps={{ 'aria-label': 'No' }}
                              />
                          </Box>
                    </Box>
                     
                </Box>
            </Box>
            <Box className={classes.ticketColumn} style={{width:'4%'}}>
                <Box>
                    <IconButton className={classes.lightB} style={{width:30,height:30,backgroundColor:'#DBF1F5',borderRadius:7,marginRight:15}} onClick={ async e => {
                      e.preventDefault()
                      setInfoTarget(item)
                      await GetClienteFromProgramma('ddd',item.byContratto)
                      if(item.tipologia !== 'manutenzione'){
                        await getAssedById(getUserData().Token, item.byAsset)
                      }else{
                        await getActivityList( getUserData().Token,item.byContratto )
                      }
                      setType('cc')
                      await delay(500)
                      setInfoFlag(true)
                    } }>
                      <InfoIcon style={{color:'#65a1fd',fontSize:18}}/>
                    </IconButton>
                </Box>
            </Box>
          </Box>
        )
      } )
    ) : null }
   </Container>

</Container>

                </Container>

            </WrapperComponent>
    )
}

export default Programma
