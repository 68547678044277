import React, {useState, useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Sidebar from "../../components/Sidebar"
import { Box, Container, Typography } from "@material-ui/core"
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clientData from '../../data/clientData';
import { useHistory } from "react-router-dom";
import userData from '../../data/userData';

import MenuIcon from '@material-ui/icons/Menu';
import WrapperComponent from '../../components/WrapperComponent';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    height: 'auto',
    backgroundColor: 'white',
    padding: '10px 15px 10px 50px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:12
  },
  toolBox: {
    width: '15%',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  Header:{
    backgroundColor: "#00ADA2",
    height: 50,
    paddingTop:12
  },
  userForm:{
    marginTop:40
  },
  root: {
    backgroundColor:'white',
    color: "#6b6868",
    flexGrow: 1,
    flex:1,
    width:'98%',
    paddingBottom:20,
    borderRadius:4,
    fontFamily:"Nunito Sans",
  },
  boxLeft: {
    backgroundColor:'white',
    flexDirection:'row',
    flex:1
  },
  boxRight: {
    backgroundColor:'white',
    flexDirection:'row',
    flex:1
  },
  form:{
    flexDirection:'row',
    display:'flex',
    flex:1
  },
  formRow: {
    display:'flex',
    padding:10,
    paddingLeft:50,
    paddingTop:15,
    paddingBottom:15,
    alignItems:'center'
  },
  formRowName: {
    width:'30%',
    marginTop:15,
    fontSize:18,
    fontWeight:'bold'
  },
  formRowInput: {
    width:'60%'
  },
  button: {
    width:'25%',
    minWidth:250,
    backgroundColor:'#00ADA2',
    flexDirection:'row',
    justifyContent:'flex-end',
    fontSize:15,
    letterSpacing:'.15em',
    fontWeight:'bold',
    float:'right',
    marginTop:30,
    marginBottom:30
  },
  errorBasic: {
    color:'red',
    paddingLeft:15
  }
}))

const First=()=> {
  const history = useHistory();
  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
  }
  //F update
  let forceUpdate =  useForceUpdate()

  const classes = useStyles();

  const [ error, setError ] = useState(0)
  const [clientInfo,setClientInfo] = useState({
      ragSociale:'',
      estensioneRagSociale:'',
      nazionalita:'',
      localita:'',
      indirizzo:'',
      codicefiscale:'',
      codicepostale:'',
      pIva:''
    })
  
  //CHANGING STATE FUNCTION
  function handleChange(e,target) {
    e.preventDefault();
    let value = e.target.value;
    setClientInfo(prevState => ({
      ...prevState,
      [target]: value 
    }));
  }

  //Client Data
  const { setNewClientData, getNewClientData } = clientData()
  //REINTEGRATING VALUES IF COMPILED ON BACK BUTTON
  useEffect(() => {
        if( getNewClientData() !== null ){
            setClientInfo( getNewClientData() )
        }
  }, [])

  const { setDrawer } = userData()
  
  const wrapperConfig = {
    title:'Clienti',
    subTitle:'Questa è la tua clientela preferita, tienitela stretta!',
    activeMenu:2,
    menu:[
      {
        label:'Dati sociali dei clienti',
        active:true
      },
      {
        label:'Referenti Cliente'
      },
      {
        label:'Contatti Cliente'
      },
      {
        label:'Conferma e Aggiungi'
      }
    ],
    color:'#716ACA',
    darkColor:'#464280',
    options:[
      {
        label:'Lista clienti',
        onPress: () => history.push('/clienti'),
        enabled: true,
        active:false
      },
      {
        label:'Nuovo cliente',
        onPress: () => null,
        enabled: true,
        active:true
      }
    ],
    heading: <Container maxWidth="False" className={classes.toolBar}><Box style={{ display: 'flex', alignItems:'center',flexDirection:'row', }}>
    <img style={{ width: 40, marginRight: 40 }} src={require('../../../assets/images/anagraficaCliente/CLIENTE.png')} />
    <Typography variant='h6' component='h2' style={{ color: '#6A6A6A', margin: 'auto 0' }}>NUOVO CLIENTE</Typography>
  </Box></Container>
  }

  return (
      <WrapperComponent {...wrapperConfig} >
        
        <Container className={classes.root} maxWidth='False'>
            <Typography style={{padding:15,paddingTop:30,fontWeight:'bold',fontSize:25, letterSpacing:'.05em'}}>DATI SOCIALI DEL CLIENTE :</Typography>
          {error == 1 ? <Typography className={classes.errorBasic}>* Compila tutti i campi obbligatori</Typography> : null}
          <Grid style={{backgroundColor:'white',flex:1,height:'100%'}} container spacing={0}>

              <form className={classes.form} noValidate autoComplete="off">
                <Box className={classes.boxLeft}>

                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>Ragione Sociale</Typography>
                    <TextField value={clientInfo.ragSociale} onChange={(e) => handleChange(e,'ragSociale')} className={classes.formRowInput} id="name-input"  />
                  </div>
                    <div className={classes.formRow}>
                      <Typography className={classes.formRowName}>Estensione Ragione Sociale</Typography>
                      <TextField  value={clientInfo.estensioneRagSociale} onChange={(e) => handleChange(e,'estensioneRagSociale') } className={classes.formRowInput} id="standard-basic" />
                    </div>
                  
                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Nazionalità</Typography>
                    <TextField value={clientInfo.nazionalita} onChange={(e) => handleChange(e,'nazionalita')} className={classes.formRowInput} id="standard-basic" />
                  </div>
                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Località</Typography>
                    <TextField value={clientInfo.localita} onChange={(e) => handleChange(e,'localita')}  className={classes.formRowInput} id="standard-basic" />
                  </div>
                  </Box>

                <Box className={classes.boxRight}>
                <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Indirizzo</Typography>
                    <TextField value={clientInfo.indirizzo} onChange={(e) => handleChange(e,'indirizzo')}  className={classes.formRowInput} id="standard-basic" />
                  </div>
                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Codice Fiscale</Typography>
                    <TextField value={clientInfo.codicefiscale} onChange={(e) => handleChange(e,'codicefiscale')} className={classes.formRowInput} id="standard-basic"  />
                  </div>
                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Codice Postale</Typography>
                    <TextField value={clientInfo.codicepostale} onChange={(e) => handleChange(e,'codicepostale')}  className={classes.formRowInput} id="standard-basic"  />
                  </div>
                  <div className={classes.formRow}>
                    <Typography className={classes.formRowName}>* Partita Iva</Typography>
                    <TextField value={clientInfo.pIva} onChange={(e) => handleChange(e,'pIva')} className={classes.formRowInput} id="standard-basic"  />
                  </div>
                
                  <div style={{flexDirection:'row',justifyContent:'flex-end', minWidth:'150px'}}>
                      <Button
                      variant="contained"
                      color="primary"
                      fullWidth={false}
                      className={classes.button}
                      endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                      onClick={ async e=> {
                          e.preventDefault();
                          if(clientInfo.nazionalita && clientInfo.localita && clientInfo.indirizzo && clientInfo.codicefiscale && clientInfo.codicepostale && clientInfo.pIva){
                            await setNewClientData(clientInfo)
                            history.push('/clienti/contattiReferente')
                          }else{
                            setError(1)
                          }
                          
                      } }
                      >
                      Salva e Continua
                    </Button>
                  {console.log(error)}
                  </div>
                </Box>
              

              </form>
          </Grid>

        </Container>
      </WrapperComponent>
  )
}

export default First
