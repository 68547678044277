import { Box, Typography, Button } from '@material-ui/core'
import React, { useState,useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import userData from '../../data/userData';
import webservice from '../../../api/webservice';
import anagraficaData from '../../data/anagraficaData';
import { PDFDownloadLink, Document, Page,View,Text,Font } from '@react-pdf/renderer'
import contractData from '../../data/contractData';
import DownloadRep from '../../ticket/DownloadRep';

const useStyles = makeStyles((theme) => ({
    imgSTD:{
        width:32,
        margin:'0 auto'
    },
    mainBox:{
        paddingLeft:20,
        width:'20%',
        marginTop:'1%',
        marginBottom:'.5%',
    },
    mainText:{
        color:'#535353',
        fontWeight:'bold',
        marginBottom:15,
        fontSize:'0.7vw'
    },
    subText:{
        color:'#B5B5B5',
        fontSize:'0.6vw'
    },
    topSideColumn:{
        marginTop:20,
        marginBottom:10
    },
    infoText:{
        marginTop:5,
        color:'#4D8AFF',
        fontSize:'0.7vw'
    },
    bottomInfoMAIN:{
        fontWeight:'bold',
        color:'#535353'
    },
    bottomSide:{
        paddingTop:15,
        paddingBottom:15,
        borderTopWidth:2,
        borderBottomWidth:2,
        borderTopStyle:'solid',
        borderBottomStyle:'solid',
        borderTopColor:'#e8e8e8',
        borderBottomColor:'#e8e8e8',
        display:'flex',
        justifyContent:'space-around',
        marginTop:'10%'
    }
}))

const LastReport = ({dataReport}) => {

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [available,setAvailable] = React.useState(0)
    const { GetFullInterventoList, getContractList, getUserList, userList, getClientList, getReport,getFullProgrammaReq, createPDF  } = webservice()
    const { getRR,getContractListData, getNewContractClientList } = contractData()

    const { getAnagraficaData, getInterventoListData, getCurrentReport } = anagraficaData()

    function useForceUpdate(){
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => ++value); // update the state to force render
      }
      const forceUpdate = useForceUpdate();

    React.useEffect(() => {
        async function initialData(){
            await GetFullInterventoList('dd')
            await getContractList('ddd')
            await getUserList('dd')
            await getClientList('dd')
            await getReport('dd')
            await getFullProgrammaReq('dd')
            await delay(1000)
        }
        initialData()
    }, [])

    function sendClient(id){
        let exp = []
        getContractListData().map( cc => {
          
          if(cc.id == id){
            getNewContractClientList().map( user => {
              if(user.id == cc.cliente){
                exp = user
              }
            } )
          }
        } )
        return exp
        console.log('dasdasdas',exp)
      }

    const classes = useStyles();

    function swipeDate(date){
        let temp = date.split('-')
        return ( temp[2] + '-' + temp[1] + '-' + temp[0] )
    }

    //Generate doc
    const MyDoc = ({data}) => {
            return (
                <>
                    <Document>
                        <Page className={classes.bodyDOC} style={{paddingTop: 35,
                        paddingBottom: 65,
                        paddingHorizontal: 35,}}>
                        <Text style={classes.titleDOC} style={{fontSize: 24,
                        textAlign: 'center',
                        }}>Report intervento manutenzione {data.tipologia}</Text>
                        <Text style={classes.authorDOC} style={{fontSize: 12,
                        textAlign: 'center',
                        marginBottom: 40,}}>INT. {data.id}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Data di creazione: 
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.created !== undefined ? swipeDate((data.created).split('T')[0]) : null}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Data di Inizio intervento: 
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.dataInizio !== undefined ? swipeDate((data.dataInizio).split('T')[0]) : null}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Data di Fine intervento: 
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.dataFine !== undefined ? swipeDate((data.dataFine).split('T')[0]) : null}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Descrizione Intervento: 
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.descrizione}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Priorità
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.priorita}</Text>
                        <Text style={classes.subtitleDOC} style={{
                            fontSize: 18,
                        margin: 12,
                        marginBottom:5,
                        
                        }}>
                            Intervento assegnato a:
                        </Text>
                        <Text style={classes.textDOC} style={{
                            margin: 0,
                        marginLeft:14,
                        fontSize: 14,
                        textAlign: 'justify',
                        
                        }}>{data.ragSociale}</Text>
                        
                        </Page>
                    </Document>
                </>
            )
            
    }

    function printKinds(ID){
        let item = getInterventoListData().find(obj => obj.id == ID)
        console.log('TROVATOOOO', item)
        if(item){
            if(item.tipologia === 'manutenzione'){
                return 'M'
            }else {
                return 'I'
            }
        }
    }

    const [Anom,setAnom] = React.useState([])
    const [Addr,setAddr] = React.useState('')
    const [Mate,setMate] = React.useState([])
    const [Manu,setManu] = React.useState([])
    const [cClient,setClient] = React.useState([])
    const [INT,setInt] = React.useState([])
    const [progAct,setProgAct] = React.useState([])
    const [desc,setDesc] = React.useState([])
    const [progToShow,setProgToShow] = React.useState({})
    const [nCliente,setNCliente] = React.useState("")
    const [signatureCliente,setSignatureCliente] = React.useState([])
    const [signatureTecnico,setSignatureTecnico] = React.useState([])
    const [downloader,setDownloader] = React.useState(false)

    return(
        <>
        <DownloadRep progToShow={progToShow} nCliente={nCliente} desc={desc} int={INT} client={cClient} anom={Anom} mate={Mate} manu={Manu} open={downloader} signatureCliente={signatureCliente} signatureTecnico={signatureTecnico} handleClose={e=> setDownloader(false)} />
        {console.log('rep',dataReport)}
        <Box className={classes.mainBox}>
            <Box className={classes.topSide}>
                <Typography variant='h5' className={classes.mainText}>ULTIMO REPORT</Typography>
                <Box className={classes.topSideColumn}>
                    <Typography variant='h7' className={classes.subText}>Tempo di Intervento</Typography>
                    <Typography variant='h5' className={classes.infoText}>{dataReport.tempi === undefined ? 'Non definito' : dataReport.tempi + ' Ore'}</Typography>
                </Box>
                <Box className={classes.topSideColumn}>
                    <Typography variant='h7' className={classes.subText}>Anomalie riscontrate negli Asset</Typography>
                    <Typography variant='h5' className={classes.infoText} style={{color:'red'}}>{dataReport.interventi === null ? '' : '0'}</Typography>
                </Box>
            </Box>
            <Box className={classes.bottomSide}>
                <Box style={{textAlign:'center',width:'49.5%',borderRightColor:'#e8e8e8',borderRightWidth:2,borderRightStyle:'solid'}}>
                    <Typography className={classes.bottomInfoMAIN} variant='overline' >ID</Typography>
                    <Typography variant='h5' className={classes.infoText} style={{color:'#0CA589'}}>{printKinds(dataReport.idIntervento)}. {dataReport.idIntervento}</Typography>
                </Box>

                <Box style={{textAlign:'center',width:'49.5%'}}>
                    <Typography className={classes.bottomInfoMAIN} variant='overline' >Creato il</Typography>
                    <Typography variant='h5' className={classes.infoText} style={{color:'#B5B5B5'}}>{ dataReport.dataInizio!== undefined ? swipeDate((dataReport.dataInizio).split('T')[0]) : null }</Typography>
                
                </Box>
            </Box>
            {console.log(dataReport)}
            {dataReport.id !== undefined ? (<Box style={{marginTop:5,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>

                <img className={classes.imgSTD} src={require('../../../assets/images/reportManutenzione/interventoStraordinario.png')} />
                <Button onClick={(e) => {
                    e.preventDefault()
                    let tmp = getRR().filter(obj => obj.byProg == dataReport.id)
                          if(tmp){
                            let reps = tmp.map(obj => obj.id)
                            reps = reps.join(';')
                            createPDF(reps).then(res => {
                              window.open('https://itp.softwellitalia.it' + res,'_blank')
                            })
                          }
                    }} >Scarica Report</Button>
            </Box>) : null}
        </Box>
        </>
    )
}

export default LastReport
