import React from 'react'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes  } from "@material-ui/core/styles"
import { Box, Typography } from '@material-ui/core';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import anagraficaData from '../../data/anagraficaData';

const useStyles = makeStyles((theme) => ({
    partTitle:{
        paddingLeft:15,
        paddingTop:15,
        fontWeight:'bold',
        fontSize:25,
        letterSpacing:'.05em',
        color:'#535353',
        fontSize:'1vw',
    },
    mainTicket:{
        display:'flex',
        flexDirection:'column',
        margin:'5% 0'
    },
    ticketMainImage:{
        width:'1vw',
        height:'1vw'
    },
    ticketRow:{
        marginBottom:'5%',
        display:'flex',
        alignItems:'center',
        paddingBottom:5,
        borderBottom:'2px solid #f1f1f1'
    },
    ticketDesc:{
        width:'40%'
    },
    ticketID:{
        color:'#0CA589',
        fontSize:'0.8vw',
        fontWeight:'bold'
    },
    ticketDescription:{
        fontSize:'0.6vw',
        color:'#B5B5B5'
    },
    ticketDate:{
        color:'#535353',
        letterSpacing:'.1em',
        fontSize:'.55vw'
    },
    leftIconTicket:{
        display:'flex',
        alignItems:'center',
        width:'auto',
        paddingLeft:15,
        paddingRight:15
    }
}))

const Ticket = ({contrID,data, progList}) => {
    const classes = useStyles();

    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);
    const { getAnagraficaData } = anagraficaData()

    function swipeDate(date){
        let temp = date.split('-')
        return ( temp[2] + '-' + temp[1] + '-' + temp[0] )
}
    function findData(internalData){
        let fin = []
        progList.map(prog => {
            internalData.map(int => {
                if(prog.stato !== 'malattia' && prog.stato !== 'permessi' && prog.stato !== 'ferie'){
                    if(prog.byContratto == contrID && prog.idIntervento == int.id){
                        fin.push(int)
                    }
                }
            })
        })
        return fin
    }

    function displayKind(type){
        if(type === 'manutenzione'){
            return 'MAN'
        }else{
            return 'INT'
        }
    }

    function urgenzaIMG(type){
        switch(type){
            case 'urgente':
                return (require('../../../assets/images/anagraficaContratto/ticket/pUrgente.png'))
                break;
            case 'medio':
                return (require('../../../assets/images/anagraficaContratto/ticket/pMedia.png'))
                break;
            case 'emergenza':
                return (require('../../../assets/images/anagraficaContratto/ticket/pEmergenza.png'))
                break;
            case 'basso':
                return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
                break;
            case 'bassa':
                return (require('../../../assets/images/anagraficaContratto/ticket/pBassa.png'))
                break;
        }
    }

    function displayStatus(id){
        if(id === undefined || id === null) return
        let typeLocal = progList.find(obj => obj.idIntervento == id)
        if(typeLocal === undefined) return
        switch(typeLocal.stato){
          case 'attivo':
            return <>
                <Typography style={{fontSize:16,color:'white',background:'#397BD3',borderRadius:10,margin:'0 auto',padding:'4px 5px',width:'2rem'}}>A</Typography>
                <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#397BD3'}}>Aperto</Typography>
                </>
          case 'lavorazione':
            return <>
                <Typography style={{fontSize:16,color:'white',background:'#EB9427',borderRadius:10,margin:'0 auto',padding:'4px 5px',width:'2rem'}}>L</Typography>
                <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#EB9427'}}>Lavorazione</Typography>
                </>
          case 'finito':
            return <>
                <Typography style={{fontSize:16,color:'white',background:'#0A9EA3',borderRadius:10,margin:'0 auto',padding:'4px 5px',width:'2rem'}}>F</Typography>
                <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#0A9EA3'}}>Finito</Typography>
                </>
          case 'sospeso':
            return <>
                <Typography style={{fontSize:16,color:'white',background:'#C09B6E',borderRadius:10,margin:'0 auto',padding:'4px 5px',width:'2rem'}}>S</Typography>
                <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#C09B6E'}}>Sospeso</Typography>
                </>
          case 'cancellato':
            return <>
                <Typography style={{fontSize:16,color:'white',background:'#E71C28',borderRadius:10,margin:'0 auto',padding:'4px 5px',width:'2rem'}}>C</Typography>
                <Typography style={{fontSize:12,color:'#12BB9C',fontWeight:'bold',color:'#E71C28'}}>Cancellato</Typography>
                </>
        }
      }

    return (
            <Box style={{backgroundColor:'white',width:'30vw',paddingLeft:15}}>
                <Typography className={classes.partTitle} >TICKET APERTI</Typography>
                <Box className={classes.mainTicket}>
                    {data !== null && data !== undefined ? 
                    (findData(data)).slice(0,3).map( item => {
                        console.log('IIIII', item, progList)
                        return (
                            <Box className={classes.ticketRow}>
                                <Box style={{display:'flex',width:'100%'}}>
                                    <Box style={{display:'flex',width:'30%',alignItems:'center'}}>
                                        <Typography className={classes.ticketDate}>{swipeDate((item.created).split('T')[0])} </Typography>
                                        <div style={{marginLeft:5,marginRight:5,width:'30px',backgroundColor:'#535353',height:1}}></div>
                                        <img className={classes.ticketMainImage}src={require('../../../assets/images/anagraficaContratto/ticket/INTERVENTO.png')} />
                                    </Box>
                                    <Box className={classes.ticketDesc}>
                                        <Typography className={classes.ticketID}>{displayKind(item.tipologia)}.{item.id}</Typography>
                                        <Typography className={classes.ticketDescription}>{(item.descrizione).slice(0,90) + '...'}</Typography>
                                    </Box>
                                    <Box className={classes.leftIconTicket}>
                                        <Box style={{textAlign:'center',width:'4 rem',alignItems:'center'}}>
                                            {displayStatus(item.id)}
                                        </Box>
                                        
                                    </Box>
                                </Box>
                                <Box>

                                </Box>
                            </Box>
                        )
                    } ) : null}
                </Box>
            </Box>
    )
}

export default Ticket
