import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import userData from '../data/userData'
import { useHistory } from 'react-router';

const WrapperComponent = ({children,title,subTitle,color = '#333',darkColor = '#000',menu = [], options = [],heading = <></>,activeMenu = 1}) => {
    const classes = useStyles();
    const { getUserData, getCurrentPermission } = userData()
    const history = useHistory()

    const menuObj = [
        {
            label:'Dashboard',
            imgUrl:require('../../assets/images/anagraficaCliente/CLIENTE.png'),
            onClick: () => history.push('/dashboard'),
            enabled:true,
            active:1
        },
        {
            label:'Clienti',
            imgUrl:require('../../assets/images/anagraficaCliente/CLIENTE.png'),
            onClick: () => history.push('/clienti'),
            enabled:getCurrentPermission() !== null && getCurrentPermission().listaClienti === true,
            active:2
        },
        {
            label:'Contratti',
            imgUrl:require('../../assets/images/lista-utenti/CONTRATTO.png'),
            onClick: () => history.push('/contratti/listaContratti'),
            enabled:getCurrentPermission() !== null && getCurrentPermission().listaContratto === true,
            active:3
        },
        {
            label:'Ticket',
            imgUrl:require('../../assets/images/anagraficaContratto/anagraficaSituazione/ticketTotali.png'),
            onClick: () => history.push('/ticket'),
            enabled:getCurrentPermission() !== null && getCurrentPermission().listaTicket === true,
            active:4
        },
        {
            label:'Utenti',
            imgUrl:require('../../assets/images/UTENTI.png'),
            onClick: () => history.push('/dashboard'),
            enabled:getUserData()!==null && getUserData().Tipologia !== 'Utente' && getUserData().Tipologia !== 'Cliente',
            active:5
        }
    ]

    return (
        <div style={{width:'100%',height:'100%'}}>
            <div className={classes.wrapper}>
                <div style={{height:54,justifyContent:'center',marginBottom:5,marginTop:5}}>
                    <img style={{ width: 54, height:54, marginRight: 40 }} src={require('../../assets/images/logo-icon.png')} />
                </div>
                <div style={{height:54,justifyContent:'center',alignItems:'center',marginBottom:5,marginTop:5,display:'flex'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <p style={{fontSize:14}}>Ciao,{" "}</p>
                        <p style={{fontWeight:'bold',fontSize:14}}>{getUserData() !== undefined ? getUserData().Nome : ''}</p>
                        <div style={{width:42,height:42,borderRadius:22,backgroundColor:'#00ADA2',color:'white',alignItems:'center',justifyContent:'center',display:'flex',marginLeft:8}}>{getUserData() !== undefined ? getUserData().Nome.slice(0,1) : ''}</div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',width:'10vw',alignItems:'center',cursor:'pointer'}} onClick={() => {
                        localStorage.clear()
                        history.push('/')
                    }}>
                        <p style={{fontSize:14,marginRight:14,color:'#737373'}}>Logout</p>
                        <img style={{ width: 14, height:14}} src={require('../../assets/images/Logout.png')} />
                    </div>
                </div>
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.bodySidebar}>
                    {menuObj.filter(obj => obj.enabled).map((currentRow, index) => {
                        return (
                            <div key={index} className={activeMenu === currentRow.active ? classes.menuBoxActive : classes.menuBox} onClick={() => {
                                if(activeMenu !== currentRow.active){
                                    currentRow.onClick()
                                }
                            }}>
                                <img src={currentRow.imgUrl} className={classes.menuBoxImage} />
                                <p className={classes.menuBoxLabel}>{currentRow.label}</p>
                            </div>
                        )
                    })}
                </div>
                <div className={classes.bodyContent}>
                    <div className={classes.bodyHeader}>
                        <p style={{color:'#00ADA2', fontSize:24,marginBottom:-8}}>{title}</p>
                        <p style={{color:'#898989', fontSize:13}}>{subTitle}</p>
                    </div>
                    {options.length > 0 && (
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:24,marginBottom:36}}>
                            {options.map(currentOption => {
                                return currentOption.enabled ? (
                                        <div className={classes.optionContainer} onClick={currentOption.onPress}>
                                        <p className={!currentOption.active ? classes.optionLabel : classes.optionLabelActive}>{currentOption.label}</p>
                                        <div className={classes.optionLabelBar}/>
                                    </div>
                                    ) : <></>
                            })}
                        </div>
                    )}
                    {heading}
                    {menu.length > 0 && (
                        <div className={classes.subBodyWrapper}>
                            <div className={classes.subBodyMenu}>
                                {menu.map((current, index) => {
                                    return (
                                        <div className={classes.menuRow} key={current.label + index} style={{backgroundColor:current.active ? color : 'white'}}>
                                            <div className={classes.menuRowIndex} style={{color:current.active ? 'white' : '#898989', backgroundColor:current.active ? darkColor : 'white'}}>{index + 1}</div>
                                            <p className={classes.menuRowText} style={{color:current.active ? 'white' : '#898989'}}>{current.label}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={classes.subBodyContent}>
                                {children}
                            </div>
                        </div>
                    )}
                    {menu.length === 0 && children}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background:'#e7e7e6',
        padding:0,
        margin:0,
        height: 64,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: 'center'
    },
    bodyContainer: {
        width:'100%',
        display:'flex',
        height:'100%',
        justifyContent:'space-between'
    },
    bodySidebar: {
        width:'10%',
        minWidth:64,
        backgroundColor:'white',
        display:'flex',
        padding:'42px 0px',
        flexDirection:'column',
        alignItems:'center',
        marginRight:32
    },
    menuBox: {
        cursor:'pointer',
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        marginBottom:32,
        width:'97%',
        paddingTop:12,
        paddingBottom:8,
    },
    menuBoxActive: {
        borderRight:'5px solid #32aea2',
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        marginBottom:32,
        width:'97%',
        paddingTop:12,
        paddingBottom:8,
    },
    menuBoxLabel: {
        lineHeight:'0px',
        fontSize:15,
        color:'#898989'
    },
    menuBoxImage: {
        width:32,
        height:32
    },
    bodyContent:{
        width:'100%',
        padding:16
    },
    subBodyWrapper: {
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    subBodyMenu: {
        padding:32,
        backgroundColor:'white',
        width:'20%',
        height:'100%'
    },
    subBodyContent: {
        width:'78%',
        height:'100%'
    },
    menuRow: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        paddingRight:32,
        height:48,
        borderRadius:24,
        marginBottom:16
    },
    menuRowIndex: {
        height:48,
        width:48,
        borderRadius:24,
        backgroundColor:'#efefef',
        color:'#898989',
        fontSize:20,
        marginRight:16,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    },
    menuRowText: {
        color:'#898989',
        fontSize:14
    },
    optionContainer: {
        marginRight:32
    },
    optionLabel: {
        fontSize:18,
        lineHeight:'18px',
        padding:0,
        margin:0,
        color:'#898989',
        cursor:'pointer'
    },
    optionLabelActive: {
        fontSize:18,
        lineHeight:'18px',
        padding:0,
        margin:0,
        color:'#32aea2',
        cursor:'pointer'
    },
    optionLabelBar: {
        height:2,
        width:36,
        background:'#32aea2'
    }
}))

export default WrapperComponent