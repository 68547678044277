import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import webservice from '../../../api/webservice';

const EditProgramma = props => {
  //States
  const [editedData,setEditedData] = React.useState({})
  const { editProgramma } = webservice()

  React.useEffect(() => {
    if(props.data !==undefined){
      setEditedData({
        description:props.desc,
        tempi:props.data.tempi,
        dataInizio:props.data.dataInizio,
        dataFine:props.data.dataFine,
        id:props.data.id,
        idIntervento:props.data.idIntervento
      })
    }
  }, [props])

  function handleInputChange(target,value){
    setEditedData(prevState => ({
      ...prevState,
      [target]:value
    }))
  }

  async function handleConfirm(){
    console.log(editedData)
    await editProgramma(editedData).then(res => {
      console.log(res)
      window.location.reload(false)
    })
  }

  console.log('Edit data form', editedData)
  return (
    <>
      <Dialog open={props.visible} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modifica programma</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Nei campi sottostanti potrai effettuare una modifica ai parametri modificabili.
            NB: Alcuni campi non possono essere modificati, si consiglia di eliminare e ricreare il programma.
          </DialogContentText>
            <TextField
              margin="dense"
              id="description"
              label="Descrizione"
              style={{
                marginBottom:'.5rem'
              }}
              multiline
              rows={6}
              variant="outlined"
              value={editedData.description}
              type="text"
              fullWidth
              onChange={e => {
                e.preventDefault()
                  handleInputChange('description',e.target.value)
              }}
            />
            <TextField
              margin="dense"
              id="workingHours"
              style={{
                marginBottom:'.5rem'
              }}
              label="Ore di lavoro"
              value={editedData.tempi}
              type="text"
              fullWidth
              onChange={e => {
                e.preventDefault()
                  handleInputChange('tempi',e.target.value)
              }}
            />
            <TextField
              id="date"
              style={{
                marginBottom:'.5rem'
              }}
              label="Data inizio"
              type="date"
              fullWidth
              defaultValue={editedData.dataInizio}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => {
                e.preventDefault()
                  handleInputChange('dataInizio',e.target.value)
              }}
            />
            <TextField
              id="date"
              style={{
                marginBottom:'.5rem'
              }}
              label="Data fine"
              type="date"
              fullWidth
              defaultValue={editedData.dataFine}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => {
                e.preventDefault()
                  handleInputChange('dataFine',e.target.value)
              }}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose()} color="primary">
            Cancella
          </Button>
          <Button onClick={() => handleConfirm()} color="primary">
            Conferma Modifiche
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditProgramma