import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Menu, MenuItem,Select as SelectNV } from '@material-ui/core';
import Select from 'react-select'
import { makeStyles } from "@material-ui/core/styles"
import userData from '../../data/userData';
import webservice from '../../../api/webservice';
import anagraficaData from '../../data/anagraficaData';

const useStyles = makeStyles((theme) => ({
    defaultSelectBox:{
        marginBottom:40,
    },
    button:{
        '&:hover': {
            background:'transparent !important'
        }
    }
}))

const EditActivity = ({toShow,item,handleClose,handleSuccess,types}) => {


    const { getUserData } = userData()
    const { updateActivity,getTipologieIntervento, createIntervento, createActivity, deleteActivity,getActivityList } = webservice()
    const { getTipoI,getAnagraficaData, getActivityListData } = anagraficaData()
    const classes = useStyles();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    //Activity Data
    const [data,setData] = useState({
        tipologia:null,
        descrizione:null,
        periodo:null
    })

    function handleChange(e,target) {
        let val = e.target.value
        setData(prevState => ({
            ...prevState,
            [target]:val
        }))
    }
    const [cType,setCType] = React.useState([])

    useEffect(() => {
        async function initialData(){
            await getTipologieIntervento('dd')
            var typDe = getAnagraficaData().tipologia
            if(typDe.split(',').length > 1){
                typDe = typDe.split(',')[0]
            }
            let fin = []
            getTipoI().map( type => {
                console.log(typDe,type.byTipologia)
                switch(typDe){
                    
                    case 'Sicurezza':
                        if(type.bytipologia == 3){
                            fin.push(type)
                        }
                        break;
                    case 'Tecnologico':
                        if(type.bytipologia == 4){
                            fin.push(type)
                        }
                        break;
                    case 'Elettrico':
                        if(type.bytipologia == 2){
                            fin.push(type)
                        }
                        break;
                    case 'Antincendio':
                        if(type.bytipologia == 1){
                            fin.push(type)
                        }
                        break;

                }
                setCType(fin)
            } )
        }
        initialData()
    }, [])

    function monthDiff(startDate, endDate) {
        var start      = startDate.split('-');
        var end        = endDate.split('-');
        var startYear  = parseInt(start[0]);
        var endYear    = parseInt(end[0]);
        var dates      = [];

        for(var i = startYear; i <= endYear; i++) {
            var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1])-1 : 0;
            for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            dates.push([i, displayMonth, '01'].join('-'));
            }
        }
        console.log(dates)
        return dates;
    }
    function toDateTime(date,action){
       
        var now = new Date()
        if(action === 0){
            return ( date + ' ' + now.getHours() + ':' + now.getMinutes() )
        }else if(action === 1){
            if(now.getHours() + date > 24){
                return( now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + (now.getDate() + 1) + ' ' + ((now.getHours() + date) -24 ) + ':' + now.getMinutes() )
            }else{
                return( now.getFullYear() + '-' + (now.getMonth() + 1 )+ '-' + now.getDate() + ' ' + (now.getHours() + date) + ':' + now.getMinutes() )
            }
        }else{
            return ( date + ' ' + '00:00')
        }
        
    }
    const [monthToLoad,setMonthToLoad] = React.useState([])

    function printSelectMonths(dates){
        function printName(num){
            switch(num){
                case '01':
                    return 'Gennaio';
                case '02':
                    return 'Febbraio';
                case '03':
                    return 'Marzo';
                case '04':
                    return 'Aprile';
                case '05':
                    return 'Maggio';
                case '06':
                    return 'Giugno';
                case '07':
                    return 'Luglio';
                case '08':
                    return 'Agosto';
                case '09':
                    return 'Settembre';
                case '10':
                    return 'Ottobre';
                case '11':
                    return 'Novembre';
                case '12':
                    return 'Dicembre';
            }
        }
        let fin = []

        dates.map( item => {
            let tmp = item.split('-')
            let tmpToUp = {
                value:item,
                label:printName(tmp[1])
            }
            fin.push(tmpToUp)
            //<MenuItem value={item}>{printName(tmp[1]) + ' - ' + tmp[0]}</MenuItem>
        })
        let tmpVal = []
        if(item !== undefined && item.length > 0){
            console.log(item)
            
            item.map(tmp => {
                let tt = fin.filter( temp => temp.value === tmp.periodo)[0]
                tmpVal.push(tt)
            })

        }
        tmpVal.sort(function(a, b) {
            var dateA = new Date(a.value), dateB = new Date(b.value);
            return dateA - dateB;
        })
        
        return (
            <Select
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                value={monthToLoad.length > 0 ? monthToLoad : tmpVal}
                name="color"
                isMulti={true}
                options={fin}
                onChange={ async itemS =>{
                    function comparer(otherArray){
                        return function(current){
                          return otherArray.filter(function(other){
                            return other.value == current.value && other.display == current.display
                          }).length == 0;
                        }
                      }
                      if(monthToLoad.length > 0){
                        var onlyInA = itemS.filter(comparer(monthToLoad));
                        var onlyInB = monthToLoad.filter(comparer(itemS));
                        let toRem = item.filter(cItem => cItem.periodo === onlyInA.concat(onlyInB)[0].value)
                        if(toRem[0] !== undefined){
                            let toRemD = {
                                token: getUserData().Token,
                                id:toRem[0].id
                              }
                              await deleteActivity(toRemD)
                              await delay(800)
                        }
                        
                      }else{
                        var onlyInA = itemS.filter(comparer(tmpVal));
                        var onlyInB = tmpVal.filter(comparer(itemS));
                        let toRem = item.filter(cItem => cItem.periodo === onlyInA.concat(onlyInB)[0].value)
                        if(toRem[0] !== undefined && toRem.length > 0){
                            let toRemD = {
                                token: getUserData().Token,
                                id:toRem[0].id
                              }
                              await deleteActivity(toRemD)
                              await delay(800)
                        }
                        
                      }
                     
                    setMonthToLoad(itemS)
                    
                } }
                />
            
        )
    }

    return (
        <>
            <Dialog open={toShow} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">MODIFICA</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Modifica dell'asset di tipologia {item.tipologia}
                </DialogContentText>
                <SelectNV
                        className={classes.defaultSelectBox}
                        labelId="selectTipologia"
                        id="selectTipologia"
                        value={data.tipologia === null && item[0] !== undefined ? item[0].tipologia : data.tipologia}
                        fullWidth
                        onChange={(e) => handleChange(e,'tipologia')}
                        label="Tipologia"
                        >
                        {types.map(type => {
                                        return (
                                            <MenuItem value={type.Nome}>{type.Nome}</MenuItem>
                                        )
                                    })}
                    </SelectNV>
                <TextField
                        id="outline-multiline-static"
                        label="Descrizione dell'attività"
                        multiline
                        className={classes.defaultSelectBox}
                        rowsMax={8}
                        height={64}
                        fullWidth
                        rows={1}
                        value={data.descrizione === null && item[0] !== undefined ? item[0].descrizione : data.descrizione}
                        placeholder="Inserisci la descrizione compelta dell'attività da svolgere"
                        onChange={e => handleChange(e,'descrizione')}
                    />
                { printSelectMonths(monthDiff(getAnagraficaData().dataInizio.split('T')[0],getAnagraficaData().dataFine.split('T')[0])) }
                </DialogContent>
                <DialogActions>
                <Button className={classes.button} onClick={handleClose} color="primary">
                    Annulla
                </Button>
                <Button className={classes.button} onClick={async e => {
                    let toUp = item
                    toUp.tipologia = data.tipologia !== null ? data.tipologia : item[0].tipologia
                    toUp.periodo = data.periodo !== null ? data.periodo : item.periodo
                    toUp.descrizione = data.descrizione !== null ? data.descrizione : item[0].descrizione
                    toUp.token = getUserData().Token
                    let toUpInt = {
                        token:getUserData().Token,
                        tipologia:'manutenzione',
                        tempi:toDateTime(data.periodo !== null ? data.periodo : item.periodo,3),
                        dataInizio:toDateTime(data.periodo !== null ? data.periodo : item.periodo,3),
                        dataFine:toDateTime(data.periodo !== null ? data.periodo : item.periodo,3),
                        priorita:'manutenzione',
                        ragSociale:null,
                        byContratto:getAnagraficaData().id,
                        descrizione:data.descrizione !== null ? data.descrizione : item[0].descrizione,
                        previsioneTermine:null,
                        created:toDateTime(0,1),
                        byAsset:0,
                        accepted:1,
                    }
                    let exister = false
                    if(getActivityListData() !== null){
                        let tmpRes = getActivityListData().filter( e => e.periodo == toUp.periodo )
                        if(tmpRes.length > 0){
                            exister = true
                        }
                    }
                    if(exister === false){
                        //await createIntervento(toUpInt)
                    }
                    if(monthToLoad.length > 0){
                        console.log(monthToLoad)
                        monthToLoad.map( async cMonth => {
                            let verifier = item.filter(cAct => cAct.periodo === cMonth.value)
                            if(verifier.length > 0){
                                let toUpUpdater = verifier[0]
                                    toUpUpdater.tipologia = data.tipologia !== null ? data.tipologia : item[0].tipologia
                                    toUpUpdater.periodo = cMonth.value
                                    toUpUpdater.descrizione = data.descrizione !== null ? data.descrizione : item[0].descrizione
                                    toUpUpdater.token = getUserData().Token
                                    await updateActivity(toUpUpdater)
                                    await delay(500)
                            }else{
                                let tmpRes = []
                                let exister = false
                                if(getActivityListData() !== null){
                                    tmpRes = getActivityListData().filter( e => e.periodo == cMonth.value )
                                    if(tmpRes.length > 0){
                                        exister = true
                                    }
                                }
                                if(exister === true){
                                    console.log(tmpRes)
                                    //NEW MONTH EXISTING INT
                                    console.log('ALREADYEXISTING')
                                    let toUp = {
                                        token:getUserData().Token,
                                        tipologia : data.tipologia !== null ? data.tipologia : item[0].tipologia,
                                        periodo : cMonth.value,
                                        descrizione : data.descrizione !== null ? data.descrizione : item[0].descrizione,
                                        numContratto : getAnagraficaData().numero
                                    }
                                    await createActivity(toUp.token,toUp.tipologia,toUp.periodo,toUp.descrizione,toUp.numContratto)
                                    await delay(500)
                                    //console.log(toUp)
                                }
                                else{
                                    //NEW MONTH NEW INT
                                    let toUp = {
                                        token:getUserData().Token,
                                        tipologia : data.tipologia !== null ? data.tipologia : item[0].tipologia,
                                        periodo : cMonth.value,
                                        descrizione : data.descrizione !== null ? data.descrizione : item[0].descrizione,
                                        numContratto : getAnagraficaData().numero
                                    }
                                    let toUpInT = {
                                        token:getUserData().Token,
                                        tipologia:'manutenzione',
                                        tempi:toDateTime(cMonth.value,3),
                                        dataInizio:toDateTime(cMonth.value,3),
                                        dataFine:toDateTime(cMonth.value,3),
                                        priorita:'manutenzione',
                                        ragSociale:null,
                                        byContratto:getAnagraficaData().id,
                                        descrizione:data.descrizione !== null ? data.descrizione : item[0].descrizione,
                                        previsioneTermine:null,
                                        created:toDateTime(0,1),
                                        byAsset:0,
                                        accepted:1,
                                        indirizzo:getAnagraficaData().indirizzo + ', ' + getAnagraficaData().cap + ' - ' + getAnagraficaData().localita
                                    
                                    }
                                    await createActivity(toUp.token,toUp.tipologia,toUp.periodo,toUp.descrizione,toUp.numContratto)
                                    await delay(500)
                                    console.log(toUpInT)
                                    await createIntervento(toUpInt)
                                }
                                
                            }
                        } )
                    }else{

                    }
                   
                    await delay(500)
                    await getActivityList('dd',getAnagraficaData().id)
                    await delay(600)

                    handleSuccess()
                    handleClose()

                }} color="primary">
                    Salva
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditActivity