import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, Button, Container, Divider, InputLabel, TextField, Typography, Select as SelectNV } from "@material-ui/core"
import Select from 'react-select'
import MenuItem from '@material-ui/core/MenuItem';
import Activity from '../../components/activity'
import contractData from '../../data/contractData';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import userData from '../../data/userData';
import webservice from '../../../api/webservice';
import anagraficaData from '../../data/anagraficaData';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    Header:{
      backgroundColor: "#00ADA2",
      height: 50,
      paddingTop:12
    },
    listaTable:{
      marginTop:40,
      height:'auto',
      width:'95%',
      paddingBottom:30,
      borderRadius:15,
      backgroundColor:'white',
    },
    tabName:{
      padding:15,
      paddingTop:30,
      fontWeight:'bold',
      fontSize:25, 
      letterSpacing:'.05em',
      color:'#6C6C6C'
    },
    topTab:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingBottom:30
    },
    defaultSelectBox:{
        width:'100%'
    },
    subTabName:{
      paddingLeft:15,
      color:'#535353',
      fontSize:20,
      fontWeight:'bold',
      letterSpacing:'0.05em',
      textTransform:'capitalize',
      textAlign:'left'
     },
     topButton:{
         background:'#18CCBC !important',
         color:'white',
         paddingLeft:25,
         paddingRight:25
     },
     mainTab:{
      paddingTop:50,
      display: "flex",
      flexDirection:'column !important',
      flexWrap: "wrap",
          "& > *": {
          margin: theme.spacing(1),
          height: theme.spacing(16)
          }
      },
      button:{
          backgroundColor:'#00ADA2',
          textDecoration:'capitalize',
          width:'15%'
      },
      buttonBack:{
            width:'15%',
            background:'transparent',
            color:'black',
            '&:hover': {
              backgroundColor: '#f1e9e9 !important',
          }
      },
      errorBasic: {
          color:'red',
          paddingLeft:15
        }
      
  
  }))

  
function EditAttivita({contratto,status,handleClose}) {
  const history = useHistory();
  //Setting values in sessionStorage
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const { getActivityListData, setActivityListData, getTipoI,getAnagraficaData } = anagraficaData()
  const { getUserData } = userData()
  const { getActivityList, createActivity, deleteActivity, getTipologieIntervento, updateContractActivity,getTabTipoImpianti } = webservice()
  const [toRender,setToRender] = useState([])
  const { getTabImpianti } = contractData()

  //Modified
  const [removed,setRemoved] = useState([])
  const [added,setAdded] = useState([])

  //STATE Data Attivita
  const [data,setData] = useState({
      tipologia:null,
      descrizione:null,
      periodo:null,
      id: null
  })

  //ForceUpdate
  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer stateLISTA
    return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  //GENERAZIONE CODICE personale Attività
  function makeid() {
    var result = '';
    var characters  = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 5; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
    }
  console.log(toRender)

  //Error manage
  const [error,setError] = useState(0)

  //Function to change state data
  function handleChange(e,target){
      let value = e.target.value
      setData(prevState => ({
          ...prevState,
          [target]:value
      }))
  }
  //Reintegrating values if already compiled
  const [CType,setCType] = React.useState([])
  useEffect(() => {
    getTipologieIntervento('dd')
        getTipologieIntervento('dd')
        getTabTipoImpianti('dd')
        getActivityList(getUserData().Token,contratto.id)
        setToRender(getActivityListData())
        if(getTabImpianti() !== undefined && getTabImpianti() !== null){
            var typDe = getAnagraficaData().tipologia
            if(typDe.split(',').length > 1){
                typDe = typDe.split(',')
            }else{
                typDe = [typDe]
            }
            //console.log(typDe)
            let fin = []
            let tt = []
            getTabImpianti().map( cTab => {
                typDe.map( cC => {
                    if(cTab.Descr == cC){
                        tt.push(cTab)
                    }
                } )
            })
            if(tt !== undefined){
                console.log('TYPES',tt,getTipoI())
                getTipoI().map( type => {
                    tt.map( cC => {
                        if(type.bytipologia == cC.id){
                            fin.push(type)
                        }
                        
                    })
                } )
                setCType(fin)
            }
        }
        
  }, [])
  //Deleting values
  function removeActivity(code){
    let dataFresh = toRender.filter( item => item.id !== code )
    let tmp = removed
    tmp.push(toRender.filter( item => item.id === code )[0])
    setRemoved(tmp)
    console.log(removed)

    setToRender( dataFresh )
}

  const classes = useStyles();

  //New month system
  function monthDiff(startDate, endDate) {
    var start      = startDate.split('-');
    var end        = endDate.split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];

    for(var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1])-1 : 0;
        for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? '0'+month : month;
        dates.push([i, displayMonth, '01'].join('-'));
        }
    }
    return dates;
}
const [monthToLoad,setMonthToLoad] = React.useState([])

function printSelectMonths(dates){

    function printName(num){
        switch(num){
            case '01':
                return 'Gennaio';
            case '02':
                return 'Febbraio';
            case '03':
                return 'Marzo';
            case '04':
                return 'Aprile';
            case '05':
                return 'Maggio';
            case '06':
                return 'Giugno';
            case '07':
                return 'Luglio';
            case '08':
                return 'Agosto';
            case '09':
                return 'Settembre';
            case '10':
                return 'Ottobre';
            case '11':
                return 'Novembre';
            case '12':
                return 'Dicembre';
        }
    }
    let fin = []

    dates.map( item => {
        let tmp = item.split('-')
        let tmpToUp = {
            value:item,
            label:printName(tmp[1])
        }
        fin.push(tmpToUp)
        //<MenuItem value={item}>{printName(tmp[1]) + ' - ' + tmp[0]}</MenuItem>
    })

    return (
        <Select
        menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                className="basic-single"
                classNamePrefix="select"
            isSearchable={true}
            name="color"
            isMulti
            options={fin}
            onChange={ item => setMonthToLoad(item) }
            />
    )
}

  return (

        <Dialog
            open={status}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>

            <Container maxWidth="False" className={classes.listaTable}>
            <Typography className={classes.tabName}>LISTA ATTIVITA'</Typography>
            {error === 1 ? <Typography className={classes.errorBasic}>* Compila tutti i campi</Typography> : null}
            <Box className={classes.topTab}>
                <Typography className={classes.subTabName}>Nuova Attività</Typography>
                <Box style={{width:'15%'}}>
                    <InputLabel htmlFor="selectTipologia">* Tipologia Impianto</InputLabel>
                    <SelectNV
                        className={classes.defaultSelectBox}
                        labelId="selectTipologia"
                        id="selectTipologia"
                        value={data.tipologia}
                        onChange={(e) => handleChange(e,'tipologia')}
                        >
                        {CType.map(type => {
                                        return (
                                            <MenuItem value={type.Nome}>{type.Nome}</MenuItem>
                                        )
                                    })}
                    </SelectNV>
                </Box>
                <Box style={{width:'25%'}}>
                    <TextField
                        id="outline-multiline-static"
                        label="Descrizione dell'attività"
                        multiline
                        className={classes.defaultSelectBox}
                        rowsMax={8}
                        height={64}
                        rows={1}
                        value={data.descrizione}
                        placeholder="Inserisci la descrizione compelta dell'attività da svolgere"
                        onChange={e => handleChange(e,'descrizione')}
                    />
                </Box>
                <Box style={{width:'20%'}}>
                    <InputLabel htmlFor="selectTipologia">* Periodo temporale</InputLabel>
                    { printSelectMonths(monthDiff(getAnagraficaData().dataInizio.split('T')[0],getAnagraficaData().dataFine.split('T')[0])) }
                            
                </Box>
                <Button 
                    variant="contained" 
                    className={classes.topButton}
                    onClick={ () => {
                        if(data.tipologia && monthToLoad.length > 0 && data.descrizione ){
                            let toUpdate = data
                            toUpdate.periodo = monthToLoad
                            toUpdate.id = makeid()
                            let tmp = toRender !== null ? toRender : []
                            tmp.push(toUpdate)
                            setToRender(tmp)

                            let tmpA = added
                            tmpA.push(data)
                            setAdded(tmpA)
                            console.log(added)

                            setData({
                              tipologia:null,
                              descrizione:'',
                              periodo:[

                              ],
                              id: null
                          })

                            setError(0);
                            forceUpdate()
                        }else{
                            setError(1);
                        }
                    } }
                >
                    <span style={{textTransform:'capitalize'}}>Aggiungi</span>
                </Button>
            </Box>
            <Divider></Divider>
            <Box className={classes.mainTab}>
                {toRender != {} && toRender !== null ?
                    toRender.map( (item, index) => {
                        return <Activity data={item} index={index} toDelete={(e) => removeActivity(e)} tipo={CType} updateAct={ e => {
                            let toUp = toRender.filter( cc => cc.id !== e.id )
                            toUp.push(e)
                            setToRender(toUp)
                        } }/>
                    } ) : null
                }
                </Box>
        </Container>

            </DialogContent>
            <DialogActions>
                  <Box style={{display:'flex',width:'100%',justifyContent:'space-between',paddingLeft:50,paddingRight:50}}>
                    <Button
                       variant="contained"
                       color="primary"
                       fullWidth={false}
                       onClick={ () => {
                         handleClose()
                       } }
                       className={classes.button}
                       startIcon={<ArrowBackIcon></ArrowBackIcon>}                       
                       >
                       Chiudi
                      </Button>

                      <Button
                       variant="contained"
                       color="primary"
                       fullWidth={false}
                       onClick={ async () => {
                          if(added.length > 0 ){
                            added.map( async item => {
                              item.periodo.map( async cPeriodo => {
                                let current = item
                                current.periodo = cPeriodo.value
                                current.numContratto = contratto.numero
                                console.log(current)
                                await createActivity(getUserData().Token,current.tipologia,current.periodo,current.descrizione,current.numContratto)
                                await delay(500)
                              } ) 
                            } )
                          }
                          if(removed.length > 0 && removed !== null && removed !== undefined){
                            removed.map( async item => {
                              let current = {
                                token:getUserData().Token,
                                id:item.id
                              }
                              await deleteActivity(current)
                            } )}
                            await delay(500)
                            setActivityListData( toRender )
                            await getActivityList(getUserData().Token,contratto.id)
                            await delay(500)
                          handleClose()
                          //window.location.reload(false)
                         }
                       } 
                       className={classes.button}
                       style={{justifyContent:'flex-start'}}                      
                       endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                       >
                       Modifica
                      </Button>
                  </Box>
            </DialogActions>
        </Dialog>

  )
}

export default EditAttivita
