import { useHistory } from "react-router-dom";

export default() => {
    const history = useHistory();

    var setNewContractData= (data) => {
        localStorage.setItem('newContractData', JSON.stringify(data));
        console.log(data)
    }
    var setNewContractError= (data) => {
        localStorage.setItem('newContractError', data)
    }
    var updateContractActivity= (data) => {
        localStorage.setItem('newContractActivity', JSON.stringify(data))
    }
    var updateContractReferent= (data) => {
        localStorage.setItem('newContractReferent', JSON.stringify(data))
    }
    var updateContractFiles= (data) => {
        localStorage.setItem('newContractDocuments', JSON.stringify(data))
    }
    var setContractActivity= (data) => {
        console.log(data,localStorage.getItem('newContractActivity'))
        if( data === 'reset' ){
            localStorage.setItem('newContractActivity',null)
        }else if( localStorage.getItem('newContractActivity') != null && localStorage.getItem('newContractActivity') != 'null') {
            let toLoad = JSON.parse(localStorage.getItem('newContractActivity'))
            toLoad.push(data)
            localStorage.setItem('newContractActivity',JSON.stringify(toLoad))
        }else{
            localStorage.setItem('newContractActivity',JSON.stringify([data]))
        }
        console.log(data,localStorage.getItem('newContractActivity'))
    }
    var setContractInvoice= (data) => {
        localStorage.setItem('newContractInvoice', JSON.stringify(data))
    }
    var setNewContractID= (data) => {
        console.log(data)
        localStorage.setItem('newContractID', (data))
    }

    var removeActivity= (data) => {
        localStorage.setItem('newContractInvoice', null)
    }

    var setContractReferent= (data,type) => {
        if(type === 'initialEdit'){
            console.log('SETTING', data)
            localStorage.setItem('newContractReferent', JSON.stringify(data))
        }
        if(type === 'commesse'){
            if(JSON.parse(localStorage.getItem('newContractReferent')) !== null){
                let toSet = JSON.parse(localStorage.getItem('newContractReferent'))
                toSet.push(data)
                localStorage.setItem('newContractReferent', JSON.stringify(toSet))
            }else{
                localStorage.setItem('newContractReferent', JSON.stringify([data]))
            }
        }else{
            if(data == 'reset'){
                localStorage.setItem('newContractReferent', null)
            }else if(JSON.parse(localStorage.getItem('newContractReferent')) !== null && localStorage.getItem('newContractActivity') != 'null'){
                let toSet = JSON.parse(localStorage.getItem('newContractReferent'))
                toSet.push(data)
                localStorage.setItem('newContractReferent', JSON.stringify(toSet))
            }else{
                localStorage.setItem('newContractReferent', JSON.stringify([data]))
            }
        }
        
        console.log( JSON.parse(localStorage.getItem('newContractReferent')) )
        
    }
    var setContractDocument = (data,variant) => {
        console.log(data)
        if(data == 'reset'){
            localStorage.setItem('newContractDocuments', JSON.stringify([]))
        }else if(variant){
            localStorage.setItem('newContractDocuments',JSON.stringify(data))
        }else if(localStorage.getItem('newContractDocuments') !== null){
            let toUp = JSON.parse(localStorage.getItem('newContractDocuments'))
            toUp.push(data)
            localStorage.setItem('newContractDocuments',JSON.stringify(toUp))
        }else{
            localStorage.setItem('newContractDocuments',JSON.stringify([data]))
        }
        
    }
    var setNewContractClient= (data) => {
        localStorage.setItem('newContractClient', data)
    }
    var setNewContractAcquisizione= (data) => {
        localStorage.setItem('newContractAcquisizione', JSON.stringify(data))
    }
    var setNewContractClientList= (data) => {
        localStorage.setItem('newContractClientList', JSON.stringify(data))
    }
    var setContractListData= (data) => {
        localStorage.setItem('contractList', JSON.stringify(data));
    }
    var setNewContractType= (data) => {
        localStorage.setItem('newContractType', JSON.stringify(data));
    }
    var setContractType = (data) => {
        localStorage.setItem('contractTypeSubmit', data)
    }
    var setNewUploadsData= (data) => {
        localStorage.setItem('NewUploadsData', JSON.stringify(data));
    }
    var setMaterialeData= (data) => {
        localStorage.setItem('MData', JSON.stringify(data));
    }
    var setRR= (data) => {
        localStorage.setItem('RData', JSON.stringify(data));
    }
    var setAnomalieData= (data) => {
        localStorage.setItem('AData', JSON.stringify(data));
    }
    var setTabImpianti= (data) => {
        localStorage.setItem('tabImpianti', JSON.stringify(data));
    }


    var getTabImpianti= () => {
        return JSON.parse(localStorage.getItem('tabImpianti'))
    }
    var getAnomalieData= () => {
        return JSON.parse(localStorage.getItem('AData'))
    }
    var getRR= () => {
        return JSON.parse(localStorage.getItem('RData'))
    }
    var getMaterialeData= () => {
        return JSON.parse(localStorage.getItem('MData'))
    }
    var getNewUploadsData= () => {
        return JSON.parse(localStorage.getItem('NewUploadsData'))
    }
    var getContractType= () => {
        return localStorage.getItem('contractTypeSubmit')
    }
    var getNewContractID= () => {
        return localStorage.getItem('newContractID')
    }
    var getContractListData= () => {
        return JSON.parse(localStorage.getItem('contractList'))
    }
    var getNewContractClientList= () => {
        return JSON.parse(localStorage.getItem('newContractClientList'))
    }
    var getNewContractClient= () => {
        return localStorage.getItem('newContractClient')
    }
    var getContractDocument= () => {
        return JSON.parse(localStorage.getItem('newContractDocuments'))
    }
    var getContractReferent= () => {
        return JSON.parse(localStorage.getItem('newContractReferent'))
    }
    var getContractInvoice= () => {
        return JSON.parse(localStorage.getItem('newContractInvoice'))
    }
    var getContractActivity= () => {
        return JSON.parse(localStorage.getItem('newContractActivity'))
    }
    var getNewContractData= () => {
        return JSON.parse(localStorage.getItem('newContractData'))
    }
    var getNewContractAcquisizione= () => {
        return JSON.parse(localStorage.getItem('newContractAcquisizione'))
    }
    var getNewContractType= () => {
        return JSON.parse(localStorage.getItem('newContractType'))
    }
    var getNewContractError= () => {
        return localStorage.getItem('newContractError')
    }

    return {
        setMaterialeData,
        setAnomalieData,
        setRR,
        getAnomalieData,
        getMaterialeData,
        getRR,
        setNewUploadsData,
        getNewUploadsData,
        setNewContractData,
        getNewContractData,
        setNewContractError,
        getNewContractError,
        setContractActivity,
        getContractActivity,
        setContractInvoice,
        getContractInvoice,
        setContractReferent,
        getContractReferent,
        updateContractActivity,
        setContractDocument,
        getContractDocument,
        updateContractFiles,
        getNewContractClient,
        setNewContractClient,
        updateContractReferent,
        setNewContractClientList,
        getNewContractClientList,
        setContractListData,
        getContractListData,
        setContractType,
        getContractType,
        setNewContractAcquisizione,
        getNewContractAcquisizione,
        removeActivity,
        setNewContractID,
        getNewContractID,
        getNewContractType,
        setNewContractType,
        setTabImpianti,
        getTabImpianti
    }
        
}
