import React from 'react';
import jsPDF from 'jspdf'
import imageToBase64 from 'image-to-base64/browser';
import {Button} from '@material-ui/core'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes  } from "@material-ui/core/styles"
import webservice from '../../api/webservice'  

const useStyles = makeStyles((theme) => ({
    button: {    
        '&:hover': {
            borderColor:'#ff5982',
            backgroundColor: 'transparent !important',
        }
    }
}));

const DownloadQR = (props) => {
    const { createAssetQR } = webservice()
    const classes = useStyles();

    const generatePDF = async () => {
        
        var url = await createAssetQR(props.codice)
        imageToBase64("https://itp.softwellitalia.it/tmp/QR_" + props.codice + ".jpg") // Image URL
            .then(
                (response) => {
                    var doc = new jsPDF('p', 'pt');
                    doc.addImage('data:image/png;base64,'+response, 'PNG',20,20);  
                    doc.save('ITP-QR_' + props.codice + '.pdf')
                }
            )
            .catch(
                (error) => {
                    alert('Errore contattare il supporto tecnico')
                    
                }
            )
    }

    return (
        <Button onClick={() => generatePDF()}  variant="outlined" className={classes.button} disabled={!props.active} >
            Stampa QR
        </Button>
    );
}

export default DownloadQR;