import React from 'react'
import exportFromJSON from 'export-from-json'
import { useHistory } from "react-router-dom";

export default() => {
    const history = useHistory();

    var setUserData= (data) => {
        localStorage.setItem('userData', JSON.stringify(data));
    }
    var setCode= (data) => {
        localStorage.setItem('codeUser', data);
    }
    var setUserContractData= (data) => {
        localStorage.setItem('userContractData', JSON.stringify(data));
    }
    var setSelectedUser= (data) => {
        localStorage.setItem('selectedUser', JSON.stringify(data));
    }
    var setNewUserData= (data) => {
        localStorage.setItem('newUserData', JSON.stringify(data));
    }
    var setNewUserPex= (data) => {
        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });
        localStorage.setItem('newUserPex', JSON.stringify(data));
    }
    var setNewUserError= (data) => {
        localStorage.setItem('newUserError', data);
    }
    var setNewUserErrorFinal= (data) => {
        localStorage.setItem('NewUserErrorFinal', data);
    }
    var setNewUserStep= (data) => {
        console.log(data)
        localStorage.setItem('NewUserStep', data);
    }
    var setNewUserID= (data) => {
        console.log(data)
        localStorage.setItem('newUserID', data);
    }
    var setUserMan= (data) => {
        console.log(data)
        localStorage.setItem('newUserMan', JSON.stringify(data));
    }
    var setCurrentPermission= (data) => {
        console.log(data)
        localStorage.setItem('userPex', JSON.stringify(data));
    }
    var setCurrentPermissionEdit= (data) => {
        console.log('DD',data)
        localStorage.setItem('userPexEdit', JSON.stringify(data));
    }
    var setDrawer= () => {
        var data = localStorage.getItem('drawer')
        if( data === '0' ){
            localStorage.setItem('drawer','1')
        }else{
            localStorage.setItem('drawer','0')
        }
        console.log( localStorage.getItem('drawer') )
        
    }


    var getNewUserID= () => {
        return (localStorage.getItem('newUserID'))
    }
    var getUserContractData= () => {
        return JSON.parse(localStorage.getItem('userContractData'))
    }
    var getDrawer= () => {
        return localStorage.getItem('drawer')
    }
    var getNewUserStep= () => {
        return localStorage.getItem('NewUserStep')
    }
    var getNewUserErrorFinal= () => {
        console.log(localStorage.getItem('NewUserErrorFinal'))
        return localStorage.getItem('NewUserErrorFinal')
    }
    var getNewUserPex= () => {
        return JSON.parse(localStorage.getItem('newUserPex'))
    }
    var getNewUserFullData= () => {
        const dataNew = [{...JSON.parse(localStorage.getItem('newUserPex'))},{ ...JSON.parse(localStorage.getItem('newUserData'))}]
        return dataNew
    }
    var getNewUserError= () => {
        return localStorage.getItem('newUserError')
    }
    var getNewUserData= () => {
        return JSON.parse(localStorage.getItem('newUserData'))
    }
    var getUserMan= () => {
        return JSON.parse(localStorage.getItem('newUserMan'));
    }
    var getUserData= () => {
        return JSON.parse(localStorage.getItem('userData'));
    }
    var getCurrentPermission= () => {
        return JSON.parse(localStorage.getItem('userPex'));
    }
    var getCurrentPermissionEdit= () => {
        return JSON.parse(localStorage.getItem('userPexEdit'));
    }
    var getCode= () => {
        return (localStorage.getItem('codeUser'));
    }
    var getSelectedUser= () => {
        var dataToExport = []
        const fileName = 'lista_utenti'
        const exportType = 'csv' 
        var data = JSON.parse(localStorage.getItem('selectedUser'));
        console.log(data)
        data.map( (item) => dataToExport.push( JSON.parse( JSON.stringify({Nome: item.name,Tipologia: item.tipologia, Societa: item.societa}) ) ) )
        return(dataToExport)
    }

    return {
        setCurrentPermissionEdit,
        getCurrentPermissionEdit,
        setUserData,
        getUserData,
        setSelectedUser,
        getSelectedUser,
        setNewUserData,
        getNewUserData,
        setNewUserError,
        getNewUserError,
        setNewUserPex,
        getNewUserPex,
        getNewUserFullData,
        setNewUserErrorFinal,
        getNewUserErrorFinal,
        setNewUserStep,
        getNewUserStep,
        setDrawer,
        getDrawer,
        setUserContractData,
        getUserContractData,
        setUserMan,
        getUserMan,
        setCode,
        getCode,
        setNewUserID,
        getNewUserID,
        setCurrentPermission,
        getCurrentPermission
    }
        
}
